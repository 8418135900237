// @flow

import * as React from 'react';
import * as Sentry from '@sentry/browser';
import IdentityProvider from '../Identity';

// import { routerHistory } from '../router';

// -------------------------------------------------------------------------------------------------

export type ErrorBoundaryProps = {|
  fallbackComponent?: any,
  fallback?: React.Node,
  children: React.Node
|};

type ErrorBoundaryState = {|
  error: any
|};

// -------------------------------------------------------------------------------------------------

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = { error: null };

  componentDidCatch(error: any, errorInfo: any): void {
    this.setState({ error });
    Sentry.withScope(scope => {
      const user = IdentityProvider.getStoredIdentity();
      if (user) {
        scope.setUser({ email: user.email });
      }
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
    // routerHistory.push('/error/500?timestamp=' + new Date().getTime());
  }

  render(): React.Node {
    const FallbackComponent = this.props.fallbackComponent;
    const fallback = this.props.fallback;

    return this.state.error ? (
      FallbackComponent ? (
        <FallbackComponent />
      ) : (
        fallback || this.props.children
      )
    ) : (
      this.props.children
    );
  }
}
