// @flow

import * as React from 'react';
import I18n from '../../_helpers/I18n';
import { graphql } from 'react-relay';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BForm from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Redirect from 'react-router-dom/Redirect';

import Logger from '@app/Logger';
import Enum from '../../_components/Enum';
import Link from '../../_components/Link';
import toastr from '../../_helpers/toastr';
import { Query } from '../../_helpers/Api';
import Layout from '../../_components/Layout';
import DateTime from '../../_components/DateTime';
import { routerHistory } from '../../_helpers/router';
import LoadingContent from '../_components/LoadingContent';
import { CooperationIcon } from '../_components/DealList/DealListItem';
import Form, { TextareaInput, ToggleInput } from '../../_components/Form';
import commitMutationPromise from '../../_helpers/Api/commitMutationPromise';

// -------------------------------------------------------------------------------------------------

export type DealContractPageProps = RouteProps<{ contractId: string }>;

const contractValidationRules = {
  notes: { maxLength: 2000 },
  status: { required: true }
};

export type ContractFormDataType = { status: ContractStatus, notes: null | string };

// -------------------------------------------------------------------------------------------------

export default function DealContractPage(props: DealContractPageProps): React.Node {
  const [pending, setPending] = React.useState<boolean>(false);

  return (
    <Layout.Common narrow>
      <Query name="manageContract" variables={{ contractId: props.match.params.contractId }}>
        {({ loading, error, data }) => {
          if (loading) {
            return <LoadingContent />;
          }

          if (error) {
            Logger.error(error);
            return <Redirect to="/error/500" />;
          }

          const contract = data && data.contract;
          const deal = contract && contract.deal;
          const user = contract && contract.consumer;
          const company = contract && contract.consumer.company;

          if (!contract || !deal) {
            return <Redirect to="/error/404" />;
          }

          return (
            <Row>
              <Col md={12} lg={9}>
                <Row className="mb-5">
                  <Col>
                    <h1 className="mb-4 font-rubik-title">
                      <I18n id="title" d="Reply details" />
                    </h1>

                    <span className="cooperation-subtitle d-block border-top border-bottom">
                      <CooperationIcon
                        value={deal.cooperation}
                        className="float-left mr-2 text-primary"
                        grayScale
                      />
                      {deal.summary}
                    </span>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <b>
                      <I18n id="startOfCooperation" d="Start of Cooperation: " />
                    </b>
                  </Col>
                  <Col>{contract.created ? <DateTime.Date value={contract.created} /> : '-'}</Col>
                </Row>

                <Row className="mb-3">
                  <Col md={4}>
                    <b>
                      <I18n id="originlaOffer" d="Original reply: " />
                    </b>
                  </Col>
                  <Col>
                    <Link to={`/deal/detail/${deal.id}`} className="font-rubik-bold">
                      {deal.summary}
                    </Link>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={4}>
                    <b>
                      <I18n id="typeOfDeal" d="Type of Opportunity: " />
                    </b>
                  </Col>
                  <Col className="text-primary font-rubik-bold">
                    {deal.type && <Enum.DealType v={deal.type} />}
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={4}>
                    <b>
                      <I18n id="typeOfCooperation" d="Type of Cooperation: " />
                    </b>
                  </Col>
                  <Col className="text-secondary font-rubik-bold">
                    <CooperationIcon value={deal.cooperation} grayScale small />
                    {deal.cooperation && <Enum.Cooperation v={deal.cooperation} />}
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={4}>
                    <b>
                      <I18n id="category" d="Category: " />
                    </b>
                  </Col>
                  <Col className="text-secondary font-rubik-bold">
                    {deal.category && deal.category.name}
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={4}>
                    <b>
                      <I18n id="cooperationCompany" d="Cooperating company: " />
                    </b>
                  </Col>
                  <Col>
                    <Link
                      to={`/company/${company.id}/user/${user.id}`}
                      className="font-rubik-bold text-success"
                    >
                      {company.name}
                    </Link>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={4}>
                    <b>
                      <I18n id="contact" d="Contact: " />
                    </b>
                  </Col>
                  <Col>{`${user.firstName || ''} ${user.lastName || ''}`}</Col>
                </Row>

                <Form
                  defaultValue={{
                    status: contract.status !== 'PROPOSED' ? contract.status : null,
                    contractId: contract.id,
                    notes: contract.notes
                  }}
                  validationRules={contractValidationRules}
                  onSubmit={handleSubmit(pending, setPending)}
                  disabled={loading || pending}
                  className="mt-3"
                >
                  <BForm.Group controlId="contractForm.status">
                    <BForm.Label>
                      <I18n id="contractStatusLabel" d="Current status:" />
                    </BForm.Label>
                    <Enum.ContractStatus>
                      {({ d }) => (
                        // $FlowFixMe
                        <Form.Field
                          component={ToggleInput}
                          // $FlowFixMe
                          options={Object.keys(d).reduce(createStateOptions(d), [])}
                          name="status"
                        />
                      )}
                    </Enum.ContractStatus>
                  </BForm.Group>

                  <BForm.Group controlId="contractForm.notes">
                    <BForm.Label>
                      <I18n id="contractNotesLabel" d="Reply notes:" />
                    </BForm.Label>
                    <I18n
                      id="contractNotesPlaceholder"
                      d="Here you can keep notes on the cooperation with {company}"
                      user={`${user.firstName} ${user.lastName}`}
                      company={company.name}
                    >
                      {placeholder => (
                        // $FlowFixMe
                        <Form.Field
                          placeholder={placeholder.value}
                          component={TextareaInput}
                          id="contractForm.notes"
                          name="notes"
                        />
                      )}
                    </I18n>
                  </BForm.Group>

                  <Button type="submit" disabled={pending} className="mr-3">
                    <I18n id="submitButton" d="Save" />
                  </Button>

                  <Link
                    variant="outline-primary"
                    disabled={pending}
                    component={Button}
                    type="submit"
                    back
                  >
                    <I18n id="backButton" d="Back" />
                  </Link>
                </Form>
              </Col>
            </Row>
          );
        }}
      </Query>
    </Layout.Common>
  );
}

// -------------------------------------------------------------------------------------------------

type StatusOptions = Array<{ label: React.Node, value: * }>;

function createStateOptions(map: { [string]: string }): (StatusOptions, string) => StatusOptions {
  return function(prev: StatusOptions, key: string): StatusOptions {
    if (key !== 'PROPOSED') {
      prev.push({ value: key, label: map[key] });
    }
    return prev;
  };
}

// -------------------------------------------------------------------------------------------------

function handleSubmit(pending: boolean, setPending: boolean => void): ContractFormDataType => void {
  return function(data: ContractFormDataType): void {
    if (pending) {
      return;
    }

    setPending(true);
    commitMutationPromise({
      mutation: updateContractMutation,
      variables: data
    })
      .then(response => ({ response, error: undefined }))
      .catch(error => ({ error, response: undefined }))
      .then(({ error, response }) => {
        setPending(false);
        toastr({
          type: error ? 'error' : 'success',
          message: error ? (
            <I18n id="contractWasNotSaved" d="Ooops! Something went wrong" />
          ) : (
            <I18n id="contractSaved" d="Done! Reply is saved." />
          )
        });
        routerHistory.push(`/account/my-contracts`);
      });
  };
}

// -------------------------------------------------------------------------------------------------

const updateContractMutation = graphql`
  mutation ManageContractPageFormMutation(
    $contractId: ID!
    $status: ContractStatus!
    $notes: String
  ) {
    contract: updateContract(id: $contractId, status: $status, notes: $notes) {
      id
      status
      notes
      deal {
        id
      }
      other
    }
  }
`;
