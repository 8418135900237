// @flow

import I18n from '../../_helpers/I18n';
import * as React from 'react';
import { graphql } from 'react-relay';
import Button from 'react-bootstrap/Button';
import Redirect from 'react-router-dom/Redirect';

import type { DealFormData } from './_components/DealForm';

import Logger from '@app/Logger';
import Link from '../../_components/Link';
import toastr from '../../_helpers/toastr';
import { Query } from '../../_helpers/Api';
import Layout from '../../_components/Layout';
import DealForm from './_components/DealForm';
import { routerHistory } from '../../_helpers/router';
import { commitMutationPromise } from '../../_helpers/Api';
import LoadingContent from '../_components/LoadingContent';
import BusinessPartnersIcon from '../../_assets/images/multiple-11.svg';
import { decorateDealInput } from './_helpers/decorateDealInput';
import { Context as IdentityContext } from '../../_helpers/Identity';

// -------------------------------------------------------------------------------------------------

export type EditDealPageProps = RouteProps<{ dealId: ID }>;

// -------------------------------------------------------------------------------------------------

export default function EditDealPage(props: EditDealPageProps): React.Node {
  const dealId = props.match.params.dealId;
  const [pending, setPending] = React.useState(false);
  const { identity, refreshIdentity, loading } = React.useContext(IdentityContext);
  const [paying] = React.useState(identity.company.paying);

  React.useEffect(() => {
    if (refreshIdentity() && !identity) {
      return <Redirect to="/" />;
    }
  }, []);

  const populated = identity.company.populated;
  const userType = identity.type || null;

  if (loading) {
    return <LoadingContent />;
  }

  return (
    <Layout.Common narrow>
      <div className="mb-4 mt-4 d-flex align-items-center edit-deal">
        <h1 className="d-block d-md-inline-block font-rubik-title">
          <I18n id="title" d="Edit Opportunity" />
        </h1>
        {dealId && (
          <span className="business-partners-container">
            <Link
              to={`/deal/manage/${dealId}`}
              component={Button}
              className="business-partners-button"
            >
              <img src={BusinessPartnersIcon} className="mr-2" width="17px" height="17px" alt="" />
              <I18n id="manage" d="Business Partners" />
            </Link>
          </span>
        )}
      </div>

      <Query name="dealDetail" variables={{ dealId }}>
        {({ loading, error, data }) => {
          if (loading) {
            return <LoadingContent />;
          }

          if (error) {
            Logger.error(error);
            return <Redirect to="/error/500" />;
          }

          const deal = data.deal;

          if (!data || !data.deal) {
            Logger.error(`EditDealPage: Opportunity "${dealId}" not found`);
            return <Redirect to="/error/500" />;
          }

          // eslint-disable-next-line
          if (
            !identity ||
            deal.company.id !== identity.company.id ||
            deal.user.id !== identity.id
          ) {
            return <Redirect to="/error/403" />;
          }

          return (
            <DealForm
              onSubmit={handleSubmit(dealId, setPending, pending, paying)}
              defaultValue={deal}
              disabled={pending || !paying}
              populated={populated}
              paying={paying}
              userType={userType}
            >
              <>
                <Button
                  className="d-block d-md-inline mb-3 mb-md-0 mr-0 mr-md-3"
                  disabled={pending}
                  type="submit"
                >
                  <I18n id="submitButton" d="Update Opportunity" />
                </Button>

                <Link
                  variant="outline-primary"
                  to="/account/my-deals"
                  disabled={pending}
                  component={Button}
                >
                  <I18n id="cancelButton" d="Discard changes" />
                </Link>
              </>
            </DealForm>
          );
        }}
      </Query>
    </Layout.Common>
  );
}

// -------------------------------------------------------------------------------------------------

// TODO: move submit method to form -> create async / promise action which returns {response, errors}
function handleSubmit(
  dealId: ID,
  setPending: boolean => void,
  pending: boolean,
  paying: boolean
): (null | DealFormData) => void {
  return function(data: null | DealFormData): void {
    const inputData = data && decorateDealInput(data);
    const max =
      !paying && data.created
        ? new Date(new Date(data.created).setMonth(data.created.getMonth() + 6))
        : null;
    if (max && new Date().getTime() > new Date(max).getTime()) {
      toastr({
        type: 'warning', //info, options
        message: (
          <I18n d="You cannot edit this opportunity as a non-paying user." id="exDateMessage" />
        )
      });
      toastr({
        type: 'error',
        message: <I18n id="responseError" d="Opportunity details was not saved!" />
      });
      return;
    }

    if (!inputData || pending) {
      return;
    }

    setPending(true);
    // TODO: decorate data
    commitMutationPromise({
      mutation: editDealMutation,
      variables: {
        data: inputData,
        dealId
      }
    })
      .then(response => ({ response, error: undefined }))
      .catch(error => ({ error }))
      .then(({ error }) => {
        setPending(false);
        toastr({
          type: error ? 'error' : 'success',
          message: error ? (
            <I18n id="responseError" d="Opportunity details was not saved!" />
          ) : (
            <I18n id="responseSuceess" d="Opportunity saved" />
          )
        });
        !error && routerHistory.push('/account/my-deals');
        return;
      });
  };
}

// -------------------------------------------------------------------------------------------------

// TODO: use fragment
const editDealMutation = graphql`
  mutation EditDealPageMutation($dealId: ID!, $data: DealInput!) {
    deal: updateDeal(id: $dealId, data: $data) {
      id
      status
      cooperation
      type
      summary
      category {
        id
        name
      }
      expires
      description
      onlyInternal
      keywords
      partnerRequirements
      attachments {
        id
        name
        url
      }
      video
      created
      modified
      company {
        id
      }
    }
  }
`;
