// @flow

import cn from 'classnames';
import * as React from 'react';

import Icon from '../../Icon';
import type { OnChangeEvent } from '../';

export type SelectOption = { value: *, label: null | React.Node };

export default function SelectInput(props: {
  onChange: (OnChangeEvent<string>) => void,
  options: Array<SelectOption>,
  disableEmpty?: boolean,
  placeholder?: string,
  value: null | string,
  className?: string,
  name: string,
  id?: string
}): React.Node {
  const { disableEmpty, onChange, value, name, options, placeholder, className, ...rest } = props;

  // $FlowFixMe
  delete rest.isInvalid;
  // $FlowFixMe
  delete rest.isValid;

  return (
    <div className={cn('form-control select-input', className || '')}>
      <select {...rest} onChange={changeHandler(name, onChange)} value={value || ''}>
        {!disableEmpty && <option value="">{placeholder || ''}</option>}
        {options.map((item, key: number) => {
          return (
            // TODO: key
            <option key={key} value={item.value}>
              {item.label}
            </option>
          );
        })}
      </select>
      <Icon className="caret" name="caret-down" />
    </div>
  );
}

function changeHandler(
  name: string,
  onChange: (OnChangeEvent<string>) => void
): (SyntheticEvent<HTMLTextAreaElement>) => void {
  return function(e: SyntheticEvent<HTMLTextAreaElement>): void {
    const value: null | string = e.currentTarget.value || null;
    const event = { value, name };
    onChange(event);
  };
}
