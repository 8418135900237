// @flow

import * as React from 'react';
import RCSlider from 'rc-slider';

import type { OnChangeEvent } from '../';

import 'rc-slider/assets/index.css';

// -------------------------------------------------------------------------------------------------

export type SliderInputProps = {|
  knob: React.ComponentType<{ offset: number, value: number }>,
  onChange: (OnChangeEvent<number>) => void,
  value: null | number,
  disabled?: boolean,
  step?: number,
  name: string,
  min?: number,
  max?: number
|};

// -------------------------------------------------------------------------------------------------

export default function SliderInput(props: SliderInputProps): React.Node {
  const { onChange, value, knob, name, ...rest } = props;
  const onChangeHandler = React.useCallback(
    value => {
      onChange({ name, value });
    },
    [name, onChange]
  );
  const Knob = knob || undefined;

  return (
    <div className="form-control slider">
      <RCSlider {...rest} handle={Knob} onChange={onChangeHandler} value={value} />
    </div>
  );
}
