// @flow

import I18n from '../../_helpers/I18n';
import * as React from 'react';
import BForm from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import queryString from 'query-string';
import Redirect from 'react-router-dom/Redirect';

import Link from '../../_components/Link';
import toastr from '../../_helpers/toastr';
import { AuthApi } from '../../_helpers/Api';
import Layout from '../../_components/Layout';
import { routerHistory } from '../../_helpers/router';
import Form, { TextInput, PasswordInput } from '../../_components/Form';
import { Context as IdentityContext } from '../../_helpers/Identity';

const Identity = IdentityContext.Consumer;

// -------------------------------------------------------------------------------------------------

export type AuthResetPageProps = RouteProps<{ token: string }>;

// -------------------------------------------------------------------------------------------------

const resetValidation = {
  password: { required: true, minLength: 8, hasDigit: true, maxLength: 50 }
};

export default function AuthResetPage(props: AuthResetPageProps): React.Node {
  const token = props.match.params.token;
  const values = queryString.parse(props.location.search);

  const defaultValue = { email: values.email, password: '' };

  return (
    <AuthApi action="reset">
      {(action, { loading }) => (
        <Layout.Auth>
          <h1 className="mb-4 font-rubik-title">
            {values.email ? (
              <I18n id="forInternalTitle" d="Set password" />
            ) : (
              <I18n id="title" d="Reset Password" />
            )}
          </h1>
          <Identity>
            {({ identity, updateIdentity }) =>
              identity ? (
                <Redirect to={`/account/profile`} />
              ) : (
                <Form
                  onSubmit={handleSubmit(action, updateIdentity, token)}
                  validationRules={resetValidation}
                  disabled={loading}
                  defaultValue={defaultValue}
                >
                  {values.email && (
                    <BForm.Group>
                      <Form.Field
                        autoComplete="new-username"
                        component={TextInput}
                        name="email"
                        type="email"
                        disabled
                      />
                    </BForm.Group>
                  )}
                  <BForm.Group>
                    <I18n id="passwordPlaceholder" d="Password">
                      {placeholder => (
                        // $FlowFixMe
                        <Form.Field
                          placeholder={placeholder.value}
                          autoComplete="new-password"
                          component={PasswordInput}
                          name="password"
                        />
                      )}
                    </I18n>
                  </BForm.Group>
                  <BForm.Group>
                    <Button variant="primary" block type="submit" disabled={loading}>
                      {values.email ? (
                        <I18n id="register" d="Register" />
                      ) : (
                        <I18n id="submit" d="Reset password" />
                      )}
                    </Button>
                  </BForm.Group>
                </Form>
              )
            }
          </Identity>
          {!values.email && (
            <p className="text-left">
              <I18n id="backToText" d="Back to" />{' '}
              <Link to="/auth/login">
                <I18n id="backToLink" d="Sign In" />
              </Link>
            </p>
          )}
        </Layout.Auth>
      )}
    </AuthApi>
  );
}

// -------------------------------------------------------------------------------------------------

const handleSubmit = (action, updateIdentity, token) => data => {
  action && action({ ...data, token }).then(response => handleResponse(response, updateIdentity));
};

// set identity or display errors
const handleResponse = (response, updateIdentity) => {
  let message = null;
  let err = false;
  if (response && response.ok) {
    const user = response.data;
    message = <I18n id="successMessage" d="Password changed" />;
    if (user && user.id && user.company && user.company.id && user.email) {
      updateIdentity({
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        company: user.company,
        permissions: ['registered'],
        email: user.email,
        id: user.id
      });
      return;
    }
  } else {
    err = true;
    message = (
      <I18n
        id="errMessage$"
        d={{
          UNKNOWN_ERROR: 'Ooops! Something went wrong.',
          INVALID_EMAIL: 'Plese enter valid email',
          INVALID_TOKEN: 'Invalid request, ask for reset link again',
          TOKEN_NOT_PROVIDED:
            'New Password was set but we are not able to sign-in you automatically'
        }}
      >
        {({ d }) => {
          return (response && response.code && d[response.code]) || d.UNKNOWN_ERROR;
        }}
      </I18n>
    );

    if (response && response.code === 'TOKEN_NOT_PROVIDED') {
      routerHistory.push('/auth/login');
    }
  }
  toastr({
    type: err ? 'error' : 'success',
    message
  });
};
