// @flow

import * as React from 'react';
import ReactDomServer from 'react-dom/server';
import GoogleAutocomplete from 'react-google-autocomplete';

import type { OnChangeEvent } from '../_components/Form';

import googlePlaceParser from '../_helpers/googlePlaceParser';
import Enum from './Enum';

// -------------------------------------------------------------------------------------------------

export type GoogleAutocompleteInputProps = {
  onChange: (OnChangeEvent<Place>) => void,
  value: null | Place,
  name: string
};

// -------------------------------------------------------------------------------------------------

export default function GoogleAutocompleteInput(props: GoogleAutocompleteInputProps): React.Node {
  const { value, name, onChange, ...rest } = props;
  const defaultValue = placeToString(value);
  const [val, setVal] = React.useState(defaultValue);
  const [enterVal, setEnterVal] = React.useState(defaultValue);

  React.useEffect(() => {
    if (val !== '') {
      setEnterVal(val);
    }
  }, [val]);

  React.useEffect(() => {
    const v = value || {};
    if (
      value &&
      value.coords &&
      ((value.coords.lat === 50.0751635 && value.coords.lng === 14.4377237) ||
        (value.coords.lat === 48.1486015 && value.coords.lng === 17.1083788)) &&
      value.coords.init === true &&
      !value.street &&
      !value.postalCode &&
      !value.city &&
      !value.country &&
      !value.state &&
      !value.countryCode &&
      !value.stateCode
    ) {
      setVal(null);
      return;
    }
    const defaultValue = [
      v.street,
      v.country === 'United Kingdom'
        ? `${v.city || ''} ${v.postalCode || ''}`
        : `${v.postalCode || ''} ${v.city || ''}`,
      v.country
    ]
      .filter(i => typeof i === 'string' && i.trim().length > 0)
      .join(', ');

    setVal(defaultValue);
  }, [value]);

  return (
    <div className="rbt control-search w-100">
      <GoogleAutocomplete
        {...rest}
        onPlaceSelected={selectedHandler(onChange, setVal, name)}
        onChange={handleWrite(setVal)}
        className="form-control"
        types={['geocode']}
        onKeyDown={devNull(name, value, enterVal, onChange, setVal)}
        value={val || ''}
      />
    </div>
  );
}

// -------------------------------------------------------------------------------------------------

function handleWrite(setter: string => void): (e: *) => void {
  return function onInputChange(e: *): void {
    setter(e.currentTarget.value);
  };
}

function devNull(
  name: String,
  value: any,
  val: any,
  onChange: (OnChangeEvent<Place>) => void,
  setVal: string => void
): (e: *) => void {
  return function devNulll(e: *): void {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleAddressInputsChange(name, value, val, onChange, setVal);
      return e;
    }
    return e;
  };
}

function handleAddressInputsChange(
  name: String,
  value: any,
  val: any,
  onChange: (OnChangeEvent<Place>) => void,
  setVal: string => void
): () => void {
  const request = {
    input: val,
    types: ['geocode']
  };
  const search = async () => {
    const res = await getPlace(request).then(result => {
      if (result) {
        selectedHandler(onChange, setVal, name)(result);
        return false;
      }
      return true;
    });
    if (!res) {
      return null;
    }
  };
  search();
}

function getPlace(request) {
  return new Promise(resolve => {
    const google = window.google;
    const service = new google.maps.places.AutocompleteService();
    const placeService = new google.maps.places.PlacesService(document.createElement('div'));
    service.getPlacePredictions(request, function(predictions, status) {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const req = {
          placeId: predictions[0].place_id
        };
        placeService.getDetails(req, function(result, status) {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(result);
          }
        });
      } else {
        resolve(null);
      }
    });
  });
}

function selectedHandler(
  onChange: (OnChangeEvent<Place>) => void,
  setVal: string => void,
  name: string
) {
  return function handleOnSelectPlace(response: GooglePlace) {
    if (response && response.address_components && response.geometry) {
      const value = googlePlaceParser(response);
      const v: Place = value || {};

      const defaultValue = [
        v.street,
        v.country === 'United Kingdom'
          ? `${v.city || ''} ${v.postalCode || ''}`
          : `${v.postalCode || ''} ${v.city || ''}`,
        v.country
      ]
        .filter(i => i && i.trim())
        .join(', ');
      setVal(defaultValue || '');
      onChange({ value, name });
    } else {
      onChange({ name, value: null });
    }
  };
}

export function placeToString(place: null | Place) {
  if (
    !place ||
    !place.coords ||
    (((place.coords.lat === 50.0751635 && place.coords.lng === 14.4377237) ||
      (place.coords.lat === 48.14868920000001 && place.coords.lng === 17.1083788)) &&
      place.coords.init === true &&
      !place.street &&
      !place.postalCode &&
      !place.city &&
      !place.country &&
      !place.state &&
      !place.countryCode &&
      !place.stateCode)
  ) {
    return '';
  }

  const country =
    place.countryCode && !place.country
      ? ReactDomServer.renderToString(<Enum.Country v={place.countryCode} />)
      : null;

  return [
    place.street || null,
    place.country === 'United Kingdom'
      ? `${place.city || ''} ${place.postalCode || ''}`
      : `${place.postalCode || ''} ${place.city || ''}`,
    place.country === '' ? place.countryCode : place.country || country
  ]
    .filter(i => typeof i === 'string' && i.trim().length > 0)
    .join(', ');
}
