/**
 * @flow
 * @relayHash b7ef556249d0e471af8b70176b775cbc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DealCooperation = "RND_COOPERATION" | "SHARE_ASSETS" | "SHARE_PROCUREMENT" | "SPARE_CAPACITY" | "%future added value";
export type DealStatus = "CLOSED" | "OPEN" | "%future added value";
export type DealType = "OFFER" | "REQUEST" | "%future added value";
export type DealInput = {|
  summary: string,
  type: DealType,
  cooperation: DealCooperation,
  category: string,
  description?: ?string,
  isInternal?: ?boolean,
  onlyInternal?: ?boolean,
  keywords?: ?$ReadOnlyArray<string>,
  partnerRequirements?: ?string,
  attachments?: ?$ReadOnlyArray<string>,
  expires?: ?any,
  video?: ?string,
|};
export type NewDealPageMutationVariables = {|
  data: DealInput
|};
export type NewDealPageMutationResponse = {|
  +deal: {|
    +id: string,
    +status: DealStatus,
    +cooperation: DealCooperation,
    +type: DealType,
    +summary: string,
    +category: {|
      +id: string,
      +name: string,
    |},
    +expires: ?any,
    +description: string,
    +isInternal: boolean,
    +onlyInternal: boolean,
    +keywords: $ReadOnlyArray<any>,
    +partnerRequirements: ?string,
    +attachments: $ReadOnlyArray<{|
      +id: string,
      +name: string,
      +url: string,
    |}>,
    +video: ?string,
    +created: any,
    +modified: ?any,
    +company: {|
      +id: string
    |},
  |}
|};
export type NewDealPageMutation = {|
  variables: NewDealPageMutationVariables,
  response: NewDealPageMutationResponse,
|};
*/


/*
mutation NewDealPageMutation(
  $data: DealInput!
) {
  deal: createDeal(data: $data) {
    id
    status
    cooperation
    type
    summary
    category {
      id
      name
    }
    expires
    description
    isInternal
    onlyInternal
    keywords
    partnerRequirements
    attachments {
      id
      name
      url
    }
    video
    created
    modified
    company {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "DealInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": "deal",
    "name": "createDeal",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "Deal",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "cooperation",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "type",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "summary",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "category",
        "storageKey": null,
        "args": null,
        "concreteType": "NACECategory",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "expires",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "description",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isInternal",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "onlyInternal",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "keywords",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "partnerRequirements",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "attachments",
        "storageKey": null,
        "args": null,
        "concreteType": "File",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "url",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "video",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "created",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "modified",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "company",
        "storageKey": null,
        "args": null,
        "concreteType": "Company",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "NewDealPageMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "NewDealPageMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "NewDealPageMutation",
    "id": null,
    "text": "mutation NewDealPageMutation(\n  $data: DealInput!\n) {\n  deal: createDeal(data: $data) {\n    id\n    status\n    cooperation\n    type\n    summary\n    category {\n      id\n      name\n    }\n    expires\n    description\n    isInternal\n    onlyInternal\n    keywords\n    partnerRequirements\n    attachments {\n      id\n      name\n      url\n    }\n    video\n    created\n    modified\n    company {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bb3a71210325f63296d9ce9fb72e7dc1';
module.exports = node;
