// @flow

import I18n from '../../_helpers/I18n';
import * as React from 'react';

function ContractStatus(props: *): React.Node {
  return (
    <I18n
      {...props}
      id="contractStatus$"
      d={{
        COMPLETED: 'Completed',
        PROPOSED: 'Proposed',
        CLOSED: 'Closed',
        FAILED: 'Failed',
        OPEN: 'Open'
      }}
    />
  );
}

ContractStatus.types = ['COMPLETED', 'PROPOSED', 'CLOSED', 'FAILED', 'OPEN'];

export default ContractStatus;
