// @flow

import { graphql } from 'react-relay';
import commitMutationPromise from './commitMutationPromise';

export default function rememberLocaleMutation(locale: string) {
  commitMutationPromise({
    variables: { locale: (locale || '').toUpperCase() },
    mutation
  });
  return;
}

const mutation = graphql`
  mutation rememberLocaleMutation($locale: LangEnum!) {
    myLanguage(language: $locale) {
      id
      lang
    }
  }
`;
