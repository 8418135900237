// @flow

import type { StandardResponseTemplate } from './types';

export type FileResponse = {
  id: number
};

export default function uploadFile(
  url: string,
  config: {
    method: 'POST' | 'PUT' | 'PATCH',
    headers?: { [string]: string },
    onProgress?: number => void,
    onUpload?: () => void,
    onStart?: () => void,
    body: FormData
  }
): Promise<StandardResponseTemplate<FileResponse>> {
  return new Promise((resolve, reject) => {
    const { onStart, onProgress, onUpload, method, body, headers } = config;
    const xhr = new XMLHttpRequest();

    xhr.withCredentials = true;

    if (onStart) {
      xhr.upload.onloadstart = function(e) {
        onStart();
      };
    }

    if (onProgress) {
      xhr.upload.onprogress = function(e) {
        onProgress(e.loaded / e.total);
      };
    }

    if (onUpload) {
      xhr.upload.onloadend = function(e) {
        onUpload();
      };
    }

    xhr.onload = function() {
      try {
        resolve(JSON.parse(xhr.responseText));
      } catch (error) {
        reject(error);
      }
    };

    xhr.onerror = function() {
      reject(xhr.statusText);
    };

    xhr.open(method || 'POST', url, true);

    try {
      headers &&
        Object.keys(headers).forEach(key => {
          xhr.setRequestHeader(key, headers[key]);
        });
    } catch (e) {
      alert(e);
    }

    xhr.send(body);
  });
}
