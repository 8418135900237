// @flow

import * as React from 'react';
import I18n from '../_helpers/I18n';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Layout from '../_components/Layout';
import BBCode from '../_components/BBCode';

export type FaqPageProps = {};

export default function FaqPage(props: FaqPageProps) {
  const toggleClass = React.useCallback(key => {
    const elems = document.getElementsByClassName('card-header active');
    if (elems[0] && elems[0] !== key.target) {
      elems[0].classList.toggle('active');
    }
    key.target.classList.toggle('active');
  });

  return (
    <Layout.Common>
      <h1 className="mb-4 font-rubik-title">
        <I18n id="faq" d="Pomoc/FAQ" />
      </h1>
      <h2 className="mb-3 font-rubik font-rubik-bold">
        <I18n id="generalFaq" d="General FAQ" />
      </h2>
      <Row xs={12} md={12}>
        <Accordion className="flex-wrap accordion-faq">
          <Col sm={12} md={6}>
            <Card className="rounded-0">
              <Accordion.Toggle as={Card.Header} eventKey="0" onClick={toggleClass}>
                <I18n id="faq1title" d="Jaké výhody vám platforma přinese?" />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="pt-0 text-justify">
                  <div className="pt-3 pb-3 answer">
                    <I18n id="answer" d="Odpověď" />
                  </div>
                  <span className="answer-text">
                    <I18n
                      id="faq1answer"
                      d="Hlavním cílem spolupracovny je [b]ušetřit vám náklady a ještě lépe využít vaše stávající zdroje.[/b] Kromě toho vám používání této platformy přinese zjednodušení některých firemních procesů a úsporu času vašich zaměstnanců. Před 20 lety nebyl e-shop standardem, v dnešní době o jeho přínosu snad nikdo nepochybuje. Naší ambicí je, aby se firemní platforma stala standardem pro firmy usilující o inovace a digitalizaci B2B procesů."
                    >
                      {({ value }) => <BBCode>{value}</BBCode>}
                    </I18n>
                  </span>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="rounded-0">
              <Accordion.Toggle as={Card.Header} eventKey="1" onClick={toggleClass}>
                <I18n id="faq2title" d="Pro koho je platforma určená?" />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body className="pt-0 text-justify">
                  <div className="pt-3 pb-3 answer">
                    <I18n id="answer" d="Odpověď" />
                  </div>
                  <span className="answer-text">
                    <I18n
                      id="faq2answer"
                      d="Platforma je primárně určená pro [b]středně velké a velké firmy (případně i menší, které mohou tuto platformu s dalšími firmami sdílet),[/b] které mají několik provozoven, poboček, skladů nebo prodejen a větší množství dodavatelů a obchodních partnerů. Je určena pro vlastníky a vedení těchto firem, které chce [b]ušetřit náklady, maximálně využít firemní zdroje a mít přehled o potřebách firmy.[/b]"
                    >
                      {({ value }) => <BBCode>{value}</BBCode>}
                    </I18n>
                  </span>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="rounded-0">
              <Accordion.Toggle as={Card.Header} eventKey="2" onClick={toggleClass}>
                <I18n id="faq3title" d="Jaký je rozdíl mezi ERP a vaší platformou?" />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body className="pt-0 text-justify">
                  <div className="pt-3 pb-3 answer">
                    <I18n id="answer" d="Odpověď" />
                  </div>
                  <span className="answer-text">
                    <I18n
                      id="faq3answer"
                      d="Platforma má [b]doplňovat váš stávající ERP systém a rozšiřovat jeho možnosti,[/b] cílem není ERP nahradit. Naše platforma je nástroj pro:
                            [ul][li][b]rychlý přehled[/b] o aktuálních potřebách firmy,[/li]
                            [li]informace o [b]možnostech využití zdrojů a majetku[/b] firmy, [/li]
                            [li]pomoc při [b]hledání nových dodavatelů, zákazníků a strategických partnerů[/b] a[/li]
                            [li]zrychlení a zjednodušení [b]komunikace[/b] mezi uživateli.[/li][/ul]"
                    >
                      {({ value }) => <BBCode>{value}</BBCode>}
                    </I18n>
                  </span>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="rounded-0">
              <Accordion.Toggle as={Card.Header} eventKey="3" onClick={toggleClass}>
                <I18n
                  id="faq4title"
                  d="Potřebovali bychom integrovat náš ERP systém s vaší platformou, je to možné?"
                />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body className="pt-0 text-justify">
                  <div className="pt-3 pb-3 answer">
                    <I18n id="answer" d="Odpověď" />
                  </div>
                  <span className="answer-text">
                    <I18n
                      id="faq4answer"
                      d="Ano. [b]Platformu lze integrovat[/b] za použití rozhraní s vaším ERP systémem, konkrétně např. s adresní knihou nebo s nákupními objednávkami."
                    >
                      {({ value }) => <BBCode>{value}</BBCode>}
                    </I18n>
                  </span>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card className="rounded-0">
              <Accordion.Toggle as={Card.Header} eventKey="4" onClick={toggleClass}>
                <I18n
                  id="faq5title"
                  d="Máme již manažerskou nadstavbu (MIS), jak můžeme vaši platformu využít?"
                />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="4">
                <Card.Body className="pt-0 text-justify">
                  <div className="pt-3 pb-3 answer">
                    <I18n id="answer" d="Odpověď" />
                  </div>
                  <span className="answer-text">
                    <I18n
                      id="faq5answer"
                      d="Manažerské informační systémy konsolidují informace z ERP, např. ekonomické výsledky, pohledávky a závazky. Lze je použít i pro plánování nebo sledování klíčových ukazatelů za vybraná období. Naše platforma má sloužit k [b]přehledu aktuálních potřeb firmy[/b] v oblasti lidských zdrojů, výroby, nákupu, majetku, případně prodeje. Lze s ní řešit [b]na jednom místě několik aktuálních problémů[/b] – od vývoje produktu přes nákup, výrobu, prodej až po řešení odpadu."
                    >
                      {({ value }) => <BBCode>{value}</BBCode>}
                    </I18n>
                  </span>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="rounded-0">
              <Accordion.Toggle as={Card.Header} eventKey="5" onClick={toggleClass}>
                <I18n id="faq6title" d="Proč není na vaší stránce ceník?" />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="5">
                <Card.Body className="pt-0 text-justify">
                  <div className="pt-3 pb-3 answer">
                    <I18n id="answer" d="Odpověď" />
                  </div>
                  <span className="answer-text">
                    <I18n
                      id="faq6answer"
                      d="Důvodem jsou naprosto rozdílné a specifické požadavky jednotlivých klientů. [b]Konečná cena se stanoví podle toho, zda zákazník požaduje další modifikace platformy,[/b] jako např. specifické požadavky pro daný typ odvětví, rozhraní do ERP systému atp. nebo žádné změny platformy pro fungování své firmy nepotřebuje. Každopádně platí, že hlavním cílem platformy Spolupracovna.cz je [b]ušetřit vám náklady.[/b] Ani její cena vám tedy neudělá do rozpočtu „díru“. Kromě toho jsme flexibilní v oblasti fakturace ve formě jak CAPEX tak OPEX.  Žádné obavy, [b]vaše náklady na pořízení platformy se vám brzy vrátí. Poradíme vám, jak na to.[/b]"
                    >
                      {({ value }) => <BBCode>{value}</BBCode>}
                    </I18n>
                  </span>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="rounded-0">
              <Accordion.Toggle as={Card.Header} eventKey="6" onClick={toggleClass}>
                <I18n id="faq7title" d="Jak se naši noví dodavatelé dozví o naší nové platformě?" />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="6">
                <Card.Body className="pt-0 text-justify">
                  <div className="pt-3 pb-3 answer">
                    <I18n id="answer" d="Odpověď" />
                  </div>
                  <span className="answer-text">
                    <I18n
                      id="faq7answer"
                      d="Informace o existenci vaší nové platformy můžete snadno nasdílet přes vaše obvyklé komunikační kanály, jako například [b]firemní web, sociální sítě, intranet nebo tiskové výstupy jako letáky nebo třeba výroční zprávy.[/b] Nejde pouze o informaci pro vlastní zaměstnance či nové dodavatele, ale i pro potenciální nové obchodní partnery (třeba pro společný nákup nebo pro nabídku vlastního majetku k pronájmu)."
                    >
                      {({ value }) => <BBCode>{value}</BBCode>}
                    </I18n>
                  </span>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="rounded-0">
              <Accordion.Toggle as={Card.Header} eventKey="7" onClick={toggleClass}>
                <I18n id="faq8title" d="Kdo bude vlastnit data v této platformě na míru?" />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="7">
                <Card.Body className="pt-0 text-justify">
                  <div className="pt-3 pb-3 answer">
                    <I18n id="answer" d="Odpověď" />
                  </div>
                  <span className="answer-text">
                    <I18n
                      id="faq8answer"
                      d="Platforma bude zcela vaše. [b]Vy budete vlastníkem a budete mít přístup k datům.[/b] Jen vy pak rozhodujete, komu na platformu povolíte přístup. Platforma bude obsahovat [b]logo vaší firmy[/b] a poběží na [b]doméně, kterou si sami zvolíte[/b] (platformu můžeme na vaše přání nainstalovat i na váš server). Používáme nejmodernější způsoby zabezpečení dat a jejich uchovávání je v souladu s pravidly ochrany osobních údajů [b]dle GDPR.[/b]"
                    >
                      {({ value }) => <BBCode>{value}</BBCode>}
                    </I18n>
                  </span>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Col>
        </Accordion>
      </Row>
    </Layout.Common>
  );
}
