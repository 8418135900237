// @flow

import I18n from '../../../../_helpers/I18n';
import cn from 'classnames';
import * as React from 'react';
import { graphql } from 'react-relay';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import type { OnChangeEvent } from '../../';
import type { FileInterface } from '../FileInput/FileInput';

import Pencil from '../../../../_assets/images/pencil-coloured.svg';
import Undo from '../../../../_assets/images/ic_undo_24px.svg';
import Delete from '../../../../_assets/images/ic_delete_48px.svg';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import toastr from '../../../../_helpers/toastr';

import { commitMutationPromise } from '../../../../_helpers/Api';
import { htmlId } from '../FileInput/_helpers';
import ModalLogo from '../../../../_assets/images/modal-logo.svg';
import LoadingContent from '../../../../_pages/_components/LoadingContent'; // TODO: do not import from pages

// -------------------------------------------------------------------------------------------------

export type ImageInputProps = {|
  onChange: (OnChangeEvent<FileInterface>) => void,
  value: null | FileInterface,
  placeholder?: string,
  disabled?: boolean,
  name: string
|};

// -------------------------------------------------------------------------------------------------

const acceptList = ['image/jpg', 'image/jpeg', 'image/png'];

export default class FileInput extends React.PureComponent<ImageInputProps> {
  inputRef = React.createRef<HTMLInputElement>();

  constructor(props) {
    super(props);
    this.state = { modal: false, companyLogo: null };
    if (props.value) {
      this.state.companyLogo = props.value;
    }
  }

  render(): React.Node {
    const { value, disabled, placeholder } = this.props;
    if (value && value.id && !this.state.companyLogo) {
      this.setState({ companyLogo: value });
    }
    const img = value && !value.error && value.url;

    return (
      <Row noGutters>
        <Col xs={10} sm={10} md={10} className="form-conrol image">
          <input
            style={{ position: 'fixed', left: -9999, top: -9999, opacity: 0 }}
            onChange={this.handleSelect}
            accept={acceptList}
            ref={this.inputRef}
            name="file"
            type="file"
            disabled={disabled}
          />

          <div
            className={cn('image-wrapper', { disabled })}
            onClick={this.handleOpen}
            title={placeholder}
            style={{
              backgroundImage: img ? `url(${img})` : undefined
            }}
          >
            {value && !value.error ? (
              value.url ? null : (
                <LoadingContent />
              )
            ) : (
              <span className="placeholder">{placeholder || 'Image'}</span>
            )}
          </div>
        </Col>
        <Col xs={2} sm={2} md={2} className={cn('image-buttons', { disabled })}>
          <div className={cn('icon-div', { disabled })} onClick={this.handleOpen}>
            <img src={Pencil} alt="Edit logo" />
          </div>
          <div className={cn('icon-div', { disabled })} onClick={this.handleRemove}>
            {value && !value.error && value.blob ? (
              <img src={Undo} alt="Undo edit logo" />
            ) : (
              <img src={Delete} alt="Delete logo" />
            )}
          </div>
        </Col>
        <Modal show={this.state.modal} onHide={this.handleModalClose}>
          <Modal.Header closeButton className="pb-0 border-0">
            <Modal.Title className="p-3">
              <Row noGutters className="align-items-center flex-nowrap">
                <img src={ModalLogo} width="20px" height="40px" alt="Modal logo" />
                <span className="ml-3 font-rubik-modal-title">
                  <I18n id="modalTitle" d="Delete the company logo" />
                </span>
              </Row>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="border-0 pt-0 pb-0">
            <div className="p-3">
              {this.state.modal && (
                <I18n id="modalText" d="Are you sure to permanently delete the company logo?" />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Row className="w-100 ml-0 mr-0 pb-3">
              <Col sm={6} md={6}>
                <Button variant="outline-primary" onClick={this.handleModalClose} className="w-100">
                  <I18n id="cancelButton" d="Cancel" />
                </Button>
              </Col>
              <Col sm={6} md={6}>
                <Button variant="danger" onClick={this.handleModalApprove} className="w-100">
                  <I18n id="confirmButton" d="Delete" />
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </Row>
    );
  }

  // // --------------------------------------------------------------------------------------------

  handleOpen = () => {
    const ref = this.inputRef && this.inputRef.current;
    if (ref) {
      ref.value = '';
      ref.click();
    }
  };

  handleRemove = () => {
    if (this.props.value && this.props.value.id && this.props.value.name && this.props.value.url) {
      this.setState({ modal: true });
    } else if (this.props.value && this.props.value.url) {
      this.props.onChange({
        name: this.props.name,
        value: this.state.companyLogo
      });
    } else {
    }
  };

  // //  -------------------------------------------------------------------------------------------

  handleSelect = (e: SyntheticEvent<*>) => {
    if (acceptList.find(element => element === e.currentTarget.files[0].type) !== undefined) {
      const val = {
        name: e.currentTarget.files[0].name,
        size: e.currentTarget.files[0].size,
        htmlId: htmlId(),
        url: URL.createObjectURL(e.currentTarget.files[0]),
        lastModified: e.currentTarget.files[0].lastModified,
        lastModifiedDate: e.currentTarget.files[0].lastModifiedDate,
        webKitRelativePath: e.currentTarget.files[0].webKitRelativePath,
        type: e.currentTarget.files[0].type,
        blob: e.currentTarget.files[0]
      };
      this.props.onChange({
        name: this.props.name,
        value: val
      });
    } else {
      toastr({
        type: 'error',
        message: (
          <I18n
            id="typeError"
            d="File {file} has bad meta type."
            file={e.currentTarget.files[0].name}
          />
        )
      });
    }
  };

  handleModalClose = () => {
    this.setState({ modal: false });
  };

  handleModalApprove = () => {
    this.props.onChange({
      name: this.props.name,
      value: null
    });
    commitMutationPromise({
      mutation,
      variables: {},
      configs: [{}]
    }).then(response => ({ response, error: undefined }));
    this.setState({ modal: false, companyLogo: null });
  };
}

// mutation for deleting logo - ImageInputMutation -> mutation must have the same name as file where defined
const mutation = graphql`
  mutation ImageInputMutation {
    deleteLogo {
      logo {
        url
      }
    }
  }
`;
