/**
 * @flow
 * @relayHash b6d6f5e4409db9ad84c14a12b90a36f8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type articleQueryVariables = {|
  slug: string
|};
export type articleQueryResponse = {|
  +article: ?{|
    +id: string,
    +slug: string,
    +title: string,
    +content: string,
    +image: {|
      +url: string
    |},
    +keywords: ?$ReadOnlyArray<?string>,
    +category: string,
    +readingTime: number,
    +author: {|
      +name: string,
      +image: {|
        +url: string
      |},
    |},
    +comments: ?$ReadOnlyArray<?{|
      +user: {|
        +firstName: string,
        +lastName: string,
        +company: {|
          +logo: ?{|
            +id: string,
            +url: string,
          |}
        |},
      |},
      +comment: string,
      +created: any,
      +modified: ?any,
    |}>,
    +likesCount: number,
    +hasLiked: boolean,
    +created: any,
    +modified: ?any,
  |}
|};
export type articleQuery = {|
  variables: articleQueryVariables,
  response: articleQueryResponse,
|};
*/


/*
query articleQuery(
  $slug: String!
) {
  article(slug: $slug) {
    id
    slug
    title
    content
    image {
      url
      id
    }
    keywords
    category
    readingTime
    author {
      name
      image {
        url
        id
      }
      id
    }
    comments {
      user {
        firstName
        lastName
        company {
          logo {
            id
            url
          }
          id
        }
        id
      }
      comment
      created
      modified
      id
    }
    likesCount
    hasLiked
    created
    modified
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "image",
  "storageKey": null,
  "args": null,
  "concreteType": "File",
  "plural": false,
  "selections": [
    (v6/*: any*/)
  ]
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "keywords",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "category",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "readingTime",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "logo",
  "storageKey": null,
  "args": null,
  "concreteType": "File",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v6/*: any*/)
  ]
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "comment",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "modified",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "likesCount",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasLiked",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "image",
  "storageKey": null,
  "args": null,
  "concreteType": "File",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v2/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "articleQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "article",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "author",
            "storageKey": null,
            "args": null,
            "concreteType": "ArticleAuthor",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v7/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "comments",
            "storageKey": null,
            "args": null,
            "concreteType": "ArticleComment",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "company",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Company",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/)
                    ]
                  }
                ]
              },
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/)
            ]
          },
          (v18/*: any*/),
          (v19/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "articleQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "article",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v20/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "author",
            "storageKey": null,
            "args": null,
            "concreteType": "ArticleAuthor",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v20/*: any*/),
              (v2/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "comments",
            "storageKey": null,
            "args": null,
            "concreteType": "ArticleComment",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "company",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Company",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v2/*: any*/)
                    ]
                  },
                  (v2/*: any*/)
                ]
              },
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v2/*: any*/)
            ]
          },
          (v18/*: any*/),
          (v19/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "articleQuery",
    "id": null,
    "text": "query articleQuery(\n  $slug: String!\n) {\n  article(slug: $slug) {\n    id\n    slug\n    title\n    content\n    image {\n      url\n      id\n    }\n    keywords\n    category\n    readingTime\n    author {\n      name\n      image {\n        url\n        id\n      }\n      id\n    }\n    comments {\n      user {\n        firstName\n        lastName\n        company {\n          logo {\n            id\n            url\n          }\n          id\n        }\n        id\n      }\n      comment\n      created\n      modified\n      id\n    }\n    likesCount\n    hasLiked\n    created\n    modified\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4026a32e32dee46ac797565ea8091152';
module.exports = node;
