/**
 * @flow
 * @relayHash 6224fa14d6008323b2cefa5570b3b180
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ImageInputMutationVariables = {||};
export type ImageInputMutationResponse = {|
  +deleteLogo: {|
    +logo: ?{|
      +url: string
    |}
  |}
|};
export type ImageInputMutation = {|
  variables: ImageInputMutationVariables,
  response: ImageInputMutationResponse,
|};
*/

/*
mutation ImageInputMutation {
  deleteLogo {
    logo {
      url
      id
    }
    id
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = {
      kind: 'ScalarField',
      alias: null,
      name: 'url',
      args: null,
      storageKey: null
    },
    v1 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'ImageInputMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: [],
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'deleteLogo',
          storageKey: null,
          args: null,
          concreteType: 'Company',
          plural: false,
          selections: [
            {
              kind: 'LinkedField',
              alias: null,
              name: 'logo',
              storageKey: null,
              args: null,
              concreteType: 'File',
              plural: false,
              selections: [(v0 /*: any*/)]
            }
          ]
        }
      ]
    },
    operation: {
      kind: 'Operation',
      name: 'ImageInputMutation',
      argumentDefinitions: [],
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'deleteLogo',
          storageKey: null,
          args: null,
          concreteType: 'Company',
          plural: false,
          selections: [
            {
              kind: 'LinkedField',
              alias: null,
              name: 'logo',
              storageKey: null,
              args: null,
              concreteType: 'File',
              plural: false,
              selections: [(v0 /*: any*/), (v1 /*: any*/)]
            },
            (v1 /*: any*/)
          ]
        }
      ]
    },
    params: {
      operationKind: 'mutation',
      name: 'ImageInputMutation',
      id: null,
      text:
        'mutation ImageInputMutation {\n  deleteLogo {\n    logo {\n      url\n      id\n    }\n    id\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '120676c36d59e3956091aa5a25ca3ee7';
module.exports = node;
