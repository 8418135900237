/**
 * @flow
 * @relayHash dccdf86f19a4afe7cefbe6096afa8bb8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DealCooperation = "RND_COOPERATION" | "SHARE_ASSETS" | "SHARE_PROCUREMENT" | "SPARE_CAPACITY" | "%future added value";
export type DealStatus = "CLOSED" | "OPEN" | "%future added value";
export type DealType = "OFFER" | "REQUEST" | "%future added value";
export type MyDealsPageQueryVariables = {|
  cooperation?: ?$ReadOnlyArray<DealCooperation>,
  keywords?: ?$ReadOnlyArray<any>,
  status?: ?DealStatus,
  type?: ?DealType,
  category?: ?string,
  first: number,
  offset?: ?number,
  internal?: ?boolean,
|};
export type MyDealsPageQueryResponse = {|
  +search: {|
    +results: {|
      +pageInfo: {|
        +startCursor: ?number,
        +endCursor: ?number,
        +hasNextPage: boolean,
        +hasPreviousPage: boolean,
      |},
      +edges: $ReadOnlyArray<{|
        +cursor: number,
        +node: {|
          +id: string,
          +summary: string,
          +type: DealType,
          +cooperation: DealCooperation,
          +status: DealStatus,
          +category: {|
            +id: string,
            +name: string,
          |},
          +isInternal: boolean,
          +onlyInternal: boolean,
          +keywords: $ReadOnlyArray<any>,
          +created: any,
          +description: string,
          +unseenContracts: {|
            +total: number
          |},
        |},
      |}>,
      +total: number,
    |}
  |}
|};
export type MyDealsPageQuery = {|
  variables: MyDealsPageQueryVariables,
  response: MyDealsPageQueryResponse,
|};
*/


/*
query MyDealsPageQuery(
  $cooperation: [DealCooperation!]
  $keywords: [Keyword!]
  $status: DealStatus
  $type: DealType
  $category: ID
  $first: Int!
  $offset: Int
  $internal: Boolean
) {
  search {
    results: dealsConnection(cooperation: $cooperation, category: $category, keywords: $keywords, offset: $offset, status: $status, first: $first, type: $type, internal: $internal, mine: true) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          summary
          type
          cooperation
          status
          category {
            id
            name
          }
          isInternal
          onlyInternal
          keywords
          created
          description
          unseenContracts: contracts(seen: false) {
            total
          }
          __typename
        }
      }
      total
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "cooperation",
    "type": "[DealCooperation!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "keywords",
    "type": "[Keyword!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "status",
    "type": "DealStatus",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "DealType",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "category",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "internal",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "cooperation",
  "variableName": "cooperation"
},
v2 = {
  "kind": "Variable",
  "name": "internal",
  "variableName": "internal"
},
v3 = {
  "kind": "Variable",
  "name": "keywords",
  "variableName": "keywords"
},
v4 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v5 = {
  "kind": "Variable",
  "name": "type",
  "variableName": "type"
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "total",
  "args": null,
  "storageKey": null
},
v8 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "pageInfo",
    "storageKey": null,
    "args": null,
    "concreteType": "PageInfo",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "startCursor",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "endCursor",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasNextPage",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasPreviousPage",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "DealEdge",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "cursor",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "Deal",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "summary",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "cooperation",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "category",
            "storageKey": null,
            "args": null,
            "concreteType": "NACECategory",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isInternal",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "onlyInternal",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "keywords",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "created",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": "unseenContracts",
            "name": "contracts",
            "storageKey": "contracts(seen:false)",
            "args": [
              {
                "kind": "Literal",
                "name": "seen",
                "value": false
              }
            ],
            "concreteType": "ContractsConnection",
            "plural": false,
            "selections": [
              (v7/*: any*/)
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  (v7/*: any*/)
],
v9 = [
  {
    "kind": "Variable",
    "name": "category",
    "variableName": "category"
  },
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "mine",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "offset",
    "variableName": "offset"
  },
  (v4/*: any*/),
  (v5/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MyDealsPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "search",
        "storageKey": null,
        "args": null,
        "concreteType": "SearchQueries",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "results",
            "name": "__MyDeals_results_connection",
            "storageKey": null,
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "concreteType": "DealsConnection",
            "plural": false,
            "selections": (v8/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MyDealsPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "search",
        "storageKey": null,
        "args": null,
        "concreteType": "SearchQueries",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "results",
            "name": "dealsConnection",
            "storageKey": null,
            "args": (v9/*: any*/),
            "concreteType": "DealsConnection",
            "plural": false,
            "selections": (v8/*: any*/)
          },
          {
            "kind": "LinkedHandle",
            "alias": "results",
            "name": "dealsConnection",
            "args": (v9/*: any*/),
            "handle": "connection",
            "key": "MyDeals_results",
            "filters": [
              "cooperation",
              "location",
              "categories",
              "keywords",
              "status",
              "type",
              "internal"
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "MyDealsPageQuery",
    "id": null,
    "text": "query MyDealsPageQuery(\n  $cooperation: [DealCooperation!]\n  $keywords: [Keyword!]\n  $status: DealStatus\n  $type: DealType\n  $category: ID\n  $first: Int!\n  $offset: Int\n  $internal: Boolean\n) {\n  search {\n    results: dealsConnection(cooperation: $cooperation, category: $category, keywords: $keywords, offset: $offset, status: $status, first: $first, type: $type, internal: $internal, mine: true) {\n      pageInfo {\n        startCursor\n        endCursor\n        hasNextPage\n        hasPreviousPage\n      }\n      edges {\n        cursor\n        node {\n          id\n          summary\n          type\n          cooperation\n          status\n          category {\n            id\n            name\n          }\n          isInternal\n          onlyInternal\n          keywords\n          created\n          description\n          unseenContracts: contracts(seen: false) {\n            total\n          }\n          __typename\n        }\n      }\n      total\n    }\n  }\n}\n",
    "metadata": {
      "connection": [
        {
          "count": "first",
          "cursor": null,
          "direction": "forward",
          "path": [
            "search",
            "results"
          ]
        }
      ]
    }
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eb94dccaa72312e63a7388ee74afdc83';
module.exports = node;
