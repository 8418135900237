// @flow

import type { QueryRendererProps } from 'react-relay';

import * as React from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import type { meQueryResponse } from './__generated__/meQuery.graphql';

import env from '../relayEnvironment';

export default function myAccount(props: QueryRendererProps<meQueryResponse>): React.Node {
  return <QueryRenderer environment={env} query={query} render={props.children} />;
}

const query = graphql`
  query meQuery {
    me {
      id
      lang
      type
      salutation
      firstName
      lastName
      email
      contactEmail
      phone
      branch
      workposition
      messaging {
        type
        value
      }
      linkedin
      company {
        id
        name
        paying
        deals(first: 1) {
          total
        }
        populated
        subscription {
          type
          created
          unsubscribed
          expires
        }
        created
      }
    }
  }
`;

export { query };
