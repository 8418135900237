// @flow

import cn from 'classnames';
import * as React from 'react';

export default function LimitText(props: {
  component?: React.ElementType,
  style?: { [string]: * },
  className?: string,
  children: string,
  max: string
}): React.Node {
  const { max, component, children, style, className, ...passing } = props;
  const Component = component || 'span';
  return (
    // $FlowFixMe
    <Component
      {...passing}
      style={{ ...(style || {}), maxWidth: max }}
      className={cn('limit-text', className)}
    >
      {children}
    </Component>
  );
}
