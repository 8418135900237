// @flow

import * as React from 'react';
import I18n from '../../../_helpers/I18n';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RobotIcon from '../../../_assets/images/robot.svg';
import CartIcon from '../../../_assets/images/cart-simple-1.svg';
import GlassIcon from '../../../_assets/images/research.svg';
import HouseIcon from '../../../_assets/images/house.svg';
import CheckBox from '../../../_components/Form/_components/CheckBox';
import Navbar from 'react-bootstrap/Navbar';
import Collapse from 'react-bootstrap/Collapse';
import Enum from '../../../_components/Enum';
import BForm from 'react-bootstrap/Form';
import { Context as IdentityContext } from '../../../_helpers/Identity';
import Form, { ToggleInput, FormContext } from '../../../_components/Form';
import CooperationPicker from '../../_components/CooperationPicker';
import DealStatusDropdown from '../../_components/DealStatusDropdown';
import CategoryKeywordAutocomplete from '../../_components/CategoryKeywordAutocomplete';

export default function MyDealsCollapsibleFilter(): React.Node {
  const { identity } = React.useContext(IdentityContext);
  const { data } = React.useContext(FormContext);
  const icons = [RobotIcon, CartIcon, GlassIcon, HouseIcon];

  return (
    <Collapse in={data.open}>
      <div className="pb-5">
        <div className="filter-panel">
          <Navbar className="inline" variant="light" bg="white">
            <Row xs={12} md={12} className="m-0 w-100 toggl with-label">
              <Col sm={12} md={2} className="pl-0 d-flex align-items-center filter-label">
                <label className="pl-0 font-rubik-small font-rubik-bold">
                  <I18n id="opportunityType" d="Opportunity type" />
                </label>
              </Col>
              <Form.Field
                className="mr-0 mb-2 mb-sm-0 cooperation-picker"
                component={DealTypePicker}
                variant="primary"
                name="type"
              />
              {(identity.type === 'INTERNAL' || identity.type === 'INTERNAL_ADMIN') && (
                <BForm.Group className="mb-0 internal-container" controlId="internal">
                  <I18n id="onlyInternalPlaceholder" d="Internal Opportunity">
                    {placeholder => (
                      // $FlowFixMe
                      <Form.Field
                        className="font-rubik-small font-rubik-bold"
                        label={placeholder.value}
                        component={CheckBox}
                        name="internal"
                      />
                    )}
                  </I18n>
                </BForm.Group>
              )}
            </Row>
          </Navbar>
          <Navbar className="inline" variant="light" bg="white">
            <Form.Field
              component={CooperationPicker}
              icons={icons}
              name="cooperation"
              id="cooperationPicker"
              label
              multiple
            />
          </Navbar>
          <Navbar className="inline category-keyword" variant="light" bg="white">
            {/* $FlowFixMe */}
            <Row xs={12} md={12} className="m-0 w-100 toggl with-label">
              <Col xs={12} md={6} lg={4} className="px-0 d-flex align-items-center">
                <Col xs={6} md={6} className="pl-0 d-flex align-items-center">
                  <label className="pl-0 font-rubik-small font-rubik-bold">
                    <I18n id="opportunityStatus" d="Opportunity status" />
                  </label>
                </Col>
                <Col md={6} className="p-0">
                  <Form.Field component={DealStatusDropdown} name="status" />
                </Col>
              </Col>
              <Col
                xs={12}
                md={6}
                lg={8}
                className="pl-0 pr-0 d-flex align-items-center justify-content-end"
              >
                <Form.Field
                  style={{ paddingTop: 7, paddingBottom: 8, zIndex: 900 }}
                  component={CategoryKeywordAutocomplete}
                  id="filter-panel.categoryOrKeyword"
                  name="categoryOrKeyword"
                  block
                />
              </Col>
            </Row>
          </Navbar>
        </div>
      </div>
    </Collapse>
  );
}

// -------------------------------------------------------------------------------------------------

export function DealTypePicker(props: *): React.Node {
  return (
    <Enum.DealType>
      {({ d }) => (
        <ToggleInput
          {...props}
          options={Object.keys(d).map(key => ({
            label: <>{d[key]}</>,
            value: key
          }))}
          size="sm"
        />
      )}
    </Enum.DealType>
  );
}
