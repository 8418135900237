/**
 * @flow
 * @relayHash 05a6913f6bf0dcd7a5212327fd30ae75
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractStatus = "CLOSED" | "COMPLETED" | "FAILED" | "OPEN" | "PROPOSED" | "%future added value";
export type DealCooperation = "RND_COOPERATION" | "SHARE_ASSETS" | "SHARE_PROCUREMENT" | "SPARE_CAPACITY" | "%future added value";
export type manageDealQueryVariables = {|
  dealId: string
|};
export type manageDealQueryResponse = {|
  +deal: ?{|
    +id: string,
    +category: {|
      +id: string,
      +name: string,
    |},
    +company: {|
      +id: string
    |},
    +cooperation: DealCooperation,
    +summary: string,
    +contracts: {|
      +edges: $ReadOnlyArray<{|
        +cursor: number,
        +node: {|
          +id: string,
          +seen: boolean,
          +status: ?ContractStatus,
          +consumer: {|
            +id: string,
            +firstName: string,
            +lastName: string,
            +created: ?any,
            +totalContracts: number,
            +company: {|
              +id: string,
              +name: ?string,
            |},
          |},
          +deal: {|
            +id: string,
            +summary: string,
          |},
          +ownerUser: {|
            +id: string,
            +firstName: string,
            +lastName: string,
          |},
        |},
      |}>,
      +total: number,
    |},
    +user: {|
      +id: string
    |},
  |}
|};
export type manageDealQuery = {|
  variables: manageDealQueryVariables,
  response: manageDealQueryResponse,
|};
*/


/*
query manageDealQuery(
  $dealId: ID!
) {
  deal(id: $dealId) {
    id
    category {
      id
      name
    }
    company {
      id
    }
    cooperation
    summary
    contracts(first: 100) {
      edges {
        cursor
        node {
          id
          seen
          status
          consumer {
            id
            firstName
            lastName
            created
            totalContracts
            company {
              id
              name
            }
          }
          deal {
            id
            summary
          }
          ownerUser {
            id
            firstName
            lastName
          }
        }
      }
      total
    }
    user {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "dealId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  }
],
v3 = [
  (v1/*: any*/)
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "summary",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v7 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deal",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "dealId"
      }
    ],
    "concreteType": "Deal",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "category",
        "storageKey": null,
        "args": null,
        "concreteType": "NACECategory",
        "plural": false,
        "selections": (v2/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "company",
        "storageKey": null,
        "args": null,
        "concreteType": "Company",
        "plural": false,
        "selections": (v3/*: any*/)
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "cooperation",
        "args": null,
        "storageKey": null
      },
      (v4/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contracts",
        "storageKey": "contracts(first:100)",
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 100
          }
        ],
        "concreteType": "ContractsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ContractEdge",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Contract",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "seen",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "status",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "consumer",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "created",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "totalContracts",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "company",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Company",
                        "plural": false,
                        "selections": (v2/*: any*/)
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "deal",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Deal",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v4/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "ownerUser",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "total",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": (v3/*: any*/)
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "manageDealQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v7/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "manageDealQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v7/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "manageDealQuery",
    "id": null,
    "text": "query manageDealQuery(\n  $dealId: ID!\n) {\n  deal(id: $dealId) {\n    id\n    category {\n      id\n      name\n    }\n    company {\n      id\n    }\n    cooperation\n    summary\n    contracts(first: 100) {\n      edges {\n        cursor\n        node {\n          id\n          seen\n          status\n          consumer {\n            id\n            firstName\n            lastName\n            created\n            totalContracts\n            company {\n              id\n              name\n            }\n          }\n          deal {\n            id\n            summary\n          }\n          ownerUser {\n            id\n            firstName\n            lastName\n          }\n        }\n      }\n      total\n    }\n    user {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f4d340f3635383f43ac7098d3fe5f06c';
module.exports = node;
