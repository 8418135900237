// @flow

import * as React from 'react';
import I18n from '../_helpers/I18n';

function Toggle(props: *): React.Node {
  const { onChange, value, name, disabled } = props;

  const toggleIgnore = React.useCallback(() => {
    if (!disabled) {
      onChange({ name, value: { ...value, ignore: !(value && value.ignore) } });
    }
  }, [onChange, value, name]);

  const ignore = !!(value && value.ignore);
  const className = `toggle-component ${ignore ? '' : ' active'}`;
  const buttonClassName = `toggle-button ${ignore ? '' : ' active'}`;

  return (
    <div
      className="d-flex text-center align-items-center justify-content-center toggle-container"
      onClick={toggleIgnore}
    >
      <div className={className}>
        <div className={buttonClassName} />
      </div>
      <I18n id="enableLocationFilter" d="Enable location filter" />
    </div>
  );
}

export default Toggle;
