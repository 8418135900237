// @flow

const tagReg = /\[([a-z][a-zA-Z0-9]*)/;

export default function parser(string: string): Array<*> {
  let str = string; // stay immutable
  const list = []; // return collection

  while (str && str.length) {
    const found = str.match(tagReg); // find 1st tag
    if (found) {
      const pos = found.index;
      const match = found[0];
      const tag = type(found[1]);

      if (pos > 0) {
        // cut prefix text
        const txt = text(str.substr(0, pos));
        txt && list.push(txt);
        str = str.substr(pos);
      }

      str = str.substr(match.length); // cut off tagname
      str = findTag(str, tag);

      list.push(tag);
    } else {
      const txt = text(str);
      txt && list.push(txt);
      str = '';
    }
  }

  return list;
}

function type(type, child) {
  return {
    type: type,
    child: child || null
  };
}

function text(string) {
  let txt = string || '';
  txt = txt.trim().length ? txt : '';
  return txt ? type('text', txt) : null;
}

function findTag(string, tag) {
  let str = string;
  const { selfClosing, attrs, endAt } = parseAttributes(str); // find attributes

  if (attrs) {
    // $FlowFixMe
    tag.attrs = attrs;
  }
  if (selfClosing) {
    // $FlowFixMe
    tag.selfClosing = selfClosing;
  }
  str = str.substr(endAt);

  if (!selfClosing) {
    const closingTag = `[/${tag.type}]`;
    const closing = str.indexOf(closingTag);
    if (closing < 0) {
      throw new Error(`Missing closing tag ${closingTag}`);
    }
    tag.child = str.substr(0, closing);
    str = str.substr(closing + closingTag.length);
  }
  return str;
}

function parseAttributes(string) {
  let str = string;

  const shift = () => {
    endAt++;
    const c = str[0];
    str = str.substr(1);
    return c;
  };

  let selfClosing = false;
  const attrs: { [string]: void | boolean | number | string } = {};
  let endAt = 0;

  let escapesCount = 0;
  let curAttr = '';
  let currVal = '';

  let parseValue = false;

  while (str.length) {
    let c = shift();

    if (parseValue) {
      // parsing value
      while (str.length && c !== '"' && escapesCount % 2 !== 1) {
        if (c === '\\') {
          escapesCount++;
        }
        currVal += c;
        c = shift();
      }
      parseValue = false;
      attrs[curAttr] = currVal;
      curAttr = '';
      currVal = '';
    } else if (/[a-z]/.test(c)) {
      // test param name
      while (str.length && c !== ']' && c !== ' ' && c !== '=' && escapesCount % 2 !== 1) {
        // while param name not end
        if (c === '\\') {
          escapesCount++;
        }
        curAttr += c;
        c = shift();
      }
      // last attr is bool
      if (c === ']') {
        attrs[curAttr] = true;
        curAttr = '';
        break;
      } else if (!/^[a-z][a-zA-Z]+$/.test(curAttr)) {
        throw new Error(`Invalid attr name character "${curAttr}"`);
      } else {
        parseValue = c === '=' && str[0] === '"';
        if (!parseValue) {
          attrs[curAttr] = true;
          curAttr = '';
        } else {
          c = shift();
        }
      }
    } else if (c === '/' && str[0] === ']') {
      // selfclosing tag
      selfClosing = true;
    } else if (!c.trim()) {
      if (curAttr) {
        attrs[curAttr] = true;
        curAttr = '';
      }
      while (!str[0].trim()) {
        c = shift();
      }
    } else if (c === ']') {
      if (curAttr) {
        attrs[curAttr] = true;
      }
      parseValue = false;
      return { endAt, attrs: Object.keys(attrs).length ? attrs : null, selfClosing };
    } else {
      throw new Error(`Invalid tag syntax near: "${str.substr(0, 10)}..."`);
    }
  }

  return {
    selfClosing,
    attrs,
    endAt
  };
}
