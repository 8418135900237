// @flow
import * as React from 'react';

import Error404Page from '../Error/404Page';
import Layout from '../../_components/Layout';
import LoadingContent from './LoadingContent';

const TC = React.lazy(() => import('../TermsConditionsPage'));
const PP = React.lazy(() => import('../PrivacyPolicyPage'));
const SS = React.lazy(() => import('../SuccessStoryPage'));

const MAP = {
  '/terms&conditions': TC,
  '/privacy-policy': PP,
  '/success-story': SS
};

export type LazyProps = RouteProps<*>;

export default function LazyRoute(props: LazyProps): React.Node {
  const Component = MAP[props.match.url];

  return (
    <React.Suspense
      fallback={
        <Layout.Common narrow>
          <LoadingContent />
        </Layout.Common>
      }
    >
      {Component ? <Component /> : <Error404Page />}
    </React.Suspense>
  );
}
