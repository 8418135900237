// @flow

import * as React from 'react';

import Layout from '../../_components/Layout';
import Section1 from './_components/Section1';
import Section2 from './_components/Section2';
import Section3 from './_components/Section3';
import Section4 from './_components/Section4';
import Section5 from './_components/Section5';
import Footer from '../../_components/Footer';

// -------------------------------------------------------------------------------------------------

function HomePage(props: *): React.Node {
  return (
    <>
      <div className="home-page">
        {/* Video banner */}
        <Section1 />
        <div className="home-content">
          <span id="home-bg" />
          <Layout.Common noFooter>
            {/* more introduction info */}
            <Section2 />

            {/* Areas of cooperation */}
            <Section3 />

            {/* How it works */}
            <Section4 />

            <Section5 />
          </Layout.Common>
        </div>
      </div>

      <Footer sticky />
    </>
  );
}

export default HomePage;
