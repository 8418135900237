// @flow

import * as React from 'react';
import I18n from '../../_helpers/I18n';
import { graphql } from 'react-relay';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Redirect from 'react-router-dom/Redirect';

import type { DealFormData } from './_components/DealForm';

import Link from '../../_components/Link';
import toastr from '../../_helpers/toastr';
import Layout from '../../_components/Layout';
import DealForm from './_components/DealForm';
import { routerHistory } from '../../_helpers/router';
import { commitMutationPromise } from '../../_helpers/Api';
import LoadingContent from '../_components/LoadingContent';
import { decorateDealInput } from './_helpers/decorateDealInput';
import { Context as IdentityContext } from '../../_helpers/Identity';

// -------------------------------------------------------------------------------------------------

export default function NewDealPage(props: *): React.Node {
  const [pendingMutation, setPending] = React.useState<boolean>(false);
  const { identity, refreshIdentity, loading } = React.useContext(IdentityContext);
  const [paying] = React.useState(identity.company.paying);

  React.useEffect(() => {
    if (refreshIdentity() && !identity) {
      return <Redirect to="/error/401" />;
    }
  }, []);

  /*if (!identity.company.paying && identity.company.deals.total > 10) {
    return <Redirect to="/subscribe" />;
  }*/

  const populated = identity.company.populated;
  const userType = identity.type || null;

  if (loading) {
    return <LoadingContent />;
  }

  const personalDetail =
    identity.firstName && identity.lastName && identity.salutation && identity.lang ? true : false;

  return (
    <Layout narrow>
      <h1 className="mb-4 mt-4 font-rubik-title">
        <I18n id="title" d="Create new Opportunity" />
      </h1>

      {(!personalDetail && (
        <Alert variant="danger" className="d-flex align-items-center rounded-pill">
          <span>
            <I18n id="populateProfileAlert" d="Please fill in the profile details first." />
          </span>

          <Link to="/account/profile" className="ml-auto">
            <I18n id="populateProfileAlertButton" d="Update profile details" />
          </Link>
        </Alert>
      )) ||
        (!populated && (
          <Alert variant="danger" className="d-flex align-items-center rounded-pill">
            <span>
              <I18n id="populateCompanyAlert" d="Please fill in the company details first." />
            </span>

            <Link to="/account/company" className="ml-auto">
              <I18n id="populateCompanyAlertButton" d="Update company details" />
            </Link>
          </Alert>
        ))}

      <DealForm
        onSubmit={getSubmitHandler(paying, pendingMutation, setPending, userType)}
        disabled={!personalDetail || !populated || pendingMutation}
        populated={populated}
        paying={paying}
        userType={userType}
      >
        <Button
          className="mr-0 mr-md-3 d-block d-md-inline mb-3 mb-md-0"
          disabled={!personalDetail || !populated || pendingMutation}
          type="submit"
        >
          <I18n id="submitButton" d="Create new Opportunity" />
        </Button>
        <Link component={Button} variant="outline-primary" to="/account/my-deals">
          <I18n id="cancelButton" d="Cancel" />
        </Link>
      </DealForm>
    </Layout>
  );
}

// -------------------------------------------------------------------------------------------------

function getSubmitHandler(
  paying: Boolean,
  pendingMutation: boolean,
  setPending: boolean => void,
  userType: boolean
): (null | DealFormData) => void {
  return function handleSubmit(data: null | DealFormData): void {
    if (pendingMutation || !data) {
      return;
    }

    if (userType === 'INTERNAL' || userType === 'INTERNAL_ADMIN') {
      data.isInternal = true;
    }

    setPending(true);
    commitMutationPromise({
      variables: { data: decorateDealInput(data) },
      mutation: newDealMutation
    })
      .then(response => ({ response, error: undefined }))
      .catch(error => ({ error }))
      .then(({ error }) => {
        setPending(false);
        toastr({
          type: error ? 'error' : 'success',
          message: error ? (
            <I18n id="newDealFailed" d="New Opportunity cannot be saved" />
          ) : (
            /*{
              paying ?
            }(*/
            <I18n
              id="newDealCreated"
              d="New Opportunity '{summary}' has been added"
              // $FlowFixMe
              summary={data.summary}
            />
            //)
          ) /*: (
            <I18n
              id="newDealCreatedNotPaying"
              d="New Opportunity '{summary}' has been added. It can be edited within next 7 days."
              // $FlowFixMe
              summary={data.summary}
            />
          )*/
        });

        !error && routerHistory.push('/account/my-deals');
      });
  };
}

// -------------------------------------------------------------------------------------------------

// TODO: use fragment maybe ?
const newDealMutation = graphql`
  mutation NewDealPageMutation($data: DealInput!) {
    deal: createDeal(data: $data) {
      id
      status
      cooperation
      type
      summary
      category {
        id
        name
      }
      expires
      description
      isInternal
      onlyInternal
      keywords
      partnerRequirements
      attachments {
        id
        name
        url
      }
      video
      created
      modified
      company {
        id
      }
    }
  }
`;
