// @flow

import I18n from '../../../_helpers/I18n';
import BBCode from '../../../_components/BBCode/BBCode';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';

import Link from '../../../_components/Link';
import DateTime from '../../../_components/DateTime';
//import { Identity } from '../../../_helpers/Identity';
import CreateContractModal from './CreateContractModal';

// -------------------------------------------------------------------------------------------------

// TODO: use GQL fragment
export default function ContractListItem(props: { contract: * }): React.Node {
  const contract = props.contract;
  const user = contract && contract.consumer;
  const company = contract && contract.consumer.company;

  if (!company || !contract) {
    return null;
  }

  return (
    /*<Identity>
    {({ identity }) => (*/
    <Col md={6} className="mb-5">
      <Col className="contract-list-item">
        <Row>
          <Col className="mb-3 title-container">
            {!contract.seen && (
              <Badge variant="success" className="mr-2">
                <I18n id="unseen" d="NEW" />
              </Badge>
            )}
            {
              <Link
                to={`/company/${company.id}/user/${user.id}`}
                className={
                  contract.status === 'PROPOSED' ? 'text-success title' : 'text-primary title'
                }
              >
                <strong>{company.name}</strong>
              </Link>
            }
          </Col>
        </Row>
        <Row className="mb-4">
          {contract.recorded && <DateTime.Date value={contract.recorded} />}
          <Col>
            <Row noGutters className="mb-2">
              <strong className="mr-1">
                <I18n id="contactPerson" d="Contact person: " />
              </strong>
              {`${user.firstName || ''} ${user.lastName || ''}`}
            </Row>
            <Row noGutters className="mb-2">
              <strong className="mr-1">
                <I18n id="memberSince" d="Member since: " />
              </strong>
              <DateTime.Date value={user.created} />
            </Row>
            <Row noGutters className="mb-2">
              <I18n total={user.totalContracts} id="totalContracts" d="Total replies: {total}">
                {({ value }) => <BBCode>{value}</BBCode>}
              </I18n>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            {contract.status === 'PROPOSED' ? (
              /*identity && identity.company.paying ? (*/
              <CreateContractModal.Consumer>
                {({ setTarget }) => (
                  <Button onClick={createContract(setTarget, contract)} variant="success">
                    <I18n id="createButton" d="Create Reply" />
                  </Button>
                )}
              </CreateContractModal.Consumer>
            ) : (
              /*) : (
                    <Link className="ml-auto" component={Button} variant="warning" to="/subscribe">
                      <I18n id="subscribeButton" d="Subscribe" />
                    </Link>
                  )*/
              <Link
                to={`/account/my-contracts/update/${contract.id}/edit`}
                component={Button}
                className="ml-auto"
              >
                <I18n id="updateButton" d="Update Reply" />
              </Link>
            )}
          </Col>
        </Row>
      </Col>
    </Col>
    /*)}
    </Identity>*/
  );
}

function createContract(setter: (*) => void, target: *): () => void {
  return function openCreateContractModel() {
    setter && target && setter(target);
  };
}
