// @flow

import * as React from 'react';
import I18n from '../../../_helpers/I18n';

import Icon from '../../../_components/Icon';
import Row from 'react-bootstrap/Row';
import Link from '../../../_components/Link';
import BBCode from '../../../_components/BBCode';
import HumanIcon from '../../../_assets/images/single-01.svg';
import EmailIcon from '../../../_assets/images/email-84.svg';
import PhoneIcon from '../../../_assets/images/phone-2.svg';
import OracleIcon from '../../../_assets/images/oracle-p.png';
import NinoxIcon from '../../../_assets/images/Ninox_Partner_Badge.png';
import AmcefIcon from '../../../_assets/images/amcef_logo_final.png';

// Pricing
export default function Section5(): React.Node {
  return (
    <div className="home-s5" id="pricing">
      {/* Title */}
      <div className="d-flex flex-column p5 mb-5">
        <h2 className="page-heading big">
          <I18n id="title" d="SPOLUPRACOVNA has been already used by" />
        </h2>
      </div>
      <div className="slide-container">
        <div className="slide">
          <img src={OracleIcon} className="slide-image" width="160" alt="" />
        </div>
        <div className="slide">
          <img src={NinoxIcon} className="slide-image ninox" width="85" alt="" />
        </div>
        <div className="slide">
          <img src={AmcefIcon} className="slide-image" width="155" alt="" />
        </div>
        <div className="slide">
          <img src={OracleIcon} className="slide-image" width="160" alt="" />
        </div>
        <div className="slide">
          <img src={NinoxIcon} className="slide-image ninox" width="85" alt="" />
        </div>
        <div className="slide">
          <img src={AmcefIcon} className="slide-image" width="155" alt="" />
        </div>
      </div>
      <div className="d-flex flex-column align-items-center p5">
        <h5 className="page-heading mb-4">
          <I18n id="subtitle" d="Do you want to ask us anything?" />
        </h5>
        <span className="text-muted mb-4">
          <I18n
            id="description"
            d="We are here for you at the available contact methods below. We already look forward to hearing from you."
          />
        </span>
        <Row className="p-3 contact justify-content-between">
          <div className="d-flex align-items-center contact-container">
            <img width="22" height="24" src={HumanIcon} alt="Show modal with video on click" />
            <span className="contact-detail pl-2">
              <I18n id="contactName" d="Peter Kubala" />
            </span>
          </div>
          <div className="d-flex align-items-center contact-container">
            <img width="26" height="20" src={EmailIcon} alt="Show modal with video on click" />
            <span className="contact-detail pl-2">
              <I18n
                id="contactEmail"
                d='[a href="mailto:pkubala@zynctos.com"]pkubala@zynctos.com[/a]'
              >
                {({ value }) => <BBCode>{value}</BBCode>}
              </I18n>
            </span>
          </div>
          <div className="d-flex align-items-center contact-container">
            <img width="21" height="21" src={PhoneIcon} alt="Show modal with video on click" />
            <span className="contact-detail pl-2">
              <I18n id="contactPhone" d='[a href="tel:+420 605 207 271"]+420 605 207 271[/a]'>
                {({ value }) => <BBCode>{value}</BBCode>}
              </I18n>
            </span>
          </div>
          <div className="d-flex align-items-center contact-container">
            <Icon name="linkedin" size={24} color="#000" />
            <span className="contact-detail pl-2">
              <Link to={`https://www.linkedin.com/company/zynctosconsulting/`}>
                <I18n id="linkedIn" d="LinkedIn" />
              </Link>
            </span>
          </div>
        </Row>
      </div>
    </div>
  );
}
