// @flow

import * as React from 'react';
import I18n from '../../_helpers/I18n';
import BForm from 'react-bootstrap/Form';
import Link from 'react-router-dom/Link';
import Button from 'react-bootstrap/Button';
import Redirect from 'react-router-dom/Redirect';

import type { LoginActionVariables } from '../../_helpers/Api/Auth';

import toastr from '../../_helpers/toastr';
import { AuthApi } from '../../_helpers/Api';
import Layout from '../../_components/Layout';
import BBCode from '../../_components/BBCode';
import { routerHistory } from '../../_helpers/router';
import { Context as I18nContext } from '../../_helpers/I18n';
import CheckBox from '../../_components/Form/_components/CheckBox';
import { Context as IdentityContext } from '../../_helpers/Identity';
import Form, { TextInput, PasswordInput } from '../../_components/Form';
import getCaptchaToken from '../../_helpers/getCaptchaToken';

// -------------------------------------------------------------------------------------------------

const signInValidation = {
  email: { required: true, email: true },
  password: { required: true }
};

// -------------------------------------------------------------------------------------------------

const defaultValue = { email: '', password: '', rememberMe: false };

export default function LoginPage(props: *): React.Node {
  const { identity, updateIdentity } = React.useContext(IdentityContext);
  const i18nContext = React.useContext(I18nContext);

  return (
    <AuthApi action="login">
      {(action, { loading }) => (
        <Layout.Auth>
          <h1 className="w-100 font-rubik-title mt-2 mb-4">
            <I18n id="title" d="Login" />
          </h1>
          {identity ? (
            <Redirect to="/" />
          ) : (
            <Form
              className="mt-4 mb-4 login-form"
              onSubmit={handleSubmit(action, updateIdentity, i18nContext)}
              validationRules={signInValidation}
              defaultValue={defaultValue}
            >
              <BForm.Group>
                <I18n id="emailPlaceholder" d="Email">
                  {placeholder => (
                    <Form.Field
                      placeholder={placeholder.value}
                      autoComplete="new-username"
                      component={TextInput}
                      disabled={loading}
                      name="email"
                      type="email"
                    />
                  )}
                </I18n>
              </BForm.Group>
              <BForm.Group>
                <I18n id="passwordPlaceholder" d="Password">
                  {placeholder => (
                    // $FlowFixMe
                    <Form.Field
                      placeholder={placeholder.value}
                      autoComplete="new-password"
                      component={PasswordInput}
                      disabled={loading}
                      name="password"
                      type="password"
                    />
                  )}
                </I18n>
              </BForm.Group>

              <BForm.Group>
                <Button variant="primary" block type="submit" disabled={loading}>
                  <I18n id="submit" d="Login" />
                </Button>
              </BForm.Group>

              <BForm.Group className="remember-me" controlId="rememberMe">
                <Link className="text-primary" to="/auth/forgotten">
                  <I18n id="forgotLink" d="Forgot Password?" />
                </Link>
                <I18n id="rememberMePlaceholder" d="Remember me">
                  {placeholder => (
                    // $FlowFixMe
                    <Form.Field
                      label={placeholder.value}
                      component={CheckBox}
                      disabled={loading}
                      name="rememberMe"
                      type="checkbox"
                      className="remember-checkbox"
                    />
                  )}
                </I18n>
              </BForm.Group>
            </Form>
          )}

          <p className="w-100 sign-up">
            <I18n id="signupText" d="Don't have an account yet?" />{' '}
            <Link to="/auth/sign-up">
              <I18n id="signupLink" d="Sign Up!" />
            </Link>
          </p>
        </Layout.Auth>
      )}
    </AuthApi>
  );
}

// -------------------------------------------------------------------------------------------------

// handle submit action
const handleSubmit = (
  action: ?(LoginActionVariables) => Promise<*>,
  updateIdentity: (*) => void,
  i18nContext
) => (data: LoginActionVariables) => {
  if (!action) {
    return;
  }

  getCaptchaToken('login').then(captcha => {
    action({
      ...data,
      rememberMe: !!data.rememberMe,
      captcha
    }).then(response => handleResponse(updateIdentity, response, data, i18nContext));
  });
};

// -------------------------------------------------------------------------------------------------

// set identity or display errors
const handleResponse = (updateIdentity, response, data: LoginActionVariables, i18nContext: any) => {
  if (response && response.ok) {
    const user = response.data;

    if (user.id && user.company && user.company.id && user.email) {
      // TODO: create general identity decorator
      updateIdentity({
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        company: user.company,
        permissions: /*user.company.paying ? ['paying', 'registered'] : */ ['registered'],
        email: user.email,
        type: user.type,
        id: user.id
      });

      const { locale, setLocale } = i18nContext;

      if (user.lang && locale !== user.lang) {
        setLocale(user.lang.toLowerCase());
      }

      routerHistory.afterLogin();

      setTimeout(() => {
        toastr({
          type: 'success',
          message: <I18n id="successLogin" d="Welcome!" />
        });
      }, 300);
    } else {
      toastr({
        type: 'error',
        message: <I18n id="errorLogin" d="Ooops! User entity is invalid" />
      });
    }
  } else {
    const code =
      typeof response.code === 'string' && response.code.startsWith('CAPTCHA_')
        ? 'INVALID_CAPTCHA'
        : (response && response.code) || null;

    const message = (
      <I18n
        id="errMessage$"
        d={{
          EMAIL_NOT_VERIFIED: 'Please verify your email to log in again.',
          UNAUTHORIZED: 'Authorization failed, please try again.',
          UNKNOWN_ERROR: 'Ooops! Something went wrong.',
          TOKEN_NOT_PROVIDED: 'Authorization failed!',
          BAD_REQUEST: 'Invalid input data.',
          INVALID_CREDENTIALS: 'Invalid email or password',
          INVALID_CAPTCHA: 'Ooops something went wrong with antispam check, please try again.'
        }}
        v={(response && response.code) || 'UNKNOWN_ERROR'}
      >
        {({ d }) => {
          return (code && d[code]) || d.UNKNOWN_ERROR;
        }}
      </I18n>
    );

    toastr({
      type: 'error',
      message
    });

    if (response.status === 422) {
      toastr({
        options: { timeOut: 0, progressBar: false },
        type: 'warning',
        message: (
          <>
            <I18n
              id="resendMessage"
              d={
                'Did not get the validation email?[br /]Click [a href="/auth/resend-verification?email={email}"]here to re-send it[/a].[br /]Also, please make sure to check your spam folder.'
              }
              email={data.email}
            >
              {({ value }) => <BBCode>{value}</BBCode>}
            </I18n>
          </>
        )
      });
    }
  }
};
