/**
 * @flow
 * @relayHash 968aef5efcef508b8d2a1ba29d0eebaf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractStatus = "CLOSED" | "COMPLETED" | "FAILED" | "OPEN" | "PROPOSED" | "%future added value";
export type DealCooperation = "RND_COOPERATION" | "SHARE_ASSETS" | "SHARE_PROCUREMENT" | "SPARE_CAPACITY" | "%future added value";
export type DealType = "OFFER" | "REQUEST" | "%future added value";
export type manageContractQueryVariables = {|
  contractId: string
|};
export type manageContractQueryResponse = {|
  +contract: ?{|
    +id: string,
    +status: ?ContractStatus,
    +notes: ?string,
    +created: ?any,
    +consumer: {|
      +id: string,
      +firstName: string,
      +lastName: string,
      +company: {|
        +id: string,
        +name: ?string,
      |},
    |},
    +deal: {|
      +id: string,
      +summary: string,
      +type: DealType,
      +cooperation: DealCooperation,
      +category: {|
        +id: string,
        +name: string,
      |},
      +user: {|
        +id: string
      |},
    |},
    +ownerUser: {|
      +id: string,
      +firstName: string,
      +lastName: string,
    |},
  |}
|};
export type manageContractQuery = {|
  variables: manageContractQueryVariables,
  response: manageContractQueryResponse,
|};
*/


/*
query manageContractQuery(
  $contractId: ID!
) {
  contract(id: $contractId) {
    id
    status
    notes
    created
    consumer {
      id
      firstName
      lastName
      company {
        id
        name
      }
    }
    deal {
      id
      summary
      type
      cooperation
      category {
        id
        name
      }
      user {
        id
      }
    }
    ownerUser {
      id
      firstName
      lastName
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  }
],
v5 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "contract",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "contractId"
      }
    ],
    "concreteType": "Contract",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "notes",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "created",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "consumer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "company",
            "storageKey": null,
            "args": null,
            "concreteType": "Company",
            "plural": false,
            "selections": (v4/*: any*/)
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deal",
        "storageKey": null,
        "args": null,
        "concreteType": "Deal",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "summary",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "cooperation",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "category",
            "storageKey": null,
            "args": null,
            "concreteType": "NACECategory",
            "plural": false,
            "selections": (v4/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "ownerUser",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "manageContractQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v5/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "manageContractQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v5/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "manageContractQuery",
    "id": null,
    "text": "query manageContractQuery(\n  $contractId: ID!\n) {\n  contract(id: $contractId) {\n    id\n    status\n    notes\n    created\n    consumer {\n      id\n      firstName\n      lastName\n      company {\n        id\n        name\n      }\n    }\n    deal {\n      id\n      summary\n      type\n      cooperation\n      category {\n        id\n        name\n      }\n      user {\n        id\n      }\n    }\n    ownerUser {\n      id\n      firstName\n      lastName\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '12a8c0ad17305920495ad1d13ce579fe';
module.exports = node;
