// @flow

import idx from 'idx';
import I18n from '../../../_helpers/I18n';
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Logger from '@app/Logger';
import ContractList from './ContractList';
import { Query } from '../../../_helpers/Api';
import Layout from '../../../_components/Layout';
import Redirect from 'react-router-dom/Redirect';
import LoadingContent from '../../_components/LoadingContent';
import SeenContractsNotifier from './SeenContractsNotifier';
import { Context as IdentityContext } from '../../../_helpers/Identity';
import { CooperationIcon } from '../../_components/DealList/DealListItem';

// -------------------------------------------------------------------------------------------------

export type ManagedealPageProps = RouteProps<{ dealId: string }>;

// -------------------------------------------------------------------------------------------------

export default function ManageDealPage(props: ManagedealPageProps): React.Node {
  const dealId = props.match.params.dealId;
  const { identity } = React.useContext(IdentityContext);

  return (
    <Layout.Common narrow>
      <Query name="manageDeal" variables={{ dealId }}>
        {({ loading, error, data }) => {
          if (loading) {
            return <LoadingContent />;
          }

          if (error) {
            Logger.error(error);
            return <Redirect to="/error/500" />;
          }

          const deal = data && data.deal;

          if (!deal) {
            Logger.error(`Cannot manage unknown deal. id = ${dealId}`);
            return <Redirect to="/error/404" />;
          }

          if (
            !identity ||
            deal.company.id !== identity.company.id ||
            deal.user.id !== identity.id
          ) {
            return <Redirect to="/error/403" />;
          }

          const potential = [];
          const existing = [];

          if (deal.contracts && deal.contracts.edges) {
            deal.contracts.edges.forEach(edge => {
              const contract = edge.node;
              contract.status === 'PROPOSED' ? potential.push(contract) : existing.push(contract);
            });
          }

          return (
            <>
              <Row>
                <Col>
                  <h1 className="mb-4 font-rubik-title">
                    <I18n id="title" d="Manage Opportunity" summary={deal.summary} />
                  </h1>

                  <span className="cooperation-subtitle d-block border-top border-bottom">
                    <CooperationIcon
                      value={deal.cooperation}
                      className="float-left mr-2 text-primary"
                      grayScale
                    />
                    {deal.summary}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2 className="mt-4 mb-4 subtitle text-success">
                    <I18n id="potentialTitle" d="Potential partners" summary={deal.summary} />
                  </h2>

                  <ContractList
                    items={potential}
                    noItemsMsg={
                      <I18n id="noPotentialResults" d="No poroposed partners were found." />
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2 className="mb-4 pt-0 subtitle text-primary">
                    <I18n id="existingTitle" d="Existing partners" summary={deal.summary} />
                  </h2>

                  <ContractList
                    items={existing}
                    noItemsMsg={<I18n id="noExistingResults" d="No partners were found yet." />}
                  />
                </Col>
              </Row>

              <SeenContractsNotifier
                items={idx(deal, _ => _.contracts.edges) || []}
                dealId={props.match.params.dealId}
              />
            </>
          );
        }}
      </Query>
    </Layout.Common>
  );
}
