export default {
  anonym: [
    {
      label: {
        id: '_components.menu.config.homeBtn',
        d: 'Home'
      },
      path: '/#'
    },
    {
      label: {
        id: '_components.menu.config.examplesBtn',
        d: 'Examples'
      },
      path: '/#examples'
    },
    {
      label: {
        id: '_components.menu.config.blogBtn',
        d: 'Our blog'
      },
      path: '/#blogs'
    },
    {
      label: {
        id: '_components.menu.config.faqBtn',
        d: 'FAQ'
      },
      path: '/#faq'
    }
  ],

  user: [
    {
      label: {
        id: '_components.menu.config.searchDealsBtn',
        d: 'Search Opportunities'
      },
      path: '/deal'
    },
    {
      label: {
        id: '_components.menu.config.createDealBtn',
        d: 'Create Opportunity'
      },
      path: '/deal/new'
    },
    {
      label: {
        id: '_components.menu.config.myDealsBtn',
        d: 'My Opportunities'
      },
      path: '/account/my-deals'
    },
    {
      label: {
        id: '_components.menu.config.myContractsBtn',
        d: 'My Replies'
      },
      path: '/account/my-contracts'
    }
  ],
  profile: [
    {
      label: {
        id: '_components.menu.config.myProfile',
        d: 'My Profile'
      },
      path: '/account/profile'
    },
    {
      label: {
        id: '_components.menu.config.myAccount',
        d: 'My Account'
      },
      path: '/account'
    },
    {
      label: {
        id: '_components.menu.config.myCompany',
        d: 'My Company'
      },
      path: '/account/company'
    },
    {
      label: {
        id: '_components.menu.config.logout',
        d: 'Logout'
      },
      path: '/auth/logout'
    }
  ]
};
