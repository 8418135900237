/**
 * @flow
 * @relayHash d1ab0e145b55fb0059cf67f0222f94a6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type searchKeywordsQueryVariables = {|
  search?: ?string
|};
export type searchKeywordsQueryResponse = {|
  +search: {|
    +keywords: $ReadOnlyArray<any>
  |}
|};
export type searchKeywordsQuery = {|
  variables: searchKeywordsQueryVariables,
  response: searchKeywordsQueryResponse,
|};
*/

/*
query searchKeywordsQuery(
  $search: String
) {
  search {
    keywords(search: $search, first: 300)
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'search',
        type: 'String',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'search',
        storageKey: null,
        args: null,
        concreteType: 'SearchQueries',
        plural: false,
        selections: [
          {
            kind: 'ScalarField',
            alias: null,
            name: 'keywords',
            args: [
              {
                kind: 'Literal',
                name: 'first',
                value: 300
              },
              {
                kind: 'Variable',
                name: 'search',
                variableName: 'search'
              }
            ],
            storageKey: null
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'searchKeywordsQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'searchKeywordsQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    params: {
      operationKind: 'query',
      name: 'searchKeywordsQuery',
      id: null,
      text:
        'query searchKeywordsQuery(\n  $search: String\n) {\n  search {\n    keywords(search: $search, first: 300)\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '12adf8b5eed06b24b6c34c66a8b8dce3';
module.exports = node;
