// @flow

import type { ButtonProps } from 'react-bootstrap/Button';

import * as React from 'react';
import Button from 'react-bootstrap/Button';
import showIcon from '../_assets/images/eye-19-show.svg';
import hideIcon from '../_assets/images/eye-19-hide.svg';

type Props = ButtonProps & { icon: string };

export default function IconButton(props: Props): React.Node {
  const { icon, className, ...passing } = props;
  return (
    <Button className={'btn-icon' + (className ? ' ' + className : '')} {...passing}>
      {/* Have to use this ugly hack because question mark icon is missing from the pack */}
      {icon === 'question' ? (
        <b> ? </b>
      ) : icon === 'seen' ? (
        <img src={showIcon} width="18" height="14" alt="" />
      ) : (
        <img src={hideIcon} width="18" height="14" alt="" />
      )}
    </Button>
  );
}
