// @flow

import * as React from 'react';

import type { TypeaheadOptions } from './Typeahead';

import Enum from '../../Enum';
import Typeahead from './Typeahead';
import type { OnChangeEvent } from '../';

export type USStateInputProps = {
  onChange: (OnChangeEvent<string>) => void,
  value: null | string,
  name: string,
  id: string
};

const v = [];

export default function USStateInput(props: USStateInputProps): React.Node {
  const { value, id, onChange, name, ...rest } = props;
  const country = value ? value.toUpperCase() : null;
  return (
    <Enum.USState>
      {({ d }) => {
        const options = d ? Object.keys(d).map(id => ({ value: id, label: d[id] })) : [];
        v.splice(0, 100);

        const val =
          country && d && d.hasOwnProperty(country) ? [{ value: country, label: d[country] }] : [];

        return (
          <Typeahead
            {...rest}
            onChange={changeHandler(onChange)}
            options={options}
            value={val}
            name={name}
            id={id}
          />
        );
      }}
    </Enum.USState>
  );
}

function changeHandler(
  onChange: (OnChangeEvent<string>) => void
): (OnChangeEvent<TypeaheadOptions>) => void {
  return function(event: OnChangeEvent<TypeaheadOptions>) {
    const { name, value } = event;
    const val = value && Array.isArray(value) ? value[0] && value[0].value : value && value.value;
    onChange && onChange({ name, value: val || null });
  };
}
