// @flow

import { fetchQuery } from 'relay-runtime';

import relayEnviroment from '../../relayEnvironment';
import meQuery from '../../queries/__generated__/meQuery.graphql';

export default function me(): Promise<*> {
  return fetchQuery(relayEnviroment, meQuery)
    .then(res =>
      res.me ? { ok: true, status: 200, code: 'OK', data: res.me } : { ok: false, status: 401 }
    )
    .catch(e => ({ ok: false, status: 401 }));
}
