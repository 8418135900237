// @flow

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const initialLength = history.length;

let redirectOnLogin = null;

history.cangoBack = () => {
  return history.length >= initialLength;
};

history.rememberUnauthorized = (val: string) => {
  redirectOnLogin = val;
};

history.afterLogin = () => {
  if (redirectOnLogin) {
    const val = redirectOnLogin;
    redirectOnLogin = null;
    history.replace(val);
  }
};

export default history;
