// @flow

import idx from 'idx';
import * as React from 'react';
import RLP from 'react-location-picker';

import type { OnChangeEvent } from '../';

import Logger from '../../../_helpers/Logger';
import { getActiveLocale } from '../../../_helpers/I18n/I18n';
import { placeToString } from '../../GoogleAutocompleteInput';
import googlePlaceParser from '../../../_helpers/googlePlaceParser';

export type MapPlacePickerProps = {|
  onChange: (OnChangeEvent<Place>) => void,
  radius?: null | number, // km
  height?: null | string,
  zoom?: null | number,
  value: null | Place,
  name: string,
  confirmation?: string
|};

type Position = {
  lat: number,
  lng: number
};

export default class MapPlacePicker extends React.Component<MapPlacePickerProps> {
  constructor(props) {
    super(props);
    if (getActiveLocale() === 'sk') {
      this.state = {
        coords: {
          lng: 17.1082802,
          lat: 48.1486015,
          init: true
        }
      };
    } else {
      this.state = {
        coords: {
          lng: 14.4377237,
          lat: 50.0751635,
          init: true
        }
      };
    }
    this.getCurrentPosition();
  }

  componentDidUpdate() {
    this.getCurrentPosition();
  }

  getCurrentPosition() {
    const { name, value } = this.props;
    if (
      (value &&
        value.coords.init &&
        value.coords.lat === 50.0751635 &&
        value.coords.lng === 14.4377237) ||
      (value &&
        value.coords.init &&
        value.coords.lat === 48.1486015 &&
        value.coords.lng === 17.1082802)
    ) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const coords = {
              lng: position.coords.longitude,
              lat: position.coords.latitude
            };
            this.setState({ coords: coords });
            this.props.onChange({ name, value: { ...value, coords: coords } });
          },
          error => {},
          { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true }
        );
      } else {
      }
    }
  }

  render(): React.Node {
    const { name, radius, value } = this.props;
    let defaultPosition = { lat: 0, lng: 0 };
    if (
      value &&
      value.coords &&
      value.coords.lat === 0 &&
      value.coords.lng === 0 &&
      this.state.coords
    ) {
      defaultPosition = this.state.coords;
      this.props.onChange({ name, value: { ...value, coords: this.state.coords } });
    }
    const radiusValue = typeof radius === 'number' && radius > -1 ? radius * 1000 : -1;
    const zoom = this.props.zoom ? this.props.zoom : getDefaultZoom(radiusValue);

    return (
      <RLP
        circleOptions={{ fillColor: '#2591fe', strokeColor: '#2591fe', strokeWeight: 5 }}
        containerElement={<div className="map-picker" style={{ height: '100%' }} />}
        defaultPosition={(value && value.coords) || defaultPosition}
        mapElement={<div style={{ height: this.props.height ? this.props.height : '400px' }} />}
        onChange={this.handleLocationChange}
        radius={radiusValue}
        zoom={zoom}
      />
    );
  }

  shouldComponentUpdate(next: MapPlacePickerProps): boolean {
    const a = idx(next, _ => _.value.coords) || {};
    const b = idx(this.props, _ => _.value.coords) || {};
    const update = a.lng !== b.lng || a.lat !== b.lat || this.props.radius !== next.radius;
    return update;
  }

  handleLocationChange = (data: {
    places: Array<*>,
    position: Position,
    address: string
  }): void => {
    const confirm = this.props.confirmation;
    const prev = this.props.value;
    const onChange = this.props.onChange;
    const name = this.props.name;
    const curr = data.position;
    if (
      prev &&
      prev.street &&
      typeof prev === typeof data.position &&
      (prev && prev.coords && prev.coords.lat === curr.lat && prev.coords.lng === curr.lng)
    ) {
      /*
        skip initi = if 'react-location-picker' component gets value on init,
        it will try to find input position, and then hit onchange if its confirmed
        so we want to populate this action only if position is invalid or different
        -> same = skip
      */
      return;
    }

    try {
      if (data && data.places && data.places[0]) {
        if (data.position.init !== true && !confirm) {
          const value: null | Place = googlePlaceParser(data.places[0]);
          onChange && onChange({ name, value });
        } else if (confirm) {
          const value: null | Place = googlePlaceParser(data.places[0]);
          onChange && onChange({ name, value: { coords: value.coords } });
        }
      }
    } catch (e) {
      Logger.error(e);
    }
  };

  handleAddressInputsChange = (): void => {
    const { name, value } = this.props;
    const request = {
      query: placeToString(value),
      fields: ['geometry.location', 'place_id', 'formatted_address']
    };
    const bind = this;
    const prioritySearchValues = [];
    const prioritySearchValue = Object.create(value);
    prioritySearchValues.push(placeToString(prioritySearchValue));
    prioritySearchValue.street = prioritySearchValue.street.split(' ')[0];
    prioritySearchValues.push(placeToString(prioritySearchValue));
    prioritySearchValue.street = ' ';
    prioritySearchValues.push(placeToString(prioritySearchValue));
    prioritySearchValue.postalCode = ' ';
    prioritySearchValues.push(placeToString(prioritySearchValue));
    const search = async () => {
      await bind.asyncForEach(prioritySearchValues, async element => {
        request.query = element;
        const res = await bind.getPlace(request).then(result => {
          if (result) {
            const coords = {
              lng: result.geometry.location.lng(),
              lat: result.geometry.location.lat()
            };
            bind.setState({ coords: coords });
            bind.props.onChange({ name, value: { ...value, coords: coords } });
            return false;
          }
          return true;
        });
        if (!res) {
          return null;
        }
      });
    };
    search();
  };

  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      const res = await callback(array[index], index, array);
      if (res === null) {
        break;
      }
    }
  }

  getPlace(request) {
    return new Promise(resolve => {
      const google = window.google;
      const service = new google.maps.places.PlacesService(document.createElement('div'));
      service.findPlaceFromQuery(request, function(results, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(results[0]);
        } else {
          resolve(null);
        }
      });
    });
  }
}

function getDefaultZoom(radius: number | null): ?number {
  if (radius === -1 || radius === null) {
    return undefined;
  }

  if (radius === 1000) {
    return 14;
  }

  if (radius > 1100000) {
    return 3;
  }

  if (radius > 600000) {
    return 4;
  }

  if (radius >= 350000) {
    return 5;
  }

  if (radius >= 200000) {
    return 6;
  }

  if (radius >= 100000) {
    return 7;
  }

  if (radius >= 50000) {
    return 8;
  }
}
