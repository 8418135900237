// @flow

import I18n from '../../../../_helpers/I18n';
import * as React from 'react';
import { graphql } from 'react-relay';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import toastr from '../../../../_helpers/toastr';
import type { DealModalTarget } from './EditModeButtons';
import { commitMutationPromise } from '../../../../_helpers/Api';
import ModalLogo from '../../../../_assets/images/modal-logo.svg';

// -------------------------------------------------------------------------------------------------

export type CloseDealModalContext = {|
  setTarget: (?DealModalTarget) => void,
  target: ?DealModalTarget
|};

const Context = React.createContext<CloseDealModalContext>({
  setTarget: function() {},
  target: null
});

// -------------------------------------------------------------------------------------------------

export default function CloseDealModal(props: { children: React.Node }): React.Node {
  const [target, setTarget] = React.useState(null);

  return (
    <Context.Provider value={{ target, setTarget }}>
      <>
        {props.children}
        <Modal show={!!target} onHide={close(setTarget)}>
          <Modal.Header closeButton className="pb-0 border-0">
            <Modal.Title className="p-3">
              <Row noGutters className="align-items-center flex-nowrap">
                <img src={ModalLogo} width="20px" height="40px" alt="Modal logo" />
                <span className="ml-3 font-rubik-modal-title">
                  <I18n id="title" d="Close Opportunity" />
                </span>
              </Row>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="border-0 pt-0 pb-0">
            <div className="p-3">
              {target && (
                <I18n
                  id="text"
                  d="Are you sure to close the Opportunity: '{name}'"
                  name={target.summary}
                />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Row className="w-100 ml-0 mr-0 pb-3">
              <Col sm={6} md={6}>
                <Button variant="outline-primary" onClick={close(setTarget)} className="w-100">
                  <I18n id="cancelButton" d="Cancel" />
                </Button>
              </Col>
              <Col sm={6} md={6}>
                <Button variant="secondary" onClick={confirm(setTarget, target)} className="w-100">
                  <I18n id="confirmButton" d="Close" />
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </>
    </Context.Provider>
  );
}

// -------------------------------------------------------------------------------------------------

function close(setTarget: (?DealModalTarget) => void): () => void {
  return function closeModal(): void {
    setTarget(null);
  };
}

function confirm(setTarget: (?DealModalTarget) => void, target: ?DealModalTarget): () => void {
  return function confirmModal(): void {
    target &&
      commitMutationPromise({
        mutation,
        variables: { dealId: target.id }
      })
        .then(response => ({ response, error: undefined }))
        .catch(error => ({ error }))
        .then(({ error }) => {
          setTarget(null);
          toastr({
            type: error ? 'error' : 'success',
            message: error ? (
              <I18n id="errorResult" d="Opportunity status has not been changed!" />
            ) : (
              <I18n id="successResult" d="Opportunity has been closed" />
            )
          });
        });
  };
}

const mutation = graphql`
  mutation CloseDealModalMutation($dealId: ID!) {
    closeDeal(id: $dealId) {
      id
      status
    }
  }
`;

export { Context };
