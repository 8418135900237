/**
 * @flow
 * @relayHash b828c9f7953941b9982f02c90f9138e9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type searchCategoryQueryVariables = {|
  search?: ?string
|};
export type searchCategoryQueryResponse = {|
  +search: {|
    +categories: $ReadOnlyArray<?{|
      +value: string,
      +label: string,
    |}>
  |}
|};
export type searchCategoryQuery = {|
  variables: searchCategoryQueryVariables,
  response: searchCategoryQueryResponse,
|};
*/

/*
query searchCategoryQuery(
  $search: String
) {
  search {
    categories(search: $search, first: 300) {
      value: id
      label: name
      id
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'search',
        type: 'String',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'Literal',
        name: 'first',
        value: 300
      },
      {
        kind: 'Variable',
        name: 'search',
        variableName: 'search'
      }
    ],
    v2 = {
      kind: 'ScalarField',
      alias: 'value',
      name: 'id',
      args: null,
      storageKey: null
    },
    v3 = {
      kind: 'ScalarField',
      alias: 'label',
      name: 'name',
      args: null,
      storageKey: null
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'searchCategoryQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'search',
          storageKey: null,
          args: null,
          concreteType: 'SearchQueries',
          plural: false,
          selections: [
            {
              kind: 'LinkedField',
              alias: null,
              name: 'categories',
              storageKey: null,
              args: (v1 /*: any*/),
              concreteType: 'NACECategory',
              plural: true,
              selections: [(v2 /*: any*/), (v3 /*: any*/)]
            }
          ]
        }
      ]
    },
    operation: {
      kind: 'Operation',
      name: 'searchCategoryQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'search',
          storageKey: null,
          args: null,
          concreteType: 'SearchQueries',
          plural: false,
          selections: [
            {
              kind: 'LinkedField',
              alias: null,
              name: 'categories',
              storageKey: null,
              args: (v1 /*: any*/),
              concreteType: 'NACECategory',
              plural: true,
              selections: [
                (v2 /*: any*/),
                (v3 /*: any*/),
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'id',
                  args: null,
                  storageKey: null
                }
              ]
            }
          ]
        }
      ]
    },
    params: {
      operationKind: 'query',
      name: 'searchCategoryQuery',
      id: null,
      text:
        'query searchCategoryQuery(\n  $search: String\n) {\n  search {\n    categories(search: $search, first: 300) {\n      value: id\n      label: name\n      id\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '1ce82b33ebbd6648f8c96b4b229446cb';
module.exports = node;
