// @flow

import cn from 'classnames';
import I18n from '../../../_helpers/I18n';
import * as React from 'react';
import { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import { getActiveLocale } from '../../../_helpers/I18n/I18n';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import SuggestedModal from '../DealList/_components/SuggestedModal';
import queryString from 'query-string';

import RobotIcon from '../../../_assets/images/robot.svg';
import CartIcon from '../../../_assets/images/cart-simple-1.svg';
import GlassIcon from '../../../_assets/images/research.svg';
import HouseIcon from '../../../_assets/images/house.svg';
import BusinessPartnersIcon from '../../../_assets/images/multiple-11.svg';
import ClockIcon from '../../../_assets/images/ic_access_time_24px.svg';
import Enum from '../../../_components/Enum';
import Link from '../../../_components/Link';
import ExpandText from '../../../_components/ExpandText';
import EditModeButtons from './_components/EditModeButtons';
import { Context as IdentityContext } from '../../../_helpers/Identity';

const DESC_LENGTH = 190;

// -------------------------------------------------------------------------------------------------

export type DealListItemProps = {|
  editMode?: boolean,
  deal: *
|};

export type CooperationProps = {|
  value: string,
  grayScale?: boolean,
  small?: boolean
|};

export default function DealListItem(props: DealListItemProps): React.Node {
  const { deal, editMode } = props;
  const [suggestedModal, setSuggestedModal] = useState(false);
  const { identity } = React.useContext(IdentityContext);
  const [mobile, setMobile] =
    window.innerWidth > 991 ? React.useState(false) : React.useState(true);

  const mobileSize = () => {
    if (window.innerWidth < 992) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  window.addEventListener('resize', mobileSize);

  function handleSuggestedClose() {
    setSuggestedModal(false);
  }

  function handleSuggestedShow() {
    setSuggestedModal(true);
  }

  const queryParam = queryString.parse(window.location.search);
  const animation = queryParam.suggested !== undefined && queryParam.suggested === 'true';

  if (!deal) {
    return null;
  }

  const categories = deal.category && deal.category.name;
  const internalUser =
    identity && (identity.type === 'INTERNAL' || identity.type === 'INTERNAL_ADMIN') ? true : false;

  return (
    <div className="deal-search-item">
      <Row>
        <Badge
          variant="outline-secondary"
          className={cn(
            'ml-3 deal-type',
            {
              offer: deal.type === 'OFFER'
            },
            {
              request: deal.type === 'REQUEST'
            }
          )}
        >
          <Enum.DealType v={deal.type} />
        </Badge>
        {deal && internalUser && !mobile && (
          <Badge
            variant="outline-secondary"
            className={cn(
              'internal-opportunity',
              {
                offer: deal.type === 'OFFER'
              },
              {
                request: deal.type === 'REQUEST'
              }
            )}
          >
            {deal.onlyInternal ? <I18n id="internalOpportunity" d="Internal opportunity" /> : ''}
          </Badge>
        )}
        <Row className="mx-0 mt-3 mb-3 d-md-flex align-items-center w-100 justify-content-between">
          <Col sm={12} md={10} lg={7}>
            {deal && internalUser && mobile && (
              <Badge
                variant="outline-secondary"
                className={cn(
                  'internal-opportunity',
                  {
                    offer: deal.type === 'OFFER'
                  },
                  {
                    request: deal.type === 'REQUEST'
                  }
                )}
              >
                {deal.onlyInternal ? (
                  <I18n id="internalOpportunity" d="Internal opportunity" />
                ) : (
                  ''
                )}
              </Badge>
            )}
            <Row className="ml-0 w-100">
              <Col xs={10} sm={9} md={9} lg={9} className="p-0">
                <Link
                  to={editMode ? `/deal/edit/${deal.id}` : `/deal/detail/${deal.id}`}
                  className="summary d-inline-flex"
                >
                  <CooperationIcon value={deal.cooperation} grayScale />
                  <div className="d-flex title-container">
                    <h3 className="title text-break">{deal.summary}</h3>
                  </div>
                </Link>
              </Col>
              <Col
                xs={2}
                sm={3}
                md={3}
                lg={3}
                className="pr-0 d-flex align-items-center justify-content-end"
              >
                {editMode && <EditModeButtons deal={deal} />}
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={2} lg={2} className="px-0 mt-3 mt-md-0 d-flex align-items-center">
            {editMode && (
              <Col
                className={cn(
                  'deal-status d-flex justify-content-center',
                  deal.status === 'OPEN'
                    ? 'font-rubik-bold text-success'
                    : 'font-rubik-bold text-secondary'
                )}
              >
                <I18n id="status$" d={{ OPEN: 'Open', CLOSED: 'Closed' }} v={deal.status} />
              </Col>
            )}
          </Col>
          <Col
            sm={12}
            md={12}
            lg={3}
            className="px-0 mt-3 mt-md-0 d-flex align-items-center justify-content-end"
          >
            {editMode && (
              <>
                <Col
                  sm={12}
                  md={6}
                  lg={12}
                  onClick={handleSuggestedShow}
                  className={`suggested-modal-button ${animation ? 'animation' : ''} `}
                >
                  <div className="suggested-deals-button w-100">
                    <I18n id="suggestedDeals" d="Suggested deals" />
                  </div>
                </Col>
                <SuggestedModal
                  show={suggestedModal}
                  onClose={handleSuggestedClose}
                  dealId={deal.id}
                />
              </>
            )}
          </Col>
        </Row>
      </Row>
      <Row className="mb-2">
        <Col className="mb-2 d-flex flex-column text-justify description mr-auto">
          <ExpandText text={deal.description || ''} length={DESC_LENGTH} />
        </Col>
        {editMode && deal.unseenContracts && deal.unseenContracts.total > 0 && (
          <Col sm={12} md={6} lg={4} className="text-right">
            <Link
              to={`/deal/manage/${deal.id}`}
              component={Button}
              variant="warning"
              size="md"
              className="w-100 new-contact-button"
            >
              <I18n
                id="unseenContracts"
                d="{count} New {count, plural, one {Contract} other {contracts}}"
                count={deal.unseenContracts.total}
              />
            </Link>
          </Col>
        )}
      </Row>
      <Row className="mb-2">
        <Col className="mr-auto">
          <Keywords keywords={deal.keywords} myDeals={!!editMode} id={deal.id} />
        </Col>
        {editMode && deal.id && (
          <Col sm={12} md={6} lg={4} className="text-right">
            <Link
              component={Button}
              variant="primary"
              size="md"
              to={`/deal/manage/${deal.id}`}
              className="w-100 business-partners-button"
            >
              <img src={BusinessPartnersIcon} className="mr-3" width="17px" height="17px" alt="" />
              <I18n id="manage" d="Business Partners" />
            </Link>
          </Col>
        )}
      </Row>
      <Row className="deal-search-item__bottom">
        <Col className="deal-search-item__categories" title={categories} xs={6} sm={6} md={6}>
          {categories}
        </Col>
        <Col xs={6} sm={6} md={6} className="text-right font-weight-bold">
          <img src={ClockIcon} className="mr-3" width="20px" height="20px" alt="" />
          <DaysAgo value={deal.created} />
        </Col>
      </Row>
    </div>
  );
}

// -------------------------------------------------------------------------------------------------

export function CooperationIcon(props: CooperationProps): React.Node {
  const { grayScale, small } = props;

  const getIcon = (key: any) => {
    if (key === 'SPARE_CAPACITY') {
      return small ? (
        <img
          src={RobotIcon}
          className={cn('mb-1 mr-2 toggle-icon', { 'filter-grayscale': grayScale })}
          width="20px"
          height="20px"
          alt=""
        />
      ) : (
        <img
          src={RobotIcon}
          className={cn('mr-3 toggle-icon', { 'filter-grayscale': grayScale })}
          width="33px"
          height="34px"
          alt=""
        />
      );
    } else if (key === 'SHARE_PROCUREMENT') {
      return small ? (
        <img
          src={CartIcon}
          className={cn('mb-1 mr-2 toggle-icon', { 'filter-grayscale': grayScale })}
          width="20px"
          height="20px"
          alt=""
        />
      ) : (
        <img
          src={CartIcon}
          className={cn('mr-3 toggle-icon', { 'filter-grayscale': grayScale })}
          width="28px"
          height="26px"
          alt=""
        />
      );
    } else if (key === 'RND_COOPERATION') {
      return small ? (
        <img
          src={GlassIcon}
          className={cn('mb-1 mr-2 toggle-icon', { 'filter-grayscale': grayScale })}
          width="14px"
          height="24px"
          alt=""
        />
      ) : (
        <img
          src={GlassIcon}
          className={cn('mr-3 toggle-icon', { 'filter-grayscale': grayScale })}
          width="28px"
          height="54px"
          alt=""
        />
      );
    }
    return small ? (
      <img
        src={HouseIcon}
        className={cn('mb-1 mr-2 toggle-icon', { 'filter-grayscale': grayScale })}
        width="22px"
        height="22px"
        alt=""
      />
    ) : (
      <img
        src={HouseIcon}
        className={cn('mr-3 toggle-icon', { 'filter-grayscale': grayScale })}
        width="44px"
        height="44px"
        alt=""
      />
    );
  };

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip>
          <Enum.Cooperation v={props.value} />
        </Tooltip>
      }
    >
      <span>{getIcon(props.value)}</span>
    </OverlayTrigger>
  );
}

// -------------------------------------------------------------------------------------------------

function Keywords(props: { keywords: Array<string>, myDeals: boolean, id: string }): React.Node {
  return (
    <div className="deal-search-item__keywords d-flex flex-wrap">
      {props.keywords.reduce((list: Array<React.Node>, keyword: string, index: number) => {
        keyword &&
          list.push(
            <div key={props.id + keyword + index} className="keyword-container">
              <Link
                to={`${props.myDeals ? '/account/my-deals' : '/deal'}?keywords=${keyword}`}
                className="mr-3 keyword"
              >
                {keyword}
              </Link>
            </div>
          );
        return list;
      }, [])}
    </div>
  );
}

export function DaysAgo(props: { value: string }) {
  const { value } = props;
  const valueDate = new Date(value);
  const diff = Math.floor((new Date() - valueDate) / 86400000);
  let days = 'days';
  if (getActiveLocale() === 'de') {
    if (diff < 2) {
      days = 'Tag';
    } else {
      days = 'Tagen';
    }
  } else if (getActiveLocale() === 'cz') {
    if (diff < 2) {
      days = 'dnem';
    } else {
      days = 'dny';
    }
  } else if (getActiveLocale() === 'sk') {
    if (diff < 2) {
      days = 'dňom';
    } else {
      days = 'dňami';
    }
  } else if (diff < 2) {
    days = 'day';
  }

  return diff === 0 ? (
    <I18n id="today" d="today" />
  ) : (
    <I18n id="daysAgo" d="{count} {days} ago" count={diff} days={days} />
  );
}
