// @flow

import * as React from 'react';
import { hot } from 'react-hot-loader/root';
import Router from 'react-router-dom/Router';

import Menu from './_components/Menu';
import routes from './_pages/_helpers/routes';
import me from './_helpers/Api/Auth/actions/me';
import { routerHistory } from './_helpers/router';
import { ErrorBoundary } from './_helpers/Logger';
import IdentityProvider from './_helpers/Identity';
import { Provider as I18nProvider } from './_helpers/I18n';
import { renderRoutes, ScrollToTop } from './_helpers/router';
import LoadingContent from './_pages/_components/LoadingContent';
import { getActiveLocale } from './_helpers/I18n/I18n';
import videoCZ from './_assets/video/videoCZ.mp4';
import videoEN from './_assets/video/videoEN.mp4';

// -------------------------------------------------------------------------------------------------

class App extends React.PureComponent<*> {
  componentDidMount() {
    window.document.onkeydown = e => {
      if (!e) {
        return;
      }
      if (e.keyCode === 27) {
        this.lightboxClose();
      }
    };
  }

  lightboxClose() {
    const lightBoxVideo = document.getElementById('VisaChipCardVideo');
    document.getElementById('fade').style.display = 'none';
    lightBoxVideo.pause();
  }

  render(): React.Node {
    return (
      <ErrorBoundary>
        <IdentityProvider confirmIdentity={me}>
          <I18nProvider>
            {loading =>
              loading ? (
                <LoadingContent />
              ) : (
                <>
                  <div id="fade" onClick={this.lightboxClose}>
                    <div id="light">
                      <span className="boxclose" id="boxclose" onClick={this.lightboxClose} />
                      <video id="VisaChipCardVideo" width="600" controls>
                        <source
                          src={getActiveLocale() === 'en' ? videoEN : videoCZ}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  </div>
                  <Menu />
                  <Router history={routerHistory}>
                    <ScrollToTop>{renderRoutes(routes)}</ScrollToTop>
                  </Router>
                </>
              )
            }
          </I18nProvider>
        </IdentityProvider>
      </ErrorBoundary>
    );
  }
}

export default hot(App);
