// @flow

import * as React from 'react';

import DealListItem from './DealListItem';
import CloseDealModal from './_components/CloseDealModal';
import DeleteDealModal from './_components/DeleteDealModal';

export default function DealList(props: { items: Array<*>, editMode?: boolean }): React.Node {
  const items = props.items
    .filter(i => i)
    .map((edge, key) => (
      <DealListItem editMode={props.editMode} key={edge.cursor} deal={edge.node} />
    ));

  if (props.editMode) {
    return (
      <DeleteDealModal>
        <CloseDealModal>
          <W>{items}</W>
        </CloseDealModal>
      </DeleteDealModal>
    );
  }

  return <W>{items}</W>;
}

const W = (props: { children: React.Node }): React.Node => (
  <div className="deal-search-results">{props.children}</div>
);
