// @flow

import I18n from '../../_helpers/I18n';
import * as React from 'react';

import ErrorLayout from './ErrorLayout';
import BBCode from '../../_components/BBCode';

// -------------------------------------------------------------------------------------------------

export default function Error500Page(): React.Node {
  return (
    <ErrorLayout>
      <h1>
        <I18n id="title" d="500 [br /]Server error">
          {({ value }) => <BBCode>{value}</BBCode>}
        </I18n>
      </h1>
      <p>
        <I18n id="text" d="Ooops, something went wrong.[br /]We're working on fixing the issue.">
          {({ value }) => <BBCode>{value}</BBCode>}
        </I18n>
      </p>
    </ErrorLayout>
  );
}
