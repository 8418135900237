// @flow

import type { FileInterface } from './FileInput';

/**
 * Find dom node and update upload progress
 *
 * @param {File} file
 * @param {FileInterface} uploadItem
 */

const getRequestOptions = (file: File, uploadItem: FileInterface) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  return {
    onProgress: setFileProgress(uploadItem),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
    body: formData
  };
};

/**
 * Find dom node and update upload progress
 *
 * @param {File} file
 * @param {string} htmlId
 */

function setFileProgress(file: FileInterface): number => void {
  return per => {
    const elem = file.htmlId && document.getElementById(file.htmlId);
    if (elem) {
      elem.innerHTML = ` ${(per * 100).toFixed(0)}%`;
    }
  };
}

/**
 * @returns {string}
 */
function htmlId(): string {
  return (
    'file-upload-item-' +
    Date.now().toString() +
    Math.random()
      .toFixed(10)
      .replace('.', '')
  );
}

export { getRequestOptions, setFileProgress, htmlId };
