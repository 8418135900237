// @flow

// TODO: Rename to general REST api or smthing not only auth

import me from './me';
import reset from './reset';
import login from './login';
import logout from './logout';
import register from './register';
import forgotten from './forgotten';
import resendVerification from './resendVerification';
import unsubscribePayment from './unsubscribePayment';

const actions = {
  unsubscribePayment,
  resendVerification,
  forgotten,
  register,
  logout,
  reset,
  login,
  me
};

export default actions;

export function getEndpoint() {
  return process.env.REACT_APP_API_HOST || '';
}
