// @flow

import idx from 'idx';
import * as React from 'react';
import { graphql } from 'react-relay';
import queryString from 'query-string';

import type { OnChangeEvent } from '../../_components/Form';

import { fetchQuery } from '../../_helpers/Api';

export default function MyLocationFakeInput(props: {
  onChange: (OnChangeEvent<*>) => void,
  value: *,
  name: string,
  disabled: boolean
}): React.Node {
  const { name, onChange, value, disabled } = props;

  const res = window.location.search ? queryString.parse(window.location.search) : null;

  React.useEffect(() => {
    if (!value && !disabled && value === null) {
      if (!res || !res.lat || !res.lng) {
        fetchQuery(myLocationQuery)
          .then(data => {
            const place = idx(data, _ => _.me.place);
            place && onChange && onChange({ name, value: place });
          })
          .catch(_ => {});
      } else {
        const place = {
          city: res.city,
          coords: { lat: parseFloat(res.lat), lng: parseFloat(res.lng) },
          countryCode: res.countryCode,
          postalCode: res.postalCode,
          street: res.street
        };
        place && onChange && onChange({ name, value: place });
      }
    }
  }, [name, onChange, value, disabled]);

  return null;
}

const myLocationQuery = graphql`
  query MyLocationFakeInputQuery {
    me {
      place: company {
        street
        postalCode: zip
        city
        countryCode: country
        stateCode: state
        coords: gps {
          lng
          lat
        }
      }
    }
  }
`;
