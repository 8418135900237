// @flow

import op from 'object-path';

import validateField from './validateFieldHelper';

import type { FormErrors, FieldError } from './types';

export default function validateForm<D>(rules: any, values: D): null | FormErrors<D> {
  if (!rules) {
    return null;
  }
  const keys: Array<string> = Object.keys(rules);

  let errors: null | FormErrors<D> = null;
  let i = -1;

  while (++i < keys.length) {
    const key = keys[i];

    const err: null | FieldError | { string: FieldError } = validateField(
      undefined,
      rules[key],
      op.get(values, key),
      values
    );

    if (err) {
      if (!errors) {
        errors = {};
      }
      // $FlowFixMe
      errors[key] = err;
    }
  }

  return errors;
}
