// @flow

import * as React from 'react';

import Logger from '../Logger';

const PERSISTED_IDENTITY_KEY = 'identity';

// -------------------------------------------------------------------------------------------------

export type UserIdentity = {|
  permissions: Array<string>, // used in render routes - check /pages/_helpers/routes
  firstName: string,
  company: {
    created: string,
    paying: boolean,
    name: string,
    id: ID
  },
  lastName: string,
  email: string,
  type: string,
  id: ID,
  paymentInProgress: boolean
|};

export type IdentityContextType = {
  updateIdentity: (?UserIdentity) => void,
  refreshIdentity: () => void,
  identity: ?UserIdentity,
  loading: boolean
};

const defaultIndetityContextValue: IdentityContextType = {
  refreshIdentity: () => {},
  updateIdentity: () => {},
  identity: null,
  loading: false
};

const Context = React.createContext<IdentityContextType>(defaultIndetityContextValue);

// -------------------------------------------------------------------------------------------------

export type IdentityProviderProps = {|
  confirmIdentity?: () => Promise<*>,
  children: React.Node
|};

type IdentityProviderState = IdentityContextType;

export default class IdentityProvider extends React.PureComponent<
  IdentityProviderProps,
  IdentityProviderState
> {
  mounted: boolean = false;

  constructor(props: IdentityProviderProps): void {
    super(props);
    const storedIdentity = IdentityProvider.getStoredIdentity();
    this.state = {
      loading: !!storedIdentity && !!props.confirmIdentity,
      refreshIdentity: this.refreshIdentity.bind(this),
      updateIdentity: this.updateIdentity.bind(this),
      identity: storedIdentity
    };
  }

  render(): React.Node {
    return <Context.Provider value={this.state}>{this.props.children}</Context.Provider>;
  }

  updateIdentity = (identity: ?UserIdentity, loading?: boolean) => {
    const value = identity ? { ...identity } : null;
    if (value) {
      localStorage.setItem('identity', JSON.stringify(value));
    } else {
      localStorage.removeItem('identity');
    }
    this.mounted &&
      this.setState(state => ({
        loading: typeof loading === 'boolean' ? loading : state.loading,
        identity: value
      }));
  };

  componentDidMount(): void {
    this.mounted = true;
    this.state.identity && this.refreshIdentity();
  }

  refreshIdentity = () => {
    const confirmAction = this.props.confirmIdentity;

    if (confirmAction) {
      this.setState(
        state => ({ ...state, loading: true }),
        _ => {
          confirmAction().then(response => {
            const state = this.state;
            if (state.identity && response && response.ok && response.data) {
              this.updateIdentity(
                {
                  ...(state.identity || {}),
                  ...response.data,
                  permissions: /*response.data.company.paying
                    ? ['paying', 'registered']
                    : */ [
                    'registered'
                  ]
                },
                false
              );
              return;
            }
            this.updateIdentity(null, false);
          });
        }
      );
    }
  };

  componentWillUnmount(): void {
    this.mounted = false;
  }

  static getStoredIdentity(): null | UserIdentity {
    let identity: null | UserIdentity = null;
    let storedVal: string | null;
    try {
      storedVal = localStorage.getItem(PERSISTED_IDENTITY_KEY) || null;
      if (!storedVal) {
        return null;
      }
      identity = JSON.parse(storedVal || '');
      if (typeof identity !== 'object') {
        identity = null;
      }
    } catch (e) {
      Logger.error(e);
    }

    return identity;
  }
}

const Identity = Context.Consumer;

export { Context, Identity };
