// @flow

import cn from 'classnames';
import * as React from 'react';

import '../_assets/icomoon/style.css';

type IconProps = {|
  onClick?: () => void,
  className?: string,
  title?: string,
  color?: string,
  size?: number,
  name: string
|};

export default function Icon(props: IconProps): React.Node {
  const { name, className, size, title, onClick } = props;
  const classes = cn(`icon icon-${name}`, className);
  let style = { color: props.color || 'inherit' };

  if (typeof size === 'number' && size > 0) {
    style = { ...style, fontSize: size };
  }
  return (
    <span className={classes} style={style} onClick={onClick} title={title}>
      <span className="path1" />
      <span className="path2" />
      <span className="path3" />
    </span>
  );
}
