// @flow

import { getEndpoint } from './';
import { getActiveLocale } from '../../../I18n/I18n';

export default function unsubscribePayment(): Promise<*> {
  const host = getEndpoint();
  return fetch(host + '/payment/unsubscribe', {
    method: 'GET',
    cache: 'no-cache',
    credentials: 'include',
    'Accept-Language': getActiveLocale()
  })
    .then(({ status }) => {
      const ok = status === 200 || status === 204;
      return { ok, status };
    })
    .catch(error => ({
      ok: false,
      error
    }));
}
