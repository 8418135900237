// @flow

import idx from 'idx';
import op from 'object-path';
import * as React from 'react';
import { Form as BForm } from 'react-bootstrap';

import validateForm from './validateFormHelper';
import validateField from './validateFieldHelper';

import type { FormErrors, FormInterface, OnChangeEvent, ValidationRules } from './index';

// -------------------------------------------------------------------------------------------------

const FormContext = React.createContext<FormInterface<*>>({
  onChange: (e: OnChangeEvent<any>) => undefined,
  batchChange: () => undefined,
  validationRules: undefined,
  isChanged: false,
  disabled: false,
  paying: false,
  reset: () => {},
  errors: null,
  data: {}
});

const { Provider, Consumer } = FormContext;

// -------------------------------------------------------------------------------------------------

type FormState<T> = {
  errors: null | FormErrors<T>,
  isChanged: boolean,
  data: T
};

export type FormProps<T> = {|
  onChangeDecorator?: (OnChangeEvent<*>, T) => T,
  didChange?: (OnChangeEvent<*>, T) => void,
  validationRules?: ValidationRules<T>,
  defaultValue?: { [$Keys<T>]: * },
  onSubmit?: (T, { reset: () => void }) => void,
  enableSubmitInvalid?: boolean,
  children: React.Node,
  className?: string,
  disabled?: boolean,
  paying?: boolean
|};

// -------------------------------------------------------------------------------------------------

export type FieldConsumerProps = {
  component: React.ComponentType<*>,
  disabled?: boolean,
  paying?: boolean,
  name: string,
  id?: string
};

function FieldConsumer(props: FieldConsumerProps): React.Node {
  const { name, component, ...more } = props;
  const UIComponent = component;
  const propsDisabled = props.disabled;
  const propsPaying = props && props.paying ? props.paying : true;
  const { validationRules, data, batchChange, onChange, errors, disabled } = React.useContext(
    FormContext
  );

  const isInvalid = Boolean(idx(errors, _ => _[name].message));
  const error = idx(errors, _ => _[name].message);
  const val: * = name ? op.get(data, name, null) : data;

  if (name === 'expires') {
    return (
      <>
        <UIComponent
          {...more}
          validationRules={validationRules && validationRules[name]}
          error={errors && errors[name] && errors[name].message}
          isValid={!!(data && val && !isInvalid)}
          disabled={(!disabled && !propsPaying) || propsDisabled}
          batchChange={batchChange}
          isInvalid={isInvalid}
          onChange={onChange}
          values={data}
          name={name}
          value={val}
        />

        {error && <BForm.Control.Feedback type="invalid">{error}</BForm.Control.Feedback>}
      </>
    );
  }
  return (
    <>
      <UIComponent
        {...more}
        validationRules={validationRules && validationRules[name]}
        error={errors && errors[name] && errors[name].message}
        isValid={!!(data && val && !isInvalid)}
        disabled={disabled || propsDisabled}
        batchChange={batchChange}
        isInvalid={isInvalid}
        onChange={onChange}
        values={data}
        name={name}
        value={val}
      />

      {error && <BForm.Control.Feedback type="invalid">{error}</BForm.Control.Feedback>}
    </>
  );
}

// -------------------------------------------------------------------------------------------------

export default class Form<T> extends React.PureComponent<FormProps<T>, FormState<T>> {
  static +Field = FieldConsumer;
  static Consumer = Consumer;

  constructor(props: FormProps<T>): void {
    super(props);
    this.state = {
      // $FlowFixMe
      data: props.defaultValue || {},
      isChanged: false,
      errors: null
    };
  }

  // // --------------------------------------------------------------------------------------------

  render(): React.Node {
    const { validationRules, disabled, paying, defaultValue, ...rest } = this.props;
    delete rest.onChangeDecorator;
    delete rest.defaultValue;
    delete rest.didChange;
    delete rest.onSubmit;
    delete rest.children;
    const { data, errors, isChanged } = this.state;
    const dataCopy = data;
    const defValueCopy = defaultValue;

    if (JSON.stringify(dataCopy) === JSON.stringify(defValueCopy) && this.state.isChanged) {
      this.setState(state => ({ ...state, isChanged: false }));
    }

    if (data && data.logo && !data.logo.lastModified && !defaultValue.logo) {
      this.setState(state => ({ ...state, isChanged: false, data: data || {} }));
    } else if (
      data &&
      data.logo &&
      !data.logo.id &&
      !data.logo.htmlId &&
      defaultValue.logo &&
      data.logo.name === defaultValue.logo.name &&
      defaultValue.logo
    ) {
      this.setState(state => ({ ...state, isChanged: false, data: this.props.defaultValue || {} }));
    } else if (data && defaultValue && !data.logo) {
      delete dataCopy.logo;
      delete defValueCopy.logo;
      if (JSON.stringify(dataCopy) === JSON.stringify(defValueCopy) && this.state.isChanged) {
        this.setState(state => ({ ...state, isChanged: false }));
      }
    }

    return (
      <Provider
        value={{
          batchChange: this.batchChange,
          onChange: this.handleChange,
          disabled: !!disabled,
          paying: !!paying,
          reset: this.reset,
          validationRules,
          isChanged,
          errors,
          data
        }}
      >
        <form {...rest} onSubmit={this.handleSubmit} style={{ width: '100%' }}>
          {this.props.children}
          <button
            style={{
              visibility: 'hidden',
              overflow: 'hidden',
              position: 'fixed',
              opacity: '0',
              left: -9999,
              height: 0,
              width: 0
            }}
            type="submit"
          />
        </form>
      </Provider>
    );
  }

  // // --------------------------------------------------------------------------------------------

  setImage = () => {
    // $FlowFixMe
    this.setState(state => ({ ...state, isChanged: false, data: this.props.defaultValue || {} }));
  };

  // // --------------------------------------------------------------------------------------------

  reset = () => {
    // $FlowFixMe
    this.setState(state => ({ ...state, isChanged: false, data: this.props.defaultValue || {} }));
  };

  // // --------------------------------------------------------------------------------------------

  handleChange = (e: OnChangeEvent<*>): void => {
    // $FlowFixMe
    const target = (e && e.currentTarget) || e || {}; // Bootstrap inputControl api
    const event = target
      ? { name: target.name, value: target.value }
      : { name: e.name, value: e.value };

    if (!event.name) {
      return;
    }

    const { name, value } = event;

    this.setState(state => {
      const nextState: FormState<T> = Form.computeChanges(state, this.props, name, value);

      if (nextState.data.newPassword && nextState.data.passwordConfirmation) {
        if (nextState.data.newPassword === nextState.data.passwordConfirmation) {
          delete nextState.errors.passwordConfirmation;
        }
      }

      if (this.props.didChange) {
        this.props.didChange(e, nextState.data);
      }

      if (this.props.onChangeDecorator) {
        nextState.data = this.props.onChangeDecorator(e, nextState.data);
      }

      return nextState;
    });
  };

  // // --------------------------------------------------------------------------------------------

  batchChange = (data: { [string]: * }): void => {
    this.setState(prevState => {
      return Object.keys(data).reduce(
        (nextState: FormState<T>, name: string) => {
          return { ...nextState, ...Form.computeChanges(nextState, this.props, name, data[name]) };
        },
        { ...prevState }
      );
    });
  };

  // // --------------------------------------------------------------------------------------------

  static computeChanges(
    state: FormState<T>,
    props: FormProps<T>,
    name: string,
    value: *
  ): FormState<T> {
    const errorName = name.replace(/\./g, '.nested.');
    const rules = props.validationRules && op.get(props.validationRules, name, null);
    const err = rules && validateField(errorName, rules, value, state.data);

    const update = {
      errors: { ...state.errors },
      data: { ...state.data },
      isChanged: true
    };

    if (name.indexOf('.') > -1) {
      let k = '';
      const n = name.substr(0, name.lastIndexOf('.'));
      n.split('.').forEach(sub => {
        k += '.' + sub;
        op.set(update, 'data' + k, { ...op.get(update, 'data' + k, {}) });
        op.set(update, 'errors' + k, {
          ...op.get(update, 'errors' + k, {})
        });
      });
    }

    op.set(update, 'data.' + name, value);

    if (err) {
      op.set(update, 'errors.' + name, err);
    } else {
      op.del(update, 'errors.' + name);
    }

    return update;
  }

  // // --------------------------------------------------------------------------------------------

  handleSubmit = (e: SyntheticEvent<HTMLFormElement>): false => {
    e.preventDefault();
    e.stopPropagation();
    const { validationRules, onSubmit, enableSubmitInvalid } = this.props;
    if (validationRules) {
      const errors = validateForm(this.props.validationRules, this.state.data);
      if (errors) {
        this.setState({ errors }, () => {
          const list = document.getElementsByClassName('invalid-feedback');
          if (list && list[0]) {
            window &&
              // $FlowFixMe
              window.scrollTo(0, Math.max(0, (list[0].previousSibling || list[0]).offsetTop - 72));
          }
        });
        if (!enableSubmitInvalid) {
          return false;
        }
      }
    }
    onSubmit && onSubmit(this.state.data, { reset: this.reset });
    if (this.state.data.logo && this.state.data.logo.htmlId) {
      delete this.state.data.logo.htmlId;
    }
    return false;
  };
}

//  ------------------------------------------------------------------------------------------------

export { Consumer as FormConsumer };
export { FormContext };
