// @flow

// TODO: move to format helper
const stripHtml = text => {
  return text.replace(/(<([^>]+)>)/gi, '');
};

const rules: { [string]: (any, any, any) => boolean } = {
  email: (value: string): boolean =>
    !value ||
    /^(([^#&$^<>()[\]\\.,;:\s@"]+(\.[^#&$^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z+\-0-9]+\.)+[a-zA-Z+]{2,}))$/.test(
      value.trim()
    ),
  required: (
    value: any,
    param?: boolean | ((value: *, context: *) => boolean),
    context: *
  ): boolean => {
    const apply = typeof param === 'function' ? param(value, context) : param;
    return (
      !apply ||
      !(
        (typeof value === 'number' && isNaN(value)) ||
        typeof value === 'undefined' ||
        value === null ||
        value === '' ||
        (Array.isArray(value) && value.length === 0)
      )
    );
  },
  number: (value: string | number): boolean =>
    (typeof value === 'string' && /ˆ[0-9]+$/.test(value)) || !isNaN(value),
  maxLength: (value: string, param?: number): boolean =>
    !(
      typeof param === 'number' &&
      value &&
      typeof value === 'string' &&
      stripHtml(value).length > param
    ),
  minLength: (value: ?string, param?: number): boolean =>
    !(
      typeof param === 'number' &&
      value &&
      typeof value === 'string' &&
      stripHtml(value).length < (param || 0)
    ),
  iban: (value: string): boolean =>
    !value || /^(SK|CZ)\d{2}\s?([0-9a-zA-Z]{4}\s?){5}$/.test(value.trim()),
  max: (value: number, param?: number): boolean =>
    typeof value !== 'number' || value <= (param || 0),
  min: (value: number, param?: number): boolean =>
    typeof value !== 'number' || value >= (param || 0),
  phone: (value: string): boolean => {
    if (!value) {
      return true;
    }
    try {
      return /^(\+|0{2})[0-9]{8,}$/.test(value);
    } catch (e) {
      return false;
    }
  },
  maxCount: (value: null | Array<*> | { [number | string]: * }, max: number) => {
    return (
      typeof value === 'undefined' ||
      value === null ||
      (Array.isArray(value) && value.length <= max) ||
      // $FlowFixMe
      (value && typeof value === 'object' && Object.keys(value).length <= max)
    );
  },
  web: (value: string) => {
    if (!value || typeof value !== 'string') {
      return true;
    }
    /*
     Regex explanation:
     - remove www. as it would create a much more complicated regex if included
     - optional http/https://
     - mandatory one or more characters ended by a dot (repeatable)
     - mandatory two or more letters
     - optional groups of letter with / in the beginning
     - optional / at the end

     if any further error is found with this regex, please use a validator package
     */
    const find = value
      .replace('www.', '')
      .match(/(https?:\/\/)?([a-zA-Z0-9]+\.)+[a-z]{2,}(\/[a-zA-Z0-9]+)*\/?$/);
    return !!(find && find.index === 0);
  },
  vatNumber: (value: string): boolean => !value || /^[a-zA-Z]{2}[0-9]{8,10}$/.test(value.trim()),
  htmlRequired: (value: string): boolean => stripHtml(value).trim().length > 0,
  bankCode: (value: string): boolean => !value || /^[A-Z0-9]{4}$/.test(value.trim()),
  countryCode: (value: string): boolean => !value || /^[A-Z]{2}$/.test(value.trim()),
  businessId: (value: string): boolean => !value || /^[0-9]{8}$/.test(value.trim()),
  zip: (value: string): boolean => !value || /^[A-Z0-9- ]{4,9}$/.test(value.trim()),
  digitsOnly: (value: string | number): boolean =>
    !value || /^[0-9.,]+$/.test(typeof value === 'string' ? value.trim() : value.toString()),
  taxId: (value: string): boolean => !value || /^[0-9]{10}$/.test(value.trim()),
  hasUppercase: (value: string) => !value || /[A-Z]+/.test(value),
  hasDigit: (value: string) => !value || /[0-9]+/.test(value),
  notSame: (value: string) => {},
  laterThan: (value: Date, options: { name: 'string' }, values) => {
    return !value ||
      !(value instanceof Date) ||
      !options ||
      !options.name ||
      !values[options.name] ||
      !(values[options.name] instanceof Date)
      ? false
      : value >= values[options.name];
  },
  linkedIn: (value: string) =>
    !value ||
    value === '' ||
    value.startsWith('https://linkedin.com/') ||
    value.startsWith('https://www.linkedin.com/')
};

// -------------------------------------------------------------------------------------------------

export type ValidationName = $Keys<typeof rules>;
export type ValidationRules<D> = { [$Keys<D>]: { [ValidationName]: any } };

// -------------------------------------------------------------------------------------------------

export default function validate(
  type: ValidationName,
  value: string | number | boolean | null,
  options?: any,
  context?: any
): boolean {
  if (rules.hasOwnProperty(type) && typeof rules[type] === 'function') {
    return rules[type](value, options, context);
  }
  throw new Error('Invalid rule type: ' + type);
}
