// @flow

import I18n from '../../../../_helpers/I18n';
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BForm from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ModalLogo from '../../../../_assets/images/modal-logo.svg';

import confirmAction from './confirmAction';
import toastr from '../../../../_helpers/toastr';
import BBCode from '../../../../_components/BBCode';

// -------------------------------------------------------------------------------------------------

export type CreateContractModalTarget = {
  deal: { id: ID, summary: string, status: DealStatus },
  consumer: { name: string },
  id: ID
};

export type CreateContractModalContext = {|
  setTarget: (?CreateContractModalTarget) => void,
  target: ?CreateContractModalTarget
|};

const Context = React.createContext<CreateContractModalContext>({
  setTarget: function() {},
  target: null
});

// -------------------------------------------------------------------------------------------------

function CreateContractModal(props: { children: React.Node }): React.Node {
  const [target, setTarget] = React.useState<?CreateContractModalTarget>(null);
  const [dealStatus, setDealStatus] = React.useState<DealStatus>(null);
  const [error, setError] = React.useState(null);

  return (
    <Context.Provider value={{ target, setTarget }}>
      <>
        {props.children}

        <Modal show={!!target} onHide={close(setTarget, setError)}>
          <Modal.Header closeButton className="pb-0 border-0">
            <Modal.Title className="p-3">
              <Row noGutters className="align-items-center flex-nowrap">
                <img src={ModalLogo} width="20px" height="40px" alt="Modal logo" />
                <span className="ml-3 font-rubik-modal-title">
                  <I18n id="title" d="New Reply" />
                </span>
              </Row>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="border-0 pt-0 pb-0">
            <div className="p-3">
              {target && (
                <I18n
                  id="text"
                  d='You are creating a new Reply with [span className="text-success font-rubik-bold"]{companyName}[/span] for your Opportunity [span className="text-primary font-rubik-bold"]{dealSummary}[/span]'
                  companyName={target.consumer.name}
                  dealSummary={target.deal.summary}
                >
                  {({ value }) => <BBCode>{value}</BBCode>}
                </I18n>
              )}
            </div>
            <div className="p-3 custom-control custom-radio">
              <strong>
                <I18n id="optionsTitle" d="Opportunity options:" />
              </strong>
              <BForm.Check id="closeDealOption" type="radio" className="mt-3">
                <BForm.Check.Input
                  onChange={handleChange(setDealStatus, 'CLOSED', setError)}
                  checked={dealStatus === 'CLOSED'}
                  name="status"
                  type="radio"
                  className="custom-control-input"
                />
                <BForm.Check.Label className="custom-control-label">
                  <I18n id="optionClose" d="Close Opportunity when Reply is created" />
                </BForm.Check.Label>
                <BForm.Control.Feedback type="valid">Close</BForm.Control.Feedback>
              </BForm.Check>

              <BForm.Check id="keepDealOption" type="radio" className="mt-2">
                <BForm.Check.Input
                  onChange={handleChange(setDealStatus, 'OPEN', setError)}
                  checked={dealStatus === 'OPEN'}
                  name="status"
                  type="radio"
                  className="custom-control-input"
                />
                <BForm.Check.Label className="custom-control-label">
                  <I18n id="optionKeep" d="Keep Opportunity open I want more partners" />
                </BForm.Check.Label>
                <BForm.Control.Feedback type="valid">Close</BForm.Control.Feedback>
              </BForm.Check>
              {error && (
                <div className="mt-2 text-danger font-weight-light">
                  <I18n id="statusRequiredLabel" d="Choose one of the options" />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Row className="w-100 ml-0 mr-0 pb-3">
              <Col md={6} className="d-flex justify-content-center mb-2">
                <Button
                  variant="outline-primary"
                  onClick={close(setTarget, setError)}
                  className="w-100"
                >
                  <I18n id="cancelButton" d="Cancel" />
                </Button>
              </Col>
              <Col md={6} className="d-flex justify-content-center mb-2">
                <Button
                  variant="success"
                  onClick={confirm(setTarget, target, dealStatus, setError)}
                  className="w-100"
                >
                  <I18n id="confirmButton" d="Create" />
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </>
    </Context.Provider>
  );
}

CreateContractModal.Consumer = Context.Consumer;

export default CreateContractModal;

// -------------------------------------------------------------------------------------------------

function handleChange(
  setter: DealStatus => void,
  val: DealStatus,
  setError: () => void
): () => void {
  return function() {
    setError(false);
    window.document.getElementById('closeDealOption').classList.remove('is-invalid');
    document.getElementById('keepDealOption').classList.remove('is-invalid');
    setter && setter(val);
  };
}

function close(setTarget: (?CreateContractModalTarget) => void, setError: () => void): () => void {
  return function closeModal(): void {
    setError(false);
    setTarget(null);
  };
}

function confirm(
  setTarget: (?CreateContractModalTarget) => void,
  target: ?CreateContractModalTarget,
  dealStatus: DealStatus,
  setError: () => void
): () => void {
  return function confirmModal() {
    if (target && !dealStatus) {
      window.document.getElementById('closeDealOption').classList.add('is-invalid');
      document.getElementById('keepDealOption').classList.add('is-invalid');
      setError(true);
      return null;
    }
    confirmAction(target, dealStatus)
      .then(response => ({ response, error: undefined }))
      .catch(error => ({ error }))
      .then(({ error }) => {
        toastr({
          type: error ? 'error' : 'success',
          message: error ? (
            <I18n id="contractCreationError" d="Ooops! Reply was not updated. Try again later" />
          ) : (
            <I18n id="contractCreated" d="Done! Reply is now opened" />
          )
        });
        setTarget(null);
        return () => {};
      });
  };
}

export { Context as ModalContext };
