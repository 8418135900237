// @flow

import * as React from 'react';

import Layout from '../../_components/Layout';
import LoadingContent from '../_components/LoadingContent';
import Error404Page from '../Error/404Page';

export default function asyncComponent(importComponent: () => Promise<*>) {
  class AsyncRouteComponent extends React.PureComponent<
    RouteProps<*>,
    { component: null | React.ComponentType<RouteProps<*>>, loading: boolean }
  > {
    mounted = false;
    state = {
      component: null,
      loading: true
    };

    render() {
      const { component, loading } = this.state;
      if (loading) {
        return (
          <Layout verticalCenter>
            <LoadingContent />
          </Layout>
        );
      } else if (!component) {
        return <Error404Page {...this.props} />;
      }

      const C = component;
      return <C {...this.props} />;
    }

    componentDidMount(): void {
      this.mounted = true;
      importComponent().then(res => {
        this.mounted &&
          this.setState({
            component: res.default,
            loading: false
          });
      });
    }

    componentWillUnmount(): void {
      this.mounted = false;
    }
  }

  return AsyncRouteComponent;
}
