// @flow
import { fetchQuery as relayFetch } from 'relay-runtime';

import environment from './relayEnvironment';

const fetchQuery = function(query: string, variables?: { [string]: * }): Promise<*> {
  return relayFetch(environment, query, variables || {});
};

export default fetchQuery;
