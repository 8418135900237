// @flow
import idx from 'idx';
import { getActiveLocale } from '../../../I18n/I18n';

import me from './me';
import { getEndpoint } from './';

export type RegisterActionVariables = {|
  password: string,
  email: string
|};

export default function register(variables: RegisterActionVariables): Promise<*> {
  return fetch(getEndpoint() + '/auth/register', {
    method: 'POST',
    headers: {
      'x-requested-with': 'XMLHttpRequest',
      'content-type': 'application/json',
      'Accept-Language': getActiveLocale()
    },
    body: JSON.stringify(variables),
    credentials: 'include',
    cache: 'no-cache'
  })
    .then(response => response.json())
    .then(response => {
      if (response.ok) {
        return me().then(res => {
          return {
            data: idx(res, _ => _.data),
            status: 200,
            ok: true
          };
        });
      }
      return {
        ok: false,
        data: response,
        code: response.code || 'UNKNOWN',
        status: response.status
      };
    })
    .catch(error => ({
      ok: false,
      error
    }));
}
