/**
 * @flow
 * @relayHash 8658ef279bde6163fa378822f50f0164
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserCompanyPageProposeContractMutationVariables = {|
  dealId: string
|};
export type UserCompanyPageProposeContractMutationResponse = {|
  +contract: {|
    +id: string,
    +deal: {|
      +id: string,
      +summary: string,
    |},
  |}
|};
export type UserCompanyPageProposeContractMutation = {|
  variables: UserCompanyPageProposeContractMutationVariables,
  response: UserCompanyPageProposeContractMutationResponse,
|};
*/


/*
mutation UserCompanyPageProposeContractMutation(
  $dealId: ID!
) {
  contract: proposeContract(id: $dealId) {
    id
    deal {
      id
      summary
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "dealId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": "contract",
    "name": "proposeContract",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "dealId"
      }
    ],
    "concreteType": "Contract",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deal",
        "storageKey": null,
        "args": null,
        "concreteType": "Deal",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "summary",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UserCompanyPageProposeContractMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UserCompanyPageProposeContractMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UserCompanyPageProposeContractMutation",
    "id": null,
    "text": "mutation UserCompanyPageProposeContractMutation(\n  $dealId: ID!\n) {\n  contract: proposeContract(id: $dealId) {\n    id\n    deal {\n      id\n      summary\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5bba689601e0eef6198cfc3d14381a96';
module.exports = node;
