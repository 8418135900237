// @flow

import I18n from '../../_helpers/I18n';
import * as React from 'react';

import type { EnumChildren } from './';

function MessagingType(props: { children?: EnumChildren, v?: string }): React.Node {
  return (
    <I18n
      {...props}
      id="messagingTypes$"
      d={{
        WHATSAPP: 'WhatsApp',
        TELEGRAM: 'Telegram',
        SIGNAL: 'Signal',
        SKYPE: 'Skype',
        VIBER: 'Viber'
      }}
    />
  );
}

MessagingType.types = ['WHATSAPP', 'TELEGRAM', 'SIGNAL', 'SKYPE', 'VIBER'];

export default MessagingType;
