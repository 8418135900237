// @flow
import idx from 'idx';
import I18n, { Context } from '../../_helpers/I18n';
import * as React from 'react';
import { graphql } from 'react-relay';
//import Alert from 'react-bootstrap/Alert';
//import Link from '../../_components/Link';
import type { UserFormData } from './_components/UserForm';
import type { MessagingInputValue } from '../../_components/Form/_components/Messaging';
import type { UserDetailsInput } from './__generated__/ProfilePageSaveDetailsMutation.graphql';
import toastr from '../../_helpers/toastr';
import UserForm from './_components/UserForm';
import Layout from '../../_components/Layout';
import { routerHistory, getQueryParam } from '../../_helpers/router';
import LoadingContent from '../_components/LoadingContent';
import { Context as IdentityContext } from '../../_helpers/Identity';
import { Query, commitMutationPromise } from '../../_helpers/Api';

// -------------------------------------------------------------------------------------------------
export default function AccountPage(props: *): React.Node {
  const [mutationPending, setPending] = React.useState(false);
  const { setLocale } = React.useContext(Context);
  const { identity, refreshIdentity } = React.useContext(IdentityContext);

  React.useEffect(() => {
    refreshIdentity();
  }, []);

  function handleSubmit(setPending: boolean => void): (null | UserFormData) => void {
    return function(data: null | UserFormData): void {
      if (!data || typeof data !== 'object') {
        return;
      }
      const details = decorateMutationInput(data);
      setPending(true);
      commitMutationPromise({
        mutation,
        variables: { details }
      })
        .then(response => ({ response, error: undefined }))
        .catch(error => ({ error }))
        .then(({ error }) => {
          setPending(false);
          if (!error) {
            setLocale(data.lang.toLowerCase());
          } else {
            toastr({
              type: 'error',
              message: <I18n id="profileError" d="Ooops. Your profile details was not saved" />
            });
          }
        })
        .finally(() => {
          const timer = setTimeout(() => {
            toastr({
              type: 'success',
              message: <I18n id="profileSaved" d="Profile has been saved successfully" />
            });
          }, 400);
          if (identity.type !== 'INTERNAL' && identity.company.name === null) {
            routerHistory.push('/account/company?next-step=/deal/new');
          } else if (identity.type === 'INTERNAL' || identity.type === 'INTERNAL_ADMIN') {
            routerHistory.push('/deal');
          }
          return () => clearTimeout(timer);
        });
    };
  }
  function devNull(): void {}
  // -------------------------------------------------------------------------------------------------
  function decorateMeToUserForm(data: { [string]: * } | null): UserFormData {
    let messaging = null;
    if (data && data.messaging) {
      messaging = data.messaging.reduce(
        (map: Array<MessagingInputValue>, it: MessagingInputValue, key: number) => {
          map[key] = it;
          return map;
        },
        {}
      );
    }
    // $FlowFixMe
    return {
      ...(data || {}),
      messaging
    };
  }
  return (
    <Layout.Common narrow>
      <Query name="me">
        {({ loading, error, data }) => {
          if (loading) {
            return <LoadingContent />;
          }
          /*const free = !idx(data, _ => _.me.company.paying);
          const created = idx(data, _ => _.me.company.created);
          const expirationDate = free
            ? new Date(new Date(created).setMonth(new Date(created).getMonth() + 6))
            : null;
          const today = new Date().getTime();*/
          const nextStep = getQueryParam('next-step');

          if (data.me.firstName && nextStep) {
            routerHistory.push(nextStep);
          }

          return (
            <>
              {/*expirationDate && today > expirationDate.getTime() && (
                <Alert variant="danger" className="d-flex align-items-center rounded-pill">
                  <span>
                    <I18n id="accountExpired" d="Validity of your account has expired" />
                  </span>

                  <Link to="/subscribe" className="ml-auto">
                    <I18n id="subscription" d="Subscribe now" />
                  </Link>
                </Alert>
              )*/}
              <h1 className="mb-4 mt-4 font-rubik-title">
                <I18n id="title" d="My account" />
              </h1>
              <UserForm
                onSubmit={mutationPending ? devNull : handleSubmit(setPending)}
                defaultValue={decorateMeToUserForm(idx(data, _ => _.me) || null)}
                disabled={mutationPending}
              />
            </>
          );
        }}
      </Query>
    </Layout.Common>
  );
}
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
const mutation = graphql`
  mutation ProfilePageSaveDetailsMutation($details: UserDetailsInput!) {
    myDetails(details: $details) {
      id
      salutation
      firstName
      lastName
      phone
      branch
      workposition
      email
      lang
      contactEmail
      messaging {
        type
        value
      }
      linkedin
      company {
        id
        populated
      }
    }
  }
`;
// -------------------------------------------------------------------------------------------------
function decorateMutationInput(data: { [string]: any }): UserDetailsInput {
  const reduceMessaging = (list, index) => {
    // TODO: refactor op.set() to be able set array index, at this moment, op.set in form onchage action, change array to object if op.set(data,"param.0.nested", ...) is called
    if (data && data.messaging && data.messaging[index].value && data.messaging[index].type) {
      list.push(data.messaging[index]);
    }
    return list;
  };
  return {
    messaging: data.messaging ? Object.keys(data.messaging).reduce(reduceMessaging, []) : [],
    contactEmail: data.contactEmail || null,
    salutation: data.salutation || null,
    firstName: data.firstName || null,
    lastName: data.lastName || null,
    linkedin: data.linkedin || null,
    password: data.password || null,
    phone: data.phone || null,
    branch: data.branch || null,
    workposition: data.workposition || null,
    email: data.email || '',
    lang: data.lang || 'EN'
  };
}
