// @flow

import I18n from '../../_helpers/I18n';
import * as React from 'react';

function DealType(props: *): React.Node {
  return <I18n {...props} id="dealType$" d={{ OFFER: 'Offer', REQUEST: 'Request' }} />;
}

DealType.types = ['OFFER', 'REQUEST'];

export default DealType;
