// @flow

import type { QueryRendererProps } from 'react-relay';

import * as React from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import type { articleQueryResponse } from './__generated__/articleQuery.graphql';

import env from '../relayEnvironment';

export type articlesProps = QueryRendererProps<articleQueryResponse>;

export default function articles(props: articlesProps): React.Node {
  return (
    <QueryRenderer
      variables={props.variables || {}}
      render={props.children}
      environment={env}
      query={graphql`
        query articleQuery($slug: String!) {
          article(slug: $slug) {
            id
            slug
            title
            content
            image {
              url
            }
            keywords
            category
            readingTime
            author {
              name
              image {
                url
              }
            }
            comments {
              user {
                firstName
                lastName
                company {
                  logo {
                    id
                    url
                  }
                }
              }
              comment
              created
              modified
            }
            likesCount
            hasLiked
            created
            modified
          }
        }
      `}
    />
  );
}
