// @flow

import idx from 'idx';
import * as React from 'react';

export type TagProps = {|
  // eslint-disable-next-line react/no-unused-prop-types
  toReact: string => React.Node,
  attrs: { [string]: string },
  // eslint-disable-next-line react/no-unused-prop-types
  children: ?React.Node,
  // eslint-disable-next-line react/no-unused-prop-types
  tagName: string
|};

// -------------------------------------------------------------------------------------------------

function TextTag(props: TagProps): React.Node {
  return props.children || null;
}

function SupTag(props: TagProps): React.Node {
  return <sup>{props.children}</sup>;
}

function SpanTag(props: TagProps): React.Node {
  return <span className={idx(props, _ => _.attrs.class) || undefined}>{props.children}</span>;
}

function SubTag(props: TagProps): React.Node {
  return <sup>{props.children}</sup>;
}

function BTag(props: TagProps): React.Node {
  return <strong>{props.children}</strong>;
}

function ITag(props: TagProps): React.Node {
  return <span style={{ fontStyle: 'italic' }}>{props.children}</span>;
}

function UTag(props: TagProps): React.Node {
  return <span style={{ textDecoration: 'underline' }}>{props.children}</span>;
}

function STag(props: TagProps): React.Node {
  return <span style={{ textDecoration: 'line-through' }}>{props.children}</span>;
}

function PTag(props: TagProps): React.Node {
  const { children, toReact, attrs } = props;

  return (
    <p className={attrs.class || undefined}>
      {typeof children === 'string' ? toReact(children.trim()) : children}
    </p>
  );
}

function BrTag(props: TagProps): React.Node {
  return <br />;
}

function ImageTag(props: TagProps): React.Node {
  const src = idx(props, _ => _.attrs.src);
  return <img src={src} alt={props.children || ''} />;
}

function LinkTag(props: TagProps): React.Node {
  const { href, target } = props.attrs || {};
  return (
    <a href={href} target={target}>
      {props.children}
    </a>
  );
}

function HeadingTag(props: TagProps): React.Node {
  const Tag = props.tagName;
  return <Tag>{props.children}</Tag>;
}

function LiTag(props: TagProps): React.Node {
  const { children, toReact } = props;
  // $FlowFixMe - children could be boolean so...
  return <li>{toReact((children || '').trim())}</li>;
}

function UlTag(props: TagProps): React.Node {
  const { children, toReact } = props;

  if (typeof children === 'string' && children.indexOf('[li') > -1) {
    return <ul>{toReact(children)}</ul>;
  }

  const child =
    typeof children === 'string' &&
    children.length > 0 &&
    children
      .trim()
      .split('\n')
      .map((it, key) => <li key={key}>{toReact(it.trim())}</li>);

  return <ul>{child}</ul>;
}

function HeadlineTag(props: TagProps): React.Node {
  const { children, tagName, attrs } = props;
  const Tag = tagName;

  return <Tag className={attrs.class || ''}>{children}</Tag>;
}

function OlTag(props: TagProps): React.Node {
  const { children, toReact } = props;
  const list = typeof children === 'string' && children.length ? children.trim().split('\n') : [];

  return (
    <ol>
      {list.map((it, key) => (
        <li key={key}>{toReact(it.trim())}</li>
      ))}
    </ol>
  );
}

// -------------------------------------------------------------------------------------------------

export {
  HeadlineTag,
  ImageTag,
  SpanTag,
  TextTag,
  LinkTag,
  SupTag,
  SubTag,
  BrTag,
  OlTag,
  UlTag,
  LiTag,
  BTag,
  PTag,
  ITag,
  UTag,
  STag,
  HeadingTag
};
