// @flow

import toastr from 'toastr';
import * as React from 'react';
import ReactDOMServer from 'react-dom/server';

import 'toastr/build/toastr.min.css';

const defaultOptions = {
  positionClass: 'toast-top-center',
  progressBar: true,
  timeOut: 5000
};

toastr.options.timeOut = 5000;
toastr.options.progressBar = true;
toastr.options.positionClass = 'toast-top-center';

export default function toastrHelper(msg: {
  options?: { [string]: number | string | boolean },
  type: 'warning' | 'success' | 'error' | 'info',
  message: React.Node
}): void {
  const event = toastr[msg.type || 'success'];
  const str =
    typeof msg.message !== 'string' ? ReactDOMServer.renderToString(msg.message) : msg.message;
  if (msg.options) {
    set({ ...defaultOptions, ...msg.options });
    event(str);
    set(defaultOptions);
  } else {
    event(str);
  }
}

function set(o) {
  Object.keys(o).forEach(key => {
    toastr.options[key] = o[key];
  });
}
