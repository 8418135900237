// @flow

import * as React from 'react';
import { graphql } from 'react-relay';

import { commitMutationPromise } from '../../../_helpers/Api';

export default function SeenContractsNotifier(props: {
  items: Array<{ node: { id: ID, seen: boolean } }>,
  dealId: string
}): React.Node {
  const timer = React.createRef();

  React.useEffect(() => {
    const idList = props.items.reduce((list, it) => {
      if (!it.node.seen) {
        list.push(it.node.id);
      }
      return list;
    }, []);

    if (idList.length > 0) {
      timer.current = setTimeout(_ => {
        seeNewContracts(props.dealId, idList);
      }, 2000);
    }

    return () => {
      timer.current && clearTimeout(timer.current);
    };
  });

  return null;
}

// -------------------------------------------------------------------------------------------------

function seeNewContracts(dealId: string, list: Array<ID>): void {
  commitMutationPromise({
    mutation: proposeContractMutation,
    variables: { dealId: parseInt(dealId, 10), list }
  })
    .then((response: { contract: { deal: { summary: string } } }) => ({
      response,
      error: undefined
    }))
    .catch(error => ({ error, response: undefined }));
}

// -------------------------------------------------------------------------------------------------

const proposeContractMutation = graphql`
  mutation SeenContractsNotifierMutation($dealId: ID!, $list: [ID!]!) {
    deal: contractHasBeenSeen(id: $dealId, list: $list) {
      id
      contracts {
        edges {
          cursor
          node {
            id
            seen
          }
        }
      }
    }
  }
`;
