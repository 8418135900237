// @flow

import * as React from 'react';
import Link from 'react-router-dom/Link';
import BForm from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Redirect from 'react-router-dom/Redirect';
import I18n, { Context as I18nContext } from '../../_helpers/I18n';

import type { RegisterActionVariables } from '../../_helpers/Api/Auth';

import toastr from '../../_helpers/toastr';
import { AuthApi } from '../../_helpers/Api';
import Layout from '../../_components/Layout';
import { Context as IdentityContext } from '../../_helpers/Identity';
import Form, { TextInput, PasswordInput } from '../../_components/Form';
import rememberLocaleMutation from '../../_helpers/Api/rememberLocaleMutation';
import getCaptchaToken from '../../_helpers/getCaptchaToken';

const Identity = IdentityContext.Consumer;

// -------------------------------------------------------------------------------------------------

const signUpValidation = {
  email: { required: true, email: true },
  password: { required: true, minLength: 8, hasDigit: true }
};

export default function SignUpPage(props: *): React.Node {
  const { locale } = React.useContext(I18nContext);

  return (
    <AuthApi action="register">
      {(action, state) => (
        <Layout.Auth>
          <h1 className="w-100 font-rubik-title mt-2 mb-4">
            <I18n id="title" d="Create Account" />
          </h1>
          <Identity>
            {({ identity, updateIdentity }) =>
              identity ? (
                <Redirect to={`/account/profile`} />
              ) : (
                <React.Fragment>
                  <Form
                    className="mt-4 mb-4 login-form"
                    onSubmit={handleSubmit(action, updateIdentity, locale)}
                    validationRules={signUpValidation}
                    disabled={state.loading}
                  >
                    <BForm.Group>
                      <I18n id="emailPlaceholder" d="Email">
                        {placeholder => (
                          <Form.Field
                            placeholder={placeholder.value}
                            autoComplete="new-email"
                            component={TextInput}
                            name="email"
                            type="email"
                          />
                        )}
                      </I18n>
                    </BForm.Group>
                    <BForm.Group>
                      <I18n id="passwordPlaceholder" d="Password">
                        {placeholder => (
                          // $FlowFixMe
                          <Form.Field
                            placeholder={placeholder.value}
                            autoComplete="new-password"
                            component={PasswordInput}
                            name="password"
                          />
                        )}
                      </I18n>
                    </BForm.Group>
                    <BForm.Group>
                      <Button variant="primary" block type="submit" disabled={state.loading}>
                        <I18n id="submit" d="Register" />
                      </Button>
                    </BForm.Group>
                  </Form>
                  <div className="sign-up">
                    <I18n
                      id="agreement"
                      d="By registering, you agree to the General Terms and Conditions and the processing of personal data."
                    />
                  </div>
                </React.Fragment>
              )
            }
          </Identity>
          <p className="w-100 mt-3 sign-up text-right">
            <I18n id="alreadyText" d="Don't have an account yet?" />{' '}
            <Link to="/auth/login">
              <I18n id="alreadyLink" d="Log In!" />
            </Link>
          </p>
        </Layout.Auth>
      )}
    </AuthApi>
  );
}

// -------------------------------------------------------------------------------------------------

const handleSubmit = (
  action: ?(RegisterActionVariables) => Promise<*>,
  updateIdentity: (*) => void,
  locale: string
) => (data: *) => {
  if (!action) {
    return;
  }
  getCaptchaToken('register').then(captcha => {
    action({ ...data, captcha }).then(response => {
      const user = response.data;

      if (user && user.id && user.company && user.company.id && user.email) {
        // TODO: create general identity decorator
        updateIdentity({
          firstName: user.firstName || '',
          lastName: user.lastName || '',
          company: user.company,
          permissions: /*user.company.paying ? ['paying', 'registered'] :*/ ['registered'],
          email: user.email,
          id: user.id
        });
        rememberLocaleMutation(locale);
        return;
      }

      const code =
        typeof response.code === 'string' && response.code.startsWith('CAPTCHA_')
          ? 'INVALID_CAPTCHA'
          : (response && response.code) || null;

      toastr({
        type: 'error',
        message: (
          <I18n
            id="err$"
            d={{
              UNKNOWN_ERROR: 'Ooops! Something went wrong.',
              INVALID_PASSWORD: 'Ooops! Invalid password',
              PASSWORD_REQUIRED: 'Password is required!',
              EMAIL_IN_USE: 'Email is already used!',
              INVALID_EMAIL: 'Oops! Invalid email!',
              EMAIL_REQUIRED: 'Email is required!',
              INVALID_CAPTCHA: 'Ooops something went wrong with antispam check, please try again.'
            }}
          >
            {({ d }) => {
              return (code && d[code]) || d.UNKNOWN_ERROR;
            }}
          </I18n>
        )
      });
    });
  });
};
