// @flow

import * as React from 'react';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import Error404Page from '../Error/404Page';
import asyncComponent from './AsyncRouteComponent';

const AsyncCooperation = asyncComponent(() => import('./CooperationPage'));

export default function ContentPage(props: { match: { url: string } }): React.Node {
  return (
    <Switch>
      <Route path={props.match.url + '/cooperation/:type'} component={AsyncCooperation} />
      <Route component={Error404Page} />
    </Switch>
  );
}
