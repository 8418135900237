/**
 * @flow
 * @relayHash 5211831f12fec272cfa5ed077cf9ac6f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DealCooperation = "RND_COOPERATION" | "SHARE_ASSETS" | "SHARE_PROCUREMENT" | "SPARE_CAPACITY" | "%future added value";
export type DealStatus = "CLOSED" | "OPEN" | "%future added value";
export type DealType = "OFFER" | "REQUEST" | "%future added value";
export type SuggestedModalQueryVariables = {|
  dealId: string
|};
export type SuggestedModalQueryResponse = {|
  +cooperationDeals: ?$ReadOnlyArray<?{|
    +id: string,
    +summary: string,
    +type: DealType,
    +cooperation: DealCooperation,
    +status: DealStatus,
    +category: {|
      +id: string,
      +name: string,
    |},
    +keywords: $ReadOnlyArray<any>,
    +created: any,
    +description: string,
  |}>
|};
export type SuggestedModalQuery = {|
  variables: SuggestedModalQueryVariables,
  response: SuggestedModalQueryResponse,
|};
*/


/*
query SuggestedModalQuery(
  $dealId: ID!
) {
  cooperationDeals(dealId: $dealId) {
    id
    summary
    type
    cooperation
    status
    category {
      id
      name
    }
    keywords
    created
    description
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "dealId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "cooperationDeals",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "dealId",
        "variableName": "dealId"
      }
    ],
    "concreteType": "Deal",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "summary",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "type",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "cooperation",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "category",
        "storageKey": null,
        "args": null,
        "concreteType": "NACECategory",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "keywords",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "created",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "description",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SuggestedModalQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SuggestedModalQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SuggestedModalQuery",
    "id": null,
    "text": "query SuggestedModalQuery(\n  $dealId: ID!\n) {\n  cooperationDeals(dealId: $dealId) {\n    id\n    summary\n    type\n    cooperation\n    status\n    category {\n      id\n      name\n    }\n    keywords\n    created\n    description\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '105ec68868f7a650207e9f7c5a03a3b2';
module.exports = node;
