/**
 * @flow
 * @relayHash d2e36d4406c0a0f91440acf7822d9d5e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SeenContractsNotifierMutationVariables = {|
  dealId: string,
  list: $ReadOnlyArray<string>,
|};
export type SeenContractsNotifierMutationResponse = {|
  +deal: {|
    +id: string,
    +contracts: {|
      +edges: $ReadOnlyArray<{|
        +cursor: number,
        +node: {|
          +id: string,
          +seen: boolean,
        |},
      |}>
    |},
  |}
|};
export type SeenContractsNotifierMutation = {|
  variables: SeenContractsNotifierMutationVariables,
  response: SeenContractsNotifierMutationResponse,
|};
*/

/*
mutation SeenContractsNotifierMutation(
  $dealId: ID!
  $list: [ID!]!
) {
  deal: contractHasBeenSeen(id: $dealId, list: $list) {
    id
    contracts {
      edges {
        cursor
        node {
          id
          seen
        }
      }
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'dealId',
        type: 'ID!',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'list',
        type: '[ID!]!',
        defaultValue: null
      }
    ],
    v1 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    },
    v2 = [
      {
        kind: 'LinkedField',
        alias: 'deal',
        name: 'contractHasBeenSeen',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'dealId'
          },
          {
            kind: 'Variable',
            name: 'list',
            variableName: 'list'
          }
        ],
        concreteType: 'Deal',
        plural: false,
        selections: [
          (v1 /*: any*/),
          {
            kind: 'LinkedField',
            alias: null,
            name: 'contracts',
            storageKey: null,
            args: null,
            concreteType: 'ContractsConnection',
            plural: false,
            selections: [
              {
                kind: 'LinkedField',
                alias: null,
                name: 'edges',
                storageKey: null,
                args: null,
                concreteType: 'ContractEdge',
                plural: true,
                selections: [
                  {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'cursor',
                    args: null,
                    storageKey: null
                  },
                  {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'node',
                    storageKey: null,
                    args: null,
                    concreteType: 'Contract',
                    plural: false,
                    selections: [
                      (v1 /*: any*/),
                      {
                        kind: 'ScalarField',
                        alias: null,
                        name: 'seen',
                        args: null,
                        storageKey: null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'SeenContractsNotifierMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v2 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'SeenContractsNotifierMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v2 /*: any*/)
    },
    params: {
      operationKind: 'mutation',
      name: 'SeenContractsNotifierMutation',
      id: null,
      text:
        'mutation SeenContractsNotifierMutation(\n  $dealId: ID!\n  $list: [ID!]!\n) {\n  deal: contractHasBeenSeen(id: $dealId, list: $list) {\n    id\n    contracts {\n      edges {\n        cursor\n        node {\n          id\n          seen\n        }\n      }\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'd985851bedb49e916291bf346298f3ba';
module.exports = node;
