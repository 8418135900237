// @flow

import I18n, { Context as I18nContext } from '../../_helpers/I18n';
import * as React from 'react';
import cn from 'classnames';
import Col from 'react-bootstrap/Col';

import Enum from '../../_components/Enum';
import { ToggleInput } from '../../_components/Form';

// -------------------------------------------------------------------------------------------------

export default function CooperationPicker(props: *): React.Node {
  const { style, ...rest } = props;
  const { icons, label, multiple } = props;
  const { locale } = React.useContext(I18nContext);

  const getIcon = (key: any) => {
    if (icons) {
      if (key === 'SPARE_CAPACITY') {
        return (
          <img src={icons[0]} className="mr-2 toggle-icon" width="23px" height="24px" alt="" />
        );
      } else if (key === 'SHARE_PROCUREMENT') {
        return (
          <img src={icons[1]} className="mr-2 toggle-icon" width="19px" height="17px" alt="" />
        );
      } else if (key === 'RND_COOPERATION') {
        return (
          <img src={icons[2]} className="mr-2 toggle-icon" width="13px" height="26px" alt="" />
        );
      }
      return <img src={icons[3]} className="mr-2 toggle-icon" width="22px" height="22px" alt="" />;
    }
  };

  return (
    <Enum.Cooperation>
      {({ d }) => {
        const options = d;
        return (
          <>
            <ToggleInput
              {...rest}
              options={Object.keys(options).map(key => ({
                label: options[key],
                value: key,
                icon: getIcon(key)
              }))}
              style={{
                boxShadow: 'none',
                width: '100%',
                ...(style || {})
              }}
              label={
                label ? (
                  <Col sm={12} md={2} className="d-flex align-items-center pl-0 filter-label">
                    <label
                      className={cn('pl-0 font-rubik-small font-rubik-bold', {
                        'text-break': locale === 'de'
                      })}
                    >
                      <I18n id="label" d="Partnership Type" />
                    </label>
                  </Col>
                ) : (
                  ''
                )
              }
              className={cn(
                'cooperation-picker',
                { multiple: multiple && locale !== 'de' },
                { single: !multiple && locale !== 'de' },
                { german: locale === 'de' }
              )}
              multiple={multiple}
            />
          </>
        );
      }}
    </Enum.Cooperation>
  );
}
