// @flow

import idx from 'idx';
import cn from 'classnames';
import * as React from 'react';
import I18n from '../../../_helpers/I18n';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import queryString from 'query-string';
import BForm from 'react-bootstrap/Form';
import Collapse from 'react-bootstrap/Collapse';

import type { OnChangeEvent } from '../../../_components/Form';

import GoogleAutocompleteInput from '../../../_components/GoogleAutocompleteInput';
import { SliderInput, MapPlacePicker } from '../../../_components/Form';

// -------------------------------------------------------------------------------------------------

export type LocationFilterValue = {
  radius: null | number,
  place: null | Place,
  ignore: boolean,
  open: boolean
};

export type LocationFilterProps = {
  onChange: (OnChangeEvent<LocationFilterValue>) => void,
  value: null | LocationFilterValue,
  step?: number,
  name: string,
  max: number
};

// -------------------------------------------------------------------------------------------------

export default function LocationFilterInput(props: LocationFilterProps): React.Node {
  const { value, onChange, name, max, step } = props;
  const ignored: boolean = !!value && value.ignore;
  const [mobile, setMobile] =
    window.innerWidth > 768 ? React.useState(false) : React.useState(true);

  const mobileSize = () => {
    if (window.innerWidth < 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  window.addEventListener('resize', mobileSize);

  const res = window.location.search ? queryString.parse(window.location.search) : null;
  if (
    res &&
    res.radius &&
    parseFloat(res.radius) !== 50 &&
    value.radius === 50 &&
    value.ignore === false
  ) {
    value.radius = parseFloat(res.radius);
  }

  const handleChange = React.useCallback(
    (e: OnChangeEvent<*>) => {
      if (ignored && e.name === 'radius') {
        // $FlowFixMe
        onChange({ name, value: { ...value, ignore: false, radius: e.value } });
      } else {
        // $FlowFixMe
        onChange({ name: name + '.' + e.name, value: e.value });
      }
    },
    [name, onChange, value, ignored]
  );

  const ignoreLocation = !!(value && value.ignore);

  function RadiusKnob(props: *): React.Node {
    const className = cn(
      'rc-slider-handle',
      { 'bg-secondary': props.disabled },
      { mobile: mobile }
    );
    return (
      <span className={className} style={{ left: props.offset + '%' }}>
        {!mobile && `${props.value > 1 ? props.value - 1 : 1}km`}
      </span>
    );
  }

  return (
    !ignoreLocation && (
      <Collapse in={value && value.open} className="mb-4">
        <div className="filter-panel">
          <BForm.Group controlId="locationFieldset.location">
            <Row
              xs={12}
              md={12}
              className="ml-0 mr-0 my-4 w-100 align-items-center toggl with-label flex-nowrap filter-label"
            >
              <BForm.Label className="pl-0 font-rubik-small font-rubik-bold mr-4 filter-search">
                <I18n id="locationLabel" d="Location" />
              </BForm.Label>
              <I18n id="phonePlaceholder" d="Search location">
                {placeholder => (
                  <GoogleAutocompleteInput
                    placeholder={placeholder.value}
                    value={value && value.place}
                    disabled={ignoreLocation}
                    onChange={handleChange}
                    name="place"
                  />
                )}
              </I18n>
            </Row>
          </BForm.Group>

          <BForm.Group controlId="locationFieldset.radius">
            <Col xs={12} md={12} className="my-4 p-0 align-items-center flex-nowrap">
              <div className="d-flex flex-row justify-content-between radius-label">
                <BForm.Label className="pl-0 my-4 font-rubik-small font-rubik-bold">
                  <I18n id="radiusLabel" d="Radius" />
                </BForm.Label>
                {mobile && (
                  <div className="d-flex align-items-center text-primary font-rubik">
                    {value.radius % 10 === 1 && value.radius !== 1
                      ? value.radius - 1
                      : value.radius}
                    km
                  </div>
                )}
              </div>
              <SliderInput
                value={(value && value.radius) || 1}
                // $FlowFixMe
                onChange={handleChange}
                knob={RadiusKnob}
                step={step || 1}
                name="radius"
                max={max}
                min={1}
                hide={mobile}
              />
            </Col>
          </BForm.Group>

          <BForm.Group controlId="locationFieldset.map" className="py-4">
            <MapPlacePicker
              radius={(ignoreLocation ? -1 : idx(value, _ => _.radius)) || -1}
              value={value && value.place}
              onChange={handleChange}
              name="place"
            />
          </BForm.Group>
        </div>
      </Collapse>
    )
  );
}
