// @flow

import * as React from 'react';
import classNames from 'classnames';

import type { OnChangeEvent } from '../types';

import Button from 'react-bootstrap/Button';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

// -------------------------------------------------------------------------------------------------

export type ToggleInputProps = {
  onChange: (e: OnChangeEvent<string | string[]>) => void, // eslint-disable-line
  options: Array<{ value: *, label: React.Node }>,
  style?: { [string]: string | number },
  isInvalid?: boolean,
  className?: string,
  disabled?: boolean,
  multiple?: boolean,
  variant?: string,
  name: string, // eslint-disable-line
  label?: React.Node,
  value: *,
  size?: 'sm' | 'lg'
};

// -------------------------------------------------------------------------------------------------

export default function ToggleInput(props: ToggleInputProps): React.Node {
  const {
    className,
    isInvalid,
    disabled,
    multiple,
    variant,
    options,
    style,
    label,
    value,
    size
  } = props;
  const classes = classNames(
    'toggl',
    { 'is-invalid': isInvalid, 'with-label': !!label },
    className
  );

  return (
    <div
      className={classes}
      style={{
        backgroundImage: 'none !important',
        borderBottom: 'none',
        paddingRight: 0,
        paddingLeft: 0,
        height: 'auto',
        ...(style || {})
      }}
    >
      {label}
      <ToggleButtonGroup type="checkbox" disabled={disabled} className="btn-block flex-wrap">
        {options.map((it, key) => {
          const active = multiple ? value && value.indexOf(it.value) > -1 : value === it.value;
          return (
            <Button
              onClick={!disabled ? handleOnChange(props, it.value) : undefined} // eslint-disable-line
              variant={
                active ? variant || 'primary' : variant ? 'outline-' + variant : 'outline-primary'
              }
              className="input d-flex"
              disabled={disabled}
              data-index={key}
              type="button"
              size={size}
              // TODO: key
              key={key}
            >
              {it.icon}
              {it.label}
            </Button>
          );
        })}
      </ToggleButtonGroup>
    </div>
  );
}

// -------------------------------------------------------------------------------------------------

function handleOnChange(
  props: ToggleInputProps,
  newValue: *
): (e: SyntheticEvent<HTMLButtonElement>) => void {
  return function(e: SyntheticEvent<HTMLButtonElement>) {
    e.preventDefault();
    const { value, multiple, onChange, name } = props;
    let val = value === newValue ? null : newValue;

    if (multiple) {
      val = [...(value || [])];
      const index = val.indexOf(newValue);
      if (index === -1) {
        val.push(newValue);
      } else {
        val.splice(index, 1);
      }
    }

    onChange({ name, value: val });
  };
}
