// @flow

import * as React from 'react';
import BForm from 'react-bootstrap/Form';

import type { OnChangeEvent } from '../types';

export default function CheckBox(props: {
  onChange: (OnChangeEvent<boolean>) => void,
  validationRules?: *,
  value: boolean,
  name: string
}): React.Node {
  // eslint-disable-next-line
  const { onChange, validationRules, value, name, ...passing } = props;
  // $FlowFixMe
  delete passing.batchChange;
  return (
    <BForm.Check
      {...passing}
      checked={value}
      name={name}
      value="on"
      onChange={handleChange(name, onChange)}
      custom
    />
  );
}

function handleChange(
  name: string,
  callback: (OnChangeEvent<boolean>) => void
): (e: SyntheticEvent<*>) => void {
  return function onChceckBoxChange(e: SyntheticEvent<*>) {
    callback({ name, value: e.currentTarget.checked });
  };
}
