/**
 * @flow
 * @relayHash 44930186d18814fb648f38a1084dfe0f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractStatus = "CLOSED" | "COMPLETED" | "FAILED" | "OPEN" | "PROPOSED" | "%future added value";
export type confirmActionSetContractStatusMutationVariables = {|
  contractId: string,
  status: ContractStatus,
|};
export type confirmActionSetContractStatusMutationResponse = {|
  +updateContract: {|
    +id: string,
    +status: ?ContractStatus,
  |}
|};
export type confirmActionSetContractStatusMutation = {|
  variables: confirmActionSetContractStatusMutationVariables,
  response: confirmActionSetContractStatusMutationResponse,
|};
*/

/*
mutation confirmActionSetContractStatusMutation(
  $contractId: ID!
  $status: ContractStatus!
) {
  updateContract(id: $contractId, status: $status) {
    id
    status
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'contractId',
        type: 'ID!',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'status',
        type: 'ContractStatus!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'updateContract',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'contractId'
          },
          {
            kind: 'Variable',
            name: 'status',
            variableName: 'status'
          }
        ],
        concreteType: 'Contract',
        plural: false,
        selections: [
          {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'status',
            args: null,
            storageKey: null
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'confirmActionSetContractStatusMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'confirmActionSetContractStatusMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    params: {
      operationKind: 'mutation',
      name: 'confirmActionSetContractStatusMutation',
      id: null,
      text:
        'mutation confirmActionSetContractStatusMutation(\n  $contractId: ID!\n  $status: ContractStatus!\n) {\n  updateContract(id: $contractId, status: $status) {\n    id\n    status\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '841430101b9e8a2667514aadf3858d85';
module.exports = node;
