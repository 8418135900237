/**
 * @flow
 * @relayHash 2b98d64d51a31652c6f47e3782def80c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MyLocationFakeInputQueryVariables = {||};
export type MyLocationFakeInputQueryResponse = {|
  +me: ?{|
    +place: {|
      +street: ?string,
      +postalCode: ?string,
      +city: ?string,
      +countryCode: ?any,
      +stateCode: ?string,
      +coords: ?{|
        +lng: number,
        +lat: number,
      |},
    |}
  |}
|};
export type MyLocationFakeInputQuery = {|
  variables: MyLocationFakeInputQueryVariables,
  response: MyLocationFakeInputQueryResponse,
|};
*/

/*
query MyLocationFakeInputQuery {
  me {
    place: company {
      street
      postalCode: zip
      city
      countryCode: country
      stateCode: state
      coords: gps {
        lng
        lat
      }
      id
    }
    id
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = {
      kind: 'ScalarField',
      alias: null,
      name: 'street',
      args: null,
      storageKey: null
    },
    v1 = {
      kind: 'ScalarField',
      alias: 'postalCode',
      name: 'zip',
      args: null,
      storageKey: null
    },
    v2 = {
      kind: 'ScalarField',
      alias: null,
      name: 'city',
      args: null,
      storageKey: null
    },
    v3 = {
      kind: 'ScalarField',
      alias: 'countryCode',
      name: 'country',
      args: null,
      storageKey: null
    },
    v4 = {
      kind: 'ScalarField',
      alias: 'stateCode',
      name: 'state',
      args: null,
      storageKey: null
    },
    v5 = {
      kind: 'LinkedField',
      alias: 'coords',
      name: 'gps',
      storageKey: null,
      args: null,
      concreteType: 'Gps',
      plural: false,
      selections: [
        {
          kind: 'ScalarField',
          alias: null,
          name: 'lng',
          args: null,
          storageKey: null
        },
        {
          kind: 'ScalarField',
          alias: null,
          name: 'lat',
          args: null,
          storageKey: null
        }
      ]
    },
    v6 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'MyLocationFakeInputQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: [],
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'me',
          storageKey: null,
          args: null,
          concreteType: 'User',
          plural: false,
          selections: [
            {
              kind: 'LinkedField',
              alias: 'place',
              name: 'company',
              storageKey: null,
              args: null,
              concreteType: 'Company',
              plural: false,
              selections: [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/),
                (v5 /*: any*/)
              ]
            }
          ]
        }
      ]
    },
    operation: {
      kind: 'Operation',
      name: 'MyLocationFakeInputQuery',
      argumentDefinitions: [],
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'me',
          storageKey: null,
          args: null,
          concreteType: 'User',
          plural: false,
          selections: [
            {
              kind: 'LinkedField',
              alias: 'place',
              name: 'company',
              storageKey: null,
              args: null,
              concreteType: 'Company',
              plural: false,
              selections: [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/),
                (v5 /*: any*/),
                (v6 /*: any*/)
              ]
            },
            (v6 /*: any*/)
          ]
        }
      ]
    },
    params: {
      operationKind: 'query',
      name: 'MyLocationFakeInputQuery',
      id: null,
      text:
        'query MyLocationFakeInputQuery {\n  me {\n    place: company {\n      street\n      postalCode: zip\n      city\n      countryCode: country\n      stateCode: state\n      coords: gps {\n        lng\n        lat\n      }\n      id\n    }\n    id\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '8e485769d95cf95542dbf216314b6315';
module.exports = node;
