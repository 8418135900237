// @flow

import I18n from '../../../_helpers/I18n';
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Redirect from 'react-router-dom/Redirect';
import MyContract from './MyContract';
import Logger from '@app/Logger';
import { Query } from '../../../_helpers/Api';
import Layout from '../../../_components/Layout';
import Pagination from '../../../_components/Pagination';
import LoadingContent from '../../_components/LoadingContent';

// -------------------------------------------------------------------------------------------------

export default function MyContractsPage(props: *): React.Node {
  const [page, setPage] = React.useState<number>(1);
  const first = 10;
  const offset = (Math.max(page, 1) - 1) * 10;

  const getContracts = (contracts, other = false) => {
    return contracts
      .filter(contract => contract.other === other)
      .map(contract => <MyContract contract={contract} key={contract.id} other={other} />);
  };

  return (
    <Layout.Common narrow>
      <Query
        name="myContracts"
        variables={{
          offset: typeof offset === 'number' && offset > 0 ? offset : null,
          first: first
        }}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return <LoadingContent />;
          }

          if (error) {
            Logger.error(error);
            return <Redirect to="/error/500" />;
          }

          const contracts =
            data &&
            data.myContracts &&
            data.myContracts.edges &&
            data.myContracts.edges.map(it => it.node);

          if (!contracts) {
            return <Redirect to="/error/404" />;
          }

          const total = data && data.myContracts && data.myContracts.total;
          let pages = (total / first) | 0;
          pages = pages < total / first ? pages + 1 : pages;

          return (
            <>
              <h1 className="mb-5 mt-4 font-rubik-title">
                <I18n id="title" d="My Replies" />
              </h1>

              <h2 className="mb-5 text-primary">
                <I18n id="myDeals" d="My Opportunities" />
              </h2>

              <div className="my-contracts-list">
                {getContracts(contracts)}
                {getContracts(contracts).length === 0 && (
                  <Row>
                    <Col className="d-flex flex-column text-primary py-3 mb-5">
                      <I18n id="noResults" d="No replies were found." />
                    </Col>
                  </Row>
                )}
              </div>

              <h2 className="mb-5 text-success">
                <I18n id="otherDeals" d="Opportunities of other users" />
              </h2>

              <div className="my-contracts-list">
                {getContracts(contracts, true)}
                {getContracts(contracts, true).length === 0 && (
                  <Row>
                    <Col className="d-flex flex-column text-primary py-3 mb-5">
                      <I18n id="noResults" d="No replies were found." />
                    </Col>
                  </Row>
                )}
              </div>

              <Pagination onRequestPage={setPage} page={page} pages={pages} />
            </>
          );
        }}
      </Query>
    </Layout.Common>
  );
}
