// @flow

import cn from 'classnames';
import * as React from 'react';
import IconButton from '../../IconButton';
import InputGroup from 'react-bootstrap/InputGroup';

import type { OnChangeEvent } from '../';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function TextInput(props: {
  onChange: (OnChangeEvent<string>) => void,
  placeholder?: React.Node,
  value: null | string,
  isInvalid?: boolean,
  className?: string,
  inputRef?: *,
  name: string,
  error?: *,
  description?: React.Node
}): React.Node {
  const {
    onChange,
    placeholder,
    error, // eslint-disable-line
    value,
    inputRef,
    name,
    className,
    isInvalid,
    description,
    ...rest
  } = props;

  // $FlowFixMe
  delete rest.isValid;
  // $FlowFixMe
  delete rest.validationRules;
  // $FlowFixMe
  delete rest.batchChange;

  return (
    <React.Fragment>
      {description ? (
        <React.Fragment>
          <InputGroup>
            <input
              {...rest}
              className={cn('form-control', { 'is-invalid': isInvalid }, className || '')}
              onChange={changeHandler(name, onChange)}
              placeholder={placeholder}
              value={value || ''}
              ref={inputRef}
              name={name}
            />
            <InputGroup.Append>
              <OverlayTrigger overlay={<Tooltip> {description} </Tooltip>}>
                <IconButton
                  icon={'question'}
                  id={`inputPasswordInputAppend-${name}`}
                  className="d-flex"
                  variant="white"
                />
              </OverlayTrigger>
            </InputGroup.Append>
          </InputGroup>
        </React.Fragment>
      ) : (
        <input
          {...rest}
          className={cn('form-control', { 'is-invalid': isInvalid }, className || '')}
          onChange={changeHandler(name, onChange)}
          placeholder={placeholder}
          value={value || ''}
          ref={inputRef}
          name={name}
        />
      )}
    </React.Fragment>
  );
}

function changeHandler(
  name: string,
  onChange: (OnChangeEvent<string>) => void
): (SyntheticEvent<HTMLTextAreaElement>) => void {
  return function(e: SyntheticEvent<HTMLTextAreaElement>): void {
    const value: null | string = e.currentTarget.value || null;
    onChange && onChange({ value, name });
  };
}
