// @flow

import I18n from '../../_helpers/I18n';
import * as React from 'react';

// -------------------------------------------------------------------------------------------------

function DealStatus(props: *): React.Node {
  return <I18n {...props} id="dealStatus$" d={{ OPEN: 'Open', CLOSED: 'Closed' }} />;
}

DealStatus.types = ['OPEN', 'CLOSED'];

export default DealStatus;
