/**
 * @flow
 * @relayHash a7f0decde902aa8944d0955d1b1ab94a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteDealModalMutationVariables = {|
  dealId: string
|};
export type DeleteDealModalMutationResponse = {|
  +deleteDeal: {|
    +id: string
  |}
|};
export type DeleteDealModalMutation = {|
  variables: DeleteDealModalMutationVariables,
  response: DeleteDealModalMutationResponse,
|};
*/

/*
mutation DeleteDealModalMutation(
  $dealId: ID!
) {
  deleteDeal(id: $dealId) {
    id
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'dealId',
        type: 'ID!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'deleteDeal',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'dealId'
          }
        ],
        concreteType: 'Deal',
        plural: false,
        selections: [
          {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'DeleteDealModalMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'DeleteDealModalMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    params: {
      operationKind: 'mutation',
      name: 'DeleteDealModalMutation',
      id: null,
      text:
        'mutation DeleteDealModalMutation(\n  $dealId: ID!\n) {\n  deleteDeal(id: $dealId) {\n    id\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '5178f2ba2fc50329accb863cd2f0ac19';
module.exports = node;
