// @flow

import * as React from 'react';
import Redirect from 'react-router-dom/Redirect';

import { Context as IdentityContext } from '../../_helpers/Identity';

const Identity = IdentityContext.Consumer;

// -------------------------------------------------------------------------------------------------

export default function AuthPage(props: *): React.Node {
  return (
    <Identity>
      {({ identity }) => <Redirect to={identity ? '/' : '/auth/login'} replace />}
    </Identity>
  );
}
