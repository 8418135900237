// @flow

import cn from 'classnames';
import * as React from 'react';

// $FlowFixMe
import { ReactComponent as Logo } from '../_assets/images/logo.svg';
import { ReactComponent as LogoSK } from '../_assets/images/logo_sk.svg';

export default function Brand(props: { light?: boolean, size?: number }): React.Node {
  const { light, size } = props;

  return process.env.REACT_APP_DEFAULT_LOCALE === 'sk' ? (
    <LogoSK height={70} width={300} className={cn('logo', { light, size })} />
  ) : (
    <Logo height={70} width={300} className={cn('logo', { light, size })} />
  );
}
