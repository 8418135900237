// @flow

import * as React from 'react';
import { Context } from '../_helpers/I18n';

export type DateTimeProps = {|
  children?: string => React.Node,
  value: number | Date | string,
  // eslint-disable-next-line react/no-unused-prop-types
  locale?: string
|};

const configDateTime = {
  minute: '2-digit',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit'
};

const configDate = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
};

const configTime = {
  hour: '2-digit',
  minute: '2-digit'
};

function dateTime(date?: boolean, time?: boolean): React$ComponentType<DateTimeProps> {
  return class DateTime extends React.PureComponent<DateTimeProps> {
    render(): React.Node {
      let val: Date = new Date();
      try {
        val = new Date(this.props.value);
        if (isNaN(val.getTime())) {
          throw new Error('Invalid input value');
        }
      } catch (e) {
        return null;
      }

      return (
        <Context.Consumer>
          {({ locale }) => {
            const formattedValue = format(val, locale, !!date, !!time);
            if (typeof this.props.children === 'function') {
              return this.props.children(formattedValue);
            }

            return formattedValue;
          }}
        </Context.Consumer>
      );
    }
  };
}

export function format(value: Date, locale: string, date: boolean, time: boolean) {
  return new Intl.DateTimeFormat(
    'sk', // this.props.locale || locale, // TODO: Configure
    date && time ? configDateTime : date ? configDate : configTime
  ).format(value);
}

class DateTime extends React.PureComponent<{ ...DateTimeProps, date?: boolean, time?: boolean }> {
  static Date = dateTime(true);
  static Time = dateTime(false, true);

  render(): React.Node {
    const Component = dateTime(true, true);
    // eslint-disable-next-line
    const { date, time, ...passing } = this.props;
    return <Component {...passing} />;
  }
}

export default DateTime;
