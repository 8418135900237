// @flow

import type { QueryRendererProps } from 'react-relay';

import idx from 'idx';
import * as React from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import type { searchCategoryQueryResponse } from './__generated__/searchCategoryQuery.graphql';

import env from '../relayEnvironment';

export default function searchCategory(
  props: QueryRendererProps<searchCategoryQueryResponse>
): React.Node {
  return (
    <QueryRenderer
      environment={env}
      variables={{
        search: idx(props, _ => _.variables.search)
      }}
      query={searchCategoryQuery}
      render={props.children}
    />
  );
}

const searchCategoryQuery = graphql`
  query searchCategoryQuery($search: String) {
    search {
      categories(search: $search, first: 300) {
        value: id
        label: name
      }
    }
  }
`;
