// @flow

import I18n from '../../../../_helpers/I18n';
import * as React from 'react';

import Link from '../../../../_components/Link';
import PencilIcon from '../../../../_assets/images/pencil-coloured.svg';
import CloseIcon from '../../../../_assets/images/ic_close_24px.svg';
import DeleteIcon from '../../../../_assets/images/ic_delete_48px.svg';
import { Context as CloseDealModalContext } from './CloseDealModal';
import { Context as DeleteDealModalContext } from './DeleteDealModal';

export type DealModalTarget = { id: ID, summary: string };

export default function EditModeButtons(props: {
  deal: { id: ID, status: DealStatus, summary: string }
}): React.Node {
  const { deal } = props;
  return (
    <div className="d-inline-block">
      <I18n id="edit" d="Edit deal">
        {title => (
          <Link to={`/deal/edit/${deal.id}`} className="ml-3" title={title.value}>
            <img src={PencilIcon} width="17px" height="17px" alt="pencil" />
          </Link>
        )}
      </I18n>

      {deal.status !== 'CLOSED' && (
        <I18n id="closeDeal" d="Close Opportunity">
          {title => (
            <CloseDealModalContext.Consumer>
              {({ setTarget }) => (
                <Link onClick={setDeal(setTarget, deal)} className="ml-3" title={title.value}>
                  <img src={CloseIcon} width="11px" height="11px" alt="close" />
                </Link>
              )}
            </CloseDealModalContext.Consumer>
          )}
        </I18n>
      )}

      <I18n id="trash" d="Delete Opportunity">
        {title => (
          <DeleteDealModalContext.Consumer>
            {({ setTarget }) => (
              <Link onClick={setDeal(setTarget, deal)} className="ml-3" title={title.value}>
                <img src={DeleteIcon} width="13px" height="17px" alt="trash" />
              </Link>
            )}
          </DeleteDealModalContext.Consumer>
        )}
      </I18n>
    </div>
  );
}

function setDeal(setter: DealModalTarget => void, target: DealModalTarget): () => void {
  return function(): void {
    setter && target && setter(target);
  };
}
