// @flow

import type { QueryRendererProps } from 'react-relay';

import idx from 'idx';
import * as React from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import type { searchKeywordsQueryResponse } from './__generated__/searchKeywordsQuery.graphql';

import env from '../relayEnvironment';

export default function searchKeywords(
  props: QueryRendererProps<searchKeywordsQueryResponse>
): React.Node {
  return (
    <QueryRenderer
      environment={env}
      variables={{
        search: idx(props, _ => _.variables.search)
      }}
      query={graphql`
        query searchKeywordsQuery($search: String) {
          search {
            keywords(search: $search, first: 300)
          }
        }
      `}
      render={props.children}
    />
  );
}
