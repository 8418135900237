// @flow

import type { QueryRendererProps } from 'react-relay';

import * as React from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import type { dealDetailQueryResponse } from './__generated__/dealDetailQuery.graphql';

import env from '../relayEnvironment';

export type dealDetailProps = QueryRendererProps<dealDetailQueryResponse>;

export default function dealDetail(props: dealDetailProps): React.Node {
  return (
    <QueryRenderer
      variables={props.variables}
      render={props.children}
      environment={env}
      query={graphql`
        query dealDetailQuery($dealId: ID!) {
          deal(id: $dealId) {
            id
            summary
            type
            cooperation
            category {
              id
              name
            }
            status
            description
            onlyInternal
            keywords
            expires
            created
            partnerRequirements
            description
            video
            attachments {
              id
              url
              name
              size
            }
            company {
              id
            }
            user {
              id
              firstName
              lastName
              phone
              branch
              workposition
              email
              contactEmail
              linkedin
              messaging {
                type
                value
              }
            }
            isContracted
          }
        }
      `}
    />
  );
}
