/**
 * @flow
 * @relayHash 9ca789fcc51684456e3e16cd67c1cb70
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type articlesQueryVariables = {|
  locale?: ?string,
  limit?: ?number,
|};
export type articlesQueryResponse = {|
  +articles: ?$ReadOnlyArray<?{|
    +id: string,
    +slug: string,
    +title: string,
    +image: {|
      +url: string
    |},
    +commentsCount: number,
    +likesCount: number,
    +created: any,
  |}>
|};
export type articlesQuery = {|
  variables: articlesQueryVariables,
  response: articlesQueryResponse,
|};
*/


/*
query articlesQuery(
  $locale: String
  $limit: Int
) {
  articles(locale: $locale, limit: $limit) {
    id
    slug
    title
    image {
      url
      id
    }
    commentsCount
    likesCount
    created
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "locale",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "limit",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "commentsCount",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "likesCount",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "articlesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "articles",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "image",
            "storageKey": null,
            "args": null,
            "concreteType": "File",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ]
          },
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "articlesQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "articles",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "image",
            "storageKey": null,
            "args": null,
            "concreteType": "File",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/)
            ]
          },
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "articlesQuery",
    "id": null,
    "text": "query articlesQuery(\n  $locale: String\n  $limit: Int\n) {\n  articles(locale: $locale, limit: $limit) {\n    id\n    slug\n    title\n    image {\n      url\n      id\n    }\n    commentsCount\n    likesCount\n    created\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '42c68cd38f402760c685eec3384f57d7';
module.exports = node;
