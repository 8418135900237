// @flow

import * as React from 'react';
import { Context as I18nContext } from '../../_helpers/I18n';

import type { EnumChildren } from './';

import countryList from '../../_assets/countryList.json';

const cache = {};

export default function USState(props: {
  children?: EnumChildren,
  v?: string,
  component?: *
}): React.Node {
  const { v, children, component, ...passing } = props;
  const val = (v || '').toUpperCase();

  const { locale } = React.useContext(I18nContext);

  const [d, setD] = React.useState(cache[locale] || {});
  let value = '';

  if (val && d && d[val]) {
    value = d[val];
  }

  React.useEffect(() => {
    let cb = d => setD(d);

    if (!cache[locale]) {
      getAsyncEnum(locale).then(d => cb && cb(d));
    }

    return () => {
      cb = null;
    };
  }, [locale]);

  if (component) {
    const C = component;
    return <C {...passing} d={d} value={value} />;
  }

  if (children) {
    return children({ value, d });
  }

  return value || val || '';
}

export { countryList };

function getAsyncEnum(locale: string): Promise<{ [string]: string }> {
  if (cache[locale]) {
    return Promise.resolve(cache[locale]);
  }

  if (locale === 'sk') {
    return import('../../_assets/I18n/enum/state.sk.json').then(module => {
      cache[locale] = module.default;
      return cache[locale];
    });
  }

  if (locale === 'cz') {
    return import('../../_assets/I18n/enum/state.cz.json').then(module => {
      cache[locale] = module.default;
      return cache[locale];
    });
  }

  if (locale === 'de') {
    return import('../../_assets/I18n/enum/country.de.json').then(module => {
      cache[locale] = module.default;
      return cache[locale];
    });
  }

  return import('../../_assets/I18n/enum/state.json').then(module => {
    cache[locale] = module.default;
    return cache[locale];
  });
}
