// @flow

import idx from 'idx';
import I18n from '../_helpers/I18n';
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { graphql } from 'react-relay';
import queryString from 'query-string';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import type { DealsFilterType } from './_components/SearchResults';

import Icon from '../_components/Icon';
import Layout from '../_components/Layout';
import Toggle from '../_components/Toggle';
import Searchresults from './_components/SearchResults';
import Form, { FormContext } from '../_components/Form';
import LocationFilterInput from './_components/LocationFilterInput';
import MyLocationFakeInput from './_components/MyLocationFakeInput';
import { parseFilter, getFilterUrlAction } from './_helpers/dealUrlFilter';

// -------------------------------------------------------------------------------------------------

const MAX_RADIUS = 201;

const defaultFilter: DealsFilterType = {
  location: {
    place: null,
    radius: MAX_RADIUS,
    ignore: true,
    open: false
  }
};

export { defaultFilter };

// -------------------------------------------------------------------------------------------------

const updateFilterUrl = getFilterUrlAction('/deal/suggested');
const LocationFakeInputComponent = React.memo(MyLocationFakeInput);

export default class SuggestedDealsPage extends React.PureComponent<*> {
  initialFilter = parseFilter('');

  constructor(props: *) {
    super(props);
    this.state = { disabled: true };
    this.initialFilter = parseFilter('?' + (window.location.search || ''));
    const parsedQuery = window.location.search ? queryString.parse(window.location.search) : null;
    if (parsedQuery && parsedQuery.location && parsedQuery.open === undefined) {
      this.initialFilter.location.open = false;
    }
    if (parsedQuery && (parsedQuery.location === undefined || parsedQuery.location === '0')) {
      this.initialFilter.location.ignore = true;
    }
  }

  componentDidMount() {
    this.setState({ disabled: false });
  }

  render(): React.Node {
    return (
      <Layout.Common>
        <Form defaultValue={this.initialFilter} didChange={updateFilterUrl}>
          <Form.Field
            component={LocationFakeInputComponent}
            name="location.place"
            disabled={this.state.disabled}
          />
          <Row className="deal-filter-header mb-4 mt-4">
            <Col className="d-md-flex align-items-center justify-content-between">
              <h1>
                <I18n id="title" d="Suggested opportunities" />
              </h1>
            </Col>
          </Row>

          <Row className="deal-filter-header mb-2 mt-2">
            <Col className="d-md-flex align-items-center justify-content-between">
              <div className="float-sm-left d-flex flex-column flex-sm-col">
                <Form.Field name="location" component={Toggle} />
              </div>
              <div className="float-sm-right d-flex flex-column flex-sm-row">
                <Form.Field name="location" component={OpenMapButton} />
              </div>
            </Col>
          </Row>

          <Form.Field component={LocationFilterInput} max={MAX_RADIUS} step={5} name="location" />

          <Searchresults
            filterDecorator={decorateFilter}
            contexRef={FormContext} // Connected to form context
            query={suggestedQuery}
            noResults={NoResults}
            suggested
          />
        </Form>
      </Layout.Common>
    );
  }
}

// -------------------------------------------------------------------------------------------------

function NoResults() {
  return <I18n id="noResultsPlaceholder" d="No opportunities were found." />;
}

// -------------------------------------------------------------------------------------------------

// Decorate Form schema to query params
function decorateFilter(data: *): DealsFilterType {
  const location = data.location || {};

  const filter = {
    page: idx(data, _ => _.page) || 0,
    location: idx(data, _ => _.location.ignore) === true ? {} : location //location.radius === MAX_RADIUS ? {} : location
  };

  return filter;
}

// -------------------------------------------------------------------------------------------------

function OpenMapButton(props: *): React.Node {
  const { onChange, value, name } = props;
  const parsedQuery = window.location.search ? queryString.parse(window.location.search) : null;

  const toggleOpen = React.useCallback(() => {
    onChange({ name, value: { ...(value || {}), open: !(value && value.open) } });
  }, [name, value, onChange]);

  const open = parsedQuery ? value && value.open && parsedQuery.open : value && value.open;
  const ignoreLocation = !!(value && value.ignore);

  const c = ignoreLocation ? 'gray' : 'primary';

  return (
    !ignoreLocation && (
      <ButtonGroup>
        <Button
          variant={open ? c : 'outline-' + c}
          className="d-flex filter-button"
          onClick={toggleOpen}
          size="sm"
        >
          <Icon name="location" className="mr-1 sole-icon" />
          {open ? (
            <I18n id="closeMabButton" d="Close map" />
          ) : (
            <I18n id="mapFilterButton" d="Open map" />
          )}
          <Icon name={open ? 'chevron-up' : 'chevron-down'} className="ml-3 sole-icon" />
        </Button>
      </ButtonGroup>
    )
  );
}

// -------------------------------------------------------------------------------------------------

const suggestedQuery = graphql`
  query SuggestedDealsPageQuery($first: Int!, $offset: Int, $location: LocationInput) {
    search {
      results: suggestedDeals(first: $first, offset: $offset, location: $location)
        @connection(key: "SuggestedDeals_results", filters: []) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            summary
            type
            cooperation
            status
            category {
              id
              name
            }
            keywords
            created
            description
            unseenContracts: contracts(seen: false) {
              total
            }
          }
        }
        total
      }
    }
  }
`;
