// @flow

import qs from 'qs';

export function getQueryParams(): { [string]: string } {
  const params = qs.parse(window.location.search.replace(/[?]+/, ''));
  return params || {};
}

export function getQueryParam(name: string): string | null {
  const variables = getQueryParams();
  return variables[name] || null;
}
