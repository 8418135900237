// @flow

import * as React from 'react';
import Jumbo from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';

import I18n from '../../../_helpers/I18n';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BBCode from '../../../_components/BBCode';
import GroupIcon from '../../../_assets/images/Group 136.svg';

// Hompage banner with video
export default function Section1(): React.Node {
  return (
    <Jumbo className="home-s-1">
      {process.env.REACT_APP_FREE_BANNER === 'true' && (
        <div className="alert alert-primary p-4 w-75 mx-auto text-center" role="alert">
          <p className="h4 mb-0 text-primary" style={{ lineHeight: '2.5rem' }}>
            <I18n
              id="bannerFree"
              d="Due to the extraordinary situation, we offer all the features of our platform completely free until August 30, 2020 - offer, request, share everything in one place in unlimited number and free of charges"
            >
              {({ value }) => <BBCode>{value}</BBCode>}
            </I18n>
          </p>
        </div>
      )}
      <Container>
        <Row className="mb-4 pb-4">
          <Col sm={12} md={12} lg={6}>
            <h1 className="mb-0 text-white">
              <I18n
                id="headline"
                d='Collaboration Room[br /][span class="h2"] Your 24/7 cost saving platform.[/span]'
              >
                {({ value }) => <BBCode>{value}</BBCode>}
              </I18n>
            </h1>
            <p className="text-white font-rubik mt-3 mb-5">
              <I18n
                id="subtitle1"
                d="Reduce costs and make more money with a bespoke operating platform."
              >
                {({ value }) => <BBCode>{value}</BBCode>}
              </I18n>
            </p>
            <div className="text-white font-rubik-list mt-2 mb-5">
              <I18n
                id="subtitle2"
                d='[span class="mb-2 font-rubik-bold"]Thanks to the automated matching of a company’s supply and demand:[/span][ul][li]You can share assets and staff within your company efficiently[/li][li]You can establish strategic B2B partnerships[/li][li]You can save money by developing common procurement procedures[/li][li]You can find cheaper and better suppliers[/li][li]You can earn money from renting out unused resources[/li][/ul]'
              >
                {({ value }) => <BBCode>{value}</BBCode>}
              </I18n>
            </div>
            <p className="text-white font-rubik font-rubik-bold mt-2 mb-5">
              <I18n
                id="subtitle3"
                d="All of this is delivered directly (without intermediaries) with a simple and quick overview of the current needs of your company."
              >
                {({ value }) => <BBCode>{value}</BBCode>}
              </I18n>
            </p>
          </Col>
          <Col sm={12} md={12} lg={6} className="image-container">
            <div className="home-s-1-polygon">
              <img src={GroupIcon} className="home-s-1-img" width="477px" height="477px" alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </Jumbo>
  );
}
