// @flow

import type { QueryRendererProps } from 'react-relay';

import * as React from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import type { myContractsQueryResponse } from './__generated__/myContractsQuery.graphql';

import env from '../relayEnvironment';

export default function myContracts(
  props: QueryRendererProps<myContractsQueryResponse>
): React.Node {
  return (
    <QueryRenderer
      variables={props.variables}
      environment={env}
      query={graphql`
        query myContractsQuery(
          $cooperation: [DealCooperation!]
          $categories: [ID!]
          $keywords: [Keyword!]
          $type: DealType
          $offset: Int
          $first: Int
        ) {
          myContracts(
            cooperation: $cooperation
            categories: $categories
            keywords: $keywords
            type: $type
            offset: $offset
            first: $first
          ) {
            edges {
              cursor
              node {
                id
                status
                created
                deal {
                  id
                  summary
                  type
                  created
                  category {
                    id
                    name
                  }
                  cooperation
                }
                consumer {
                  id
                  firstName
                  lastName
                  company {
                    id
                    name
                  }
                }
                ownerUser {
                  id
                  firstName
                  lastName
                }
                other
              }
            }
            total
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
          }
        }
      `}
      render={props.children}
    />
  );
}
