// @flow

import type { QueryRendererProps } from 'react-relay';

import * as React from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import type { manageDealQueryResponse } from './__generated__/manageDealQuery.graphql';

import env from '../relayEnvironment';

export type dealDetailProps = QueryRendererProps<manageDealQueryResponse>;

export default function dealDetail(props: dealDetailProps): React.Node {
  return (
    <QueryRenderer
      variables={props.variables}
      render={props.children}
      environment={env}
      query={graphql`
        query manageDealQuery($dealId: ID!) {
          deal(id: $dealId) {
            id
            category {
              id
              name
            }
            company {
              id
            }
            cooperation
            summary
            contracts(first: 100) {
              edges {
                cursor
                node {
                  id
                  seen
                  status
                  consumer {
                    id
                    firstName
                    lastName
                    created
                    totalContracts
                    company {
                      id
                      name
                    }
                  }
                  deal {
                    id
                    summary
                  }
                  ownerUser {
                    id
                    firstName
                    lastName
                  }
                }
              }
              total
            }
            company {
              id
            }
            user {
              id
            }
          }
        }
      `}
    />
  );
}
