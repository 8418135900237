// @flow

import cn from 'classnames';
import * as React from 'react';
import Container from 'react-bootstrap/Container';

import Footer from '../Footer';

export type CommpnLayoutProps = {|
  verticalCenter?: boolean,
  children: React.Node,
  className?: string,
  noFooter?: boolean,
  narrow?: boolean
|};

export default function CommonLayout(props: CommpnLayoutProps): React.Node {
  const containerClasses = cn('layout-common', props.className, {});
  const wrapperClasses = cn('layout-common__content', {
    narrow: props.narrow,
    'justify-content-center': props.verticalCenter
  });

  return (
    <>
      <Container className={containerClasses}>
        <div className={wrapperClasses}>{props.children}</div>
      </Container>
      {!props.noFooter && <Footer />}
    </>
  );
}
