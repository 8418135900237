// @flow

import * as React from 'react';
import I18n from '../../../_helpers/I18n';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import BBCode from '../../../_components/BBCode';
import { getActiveLocale } from '../../../_helpers/I18n/I18n';
import ModalLogo from '../../../_assets/images/modal-logo.svg';
import { getQueryParam } from '../../../_helpers/router';
import PlayIcon from '../../../_assets/images/play_spolupracovna.svg';
import videoEN from '../../../_assets/video/Spolupracovna-v4-EN.mp4';
import videoCZ from '../../../_assets/video/Spolupracovna-v4-CZ.mp4';
import videoDE from '../../../_assets/video/Spolupracovna-v4-DE.mp4';

// -------------------------------------------------------------------------------------------------

export type VideoModalProps = { show: boolean, source: string, onHide: () => void };

// Areas of cooperation
export default function Section3(): React.Node {
  const [videoShow, setVideoShow] = React.useState(false);
  const activeLang = getActiveLocale();
  const videoSource = activeLang === 'en' ? videoEN : activeLang === 'de' ? videoDE : videoCZ;

  const hideVideo = React.useCallback(() => {
    setVideoShow(false);
  });

  const showVideo = React.useCallback(() => {
    setVideoShow(true);
    const elem = document.getElementById('remarketing-script');
    if (!elem) {
      const script = document.createElement('script');
      script.id = 'remarketing-script';
      script.async = true;
      script.type = 'text/javascript';
      script.src = '/js/remarketing-codes.js';
      document.head.appendChild(script);
    }
    // eslint-disable-next-line
    gtag('event', 'video_play_start', {
      event_category: 'video_play',
      event_label: 'Spolupracovna video'
    });
  });

  const showHowItWorksTab = React.useCallback(() => {
    const tab = document.getElementById('uncontrolled-tab-example-tab-how-it-works');
    if (tab) {
      tab.click();
      tab.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      });
    }
  });

  React.useEffect(() => {
    if (getQueryParam('video')) {
      showVideo();
    }
  }, []);

  return (
    <>
      <VideoModal show={videoShow} source={videoSource} onHide={hideVideo} />
      <div className="home-s-3" id="cooperation-areas">
        <Row className="d-flex align-items-center">
          <Col xs={12} md={6}>
            <h5 className="page-heading mb-5">
              <I18n
                id="title"
                d="Try Spolupracovna.cz and return your company’s figures back to the black"
              />
            </h5>
            <div className="text-muted mb-5">
              <I18n
                id="subtitle"
                d='Click on [a href="https://spolupracovna.cz/" className="font-rubik-bold"]www.spolupracovna.cz[/a] and see for yourself how your company can both save and make money.'
              >
                {({ value }) => <BBCode>{value}</BBCode>}
              </I18n>
            </div>
            <h5 className="page-heading mb-5">
              <I18n id="title2" d="Why use Collaboration Room?" />
            </h5>
            <div className="text-muted mb-5">
              <I18n
                id="subtitle2"
                d="[ul][li]you don`t have to learn to use the application – it is simple and user-friendly[/li][li]the application is flexible to your needs and is available in several languages[/li][li]the application is dual-purpose – it provides solutions for both internal and external purposes[/li][li]the application will help you find new suppliers and business partners instead of relying solely on the pre-selection made by your employees[/li][li]the application will help you reduce costs of the company[/li][li]the application simplifies and speeds up work for users (employees, managers, and business partners)[/li][li]we will install and adapt the application for you very quickly (basis within one month)[/li][/ul]"
              >
                {({ value }) => <BBCode>{value}</BBCode>}
              </I18n>
            </div>
            <Col sm={12} md={12} className="p-sm-0 sm-text-center">
              <div className="buttons-container">
                <Button variant="primary" className="button-large" href={'./contact'}>
                  <I18n id="button" d="I want a demo" />
                </Button>
                <Button variant="primary" className="button-large" onClick={showHowItWorksTab}>
                  <I18n id="button2" d="Learn more" />
                </Button>
              </div>
            </Col>
          </Col>
          <Col xs={12} md={6}>
            <div className="video-container">
              <span className="ml-4 text">
                <I18n id="videoDescription" d="Watch this short video on how our platform works." />
              </span>
              <Button className="play-button" variant="light" onClick={showVideo}>
                <I18n id="playButton" d="Play video" />
                <img className="play-icon" src={PlayIcon} alt="Show modal with video on click" />
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

// -------------------------------------------------------------------------------------------------
function VideoModal(props: VideoModalProps) {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton className="pb-0 border-0">
        <Modal.Title id="contained-modal-title-vcenter" className="p-3">
          <Row noGutters className="align-items-center flex-nowrap">
            <img src={ModalLogo} width="20px" height="40px" alt="Modal logo" />
            <span className="ml-3 font-rubik-modal-title">
              {' '}
              <I18n id="modalTitle" d="Why this platform?" />
            </span>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="border-0 pt-0 pb-0">
        <div className="p-3">
          <video id="VisaChipCardVideo" className="video-container" autoPlay controls>
            <source src={props.source} type="video/mp4" />
          </video>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Row className="w-100 ml-0 mr-0 pb-3 justify-content-end">
          <Button onClick={props.onHide}>
            <I18n id="modalClose" d="Close" />
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}
