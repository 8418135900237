// @flow

import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { graphql, QueryRenderer } from 'react-relay';
import I18n from '../../../../_helpers/I18n';
import environment from '../../../../_helpers/Api/relayEnvironment';
import DealListItem from '../DealListItem';
import ModalLogo from '../../../../_assets/images/modal-logo.svg';
import LoadingContent from '../../LoadingContent';

interface SuggestedProps {
  show: boolean;
  onClose: any;
  dealId: any;
}

const renderDataTable = (result: { error: any, props: any }) => {
  const { error, props } = result;

  if (error) {
    return (
      <p>
        <I18n
          id="errMessage"
          d="Ooops! Something went wrong, try to change search criteria or reload page."
        />
      </p>
    );
  } else if (props) {
    /*eslint-disable */
    const { cooperationDeals } = props;

    if (cooperationDeals && cooperationDeals.length === 0) {
      return <NoResult />;
    }

    if (cooperationDeals !== undefined && cooperationDeals !== null) {
      const items = cooperationDeals.map((edge, key) => (
        <DealListItem editMode={false} key={key} deal={edge} />
      ));
      return <W>{items}</W>;
    }
    /*eslint-enable*/
  }
  return <LoadingContent />;
};

const NoResult = () => {
  return (
    <I18n
      id="noResultsPlaceholder"
      d="No opportunities were found. Please change your search criteria and try again"
    />
  );
};

const W = (props: { children: React.Node }): React.Node => (
  <div className="deal-search-results">{props.children}</div>
);

const SuggestedModal = (props: SuggestedProps) => {
  return (
    <Modal show={props.show} onHide={props.onClose} size="lg">
      <Modal.Header closeButton className="pb-0 border-0">
        <Modal.Title className="p-3">
          <Row noGutters className="align-items-center flex-nowrap">
            <img src={ModalLogo} width="20px" height="40px" alt="Modal logo" />
            <span className="ml-3 font-rubik-modal-title">
              <I18n id="suggestedDeals" d="Suggested deals" />
            </span>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="border-0 pt-0 pb-0"
        style={{
          maxHeight: 'calc(100vh - 152px - 3.5rem)',
          overflow: 'auto'
        }}
      >
        <div className="p-3">
          <QueryRenderer
            key={'key'}
            variables={{
              dealId: props.dealId
            }}
            environment={environment}
            render={renderDataTable}
            query={suggestedQuery}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Row className="w-100 ml-0 mr-0 pb-3 justify-content-end">
          <Button variant="secondary" onClick={props.onClose}>
            <I18n id="closeBtn" d="Close" />
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

// -------------------------------------------------------------------------------------------------

const suggestedQuery = graphql`
  query SuggestedModalQuery($dealId: ID!) {
    cooperationDeals(dealId: $dealId) {
      id
      summary
      type
      cooperation
      status
      category {
        id
        name
      }
      keywords
      created
      description
    }
  }
`;

export default SuggestedModal;
