// @flow

import I18n from '../../_helpers/I18n';
import * as React from 'react';

import ErrorLayout from './ErrorLayout';
import BBCode from '../../_components/BBCode';

// -------------------------------------------------------------------------------------------------

export default function Error401Page(): React.Node {
  return (
    <ErrorLayout>
      <h1>
        <I18n id="title" d="403 [br /]Operation not permited">
          {({ value }) => <BBCode>{value}</BBCode>}
        </I18n>
      </h1>
      <p>
        <I18n id="text" d="Look like you don't have a permission[br /]to provide requested action.">
          {({ value }) => <BBCode>{value}</BBCode>}
        </I18n>
      </p>
    </ErrorLayout>
  );
}
