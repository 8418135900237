// @flow

import * as React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';

import IconButton from '../../../_components/IconButton';
import type { OnChangeEvent } from '../../../_components/Form';

import { TextInput } from '..';

export type PasswordInputProps = {
  label?: (state: boolean) => React.Node,
  onChange: (OnChangeEvent<string>) => void,
  placeholder?: string,
  isInvalid?: boolean,
  value: ?string,
  name: string
};

export default function PasswordInput(props: PasswordInputProps): React.Node {
  const { placeholder, name, onChange, label, value, isInvalid, ...passing } = props;
  const [type, setType] = React.useState('password');

  return (
    <InputGroup className={isInvalid ? 'is-invalid' : ''}>
      <TextInput
        {...passing}
        aria-describedby={`inputPasswordInputAppend-${name}`}
        placeholder={placeholder}
        isInvalid={isInvalid}
        onChange={onChange}
        value={value || ''}
        type={type}
        name={name}
      />
      <InputGroup.Append>
        <IconButton
          icon={type !== 'password' ? 'unseen' : 'seen'}
          id={`inputPasswordInputAppend-${name}`}
          onClick={toggleType(type, setType)}
          className="d-flex"
          variant="transparent"
        >
          {label && label(type !== 'password')}
        </IconButton>
      </InputGroup.Append>
    </InputGroup>
  );
}

const toggleType = (type: string, setType: string => void) => () => {
  setType(type === 'password' ? 'text' : 'password');
};
