// @flow

import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BForm from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import I18n from '../../../_helpers/I18n';
import RobotIcon from '../../../_assets/images/robot.svg';
import CartIcon from '../../../_assets/images/cart-simple-1.svg';
import GlassIcon from '../../../_assets/images/research.svg';
import HouseIcon from '../../../_assets/images/house.svg';
import CheckBox from '../../../_components/Form/_components/CheckBox';
import { Context as IdentityContext } from '../../../_helpers/Identity';
import Form from '../../../_components/Form';
import { DealTypePicker } from './../../Account/_components/MyDealsCollapsibleFilter'; // TODO export to extra file
import CooperationPicker from '../CooperationPicker';
import CategoryKeywordAutocomplete from '../CategoryKeywordAutocomplete';

export default function SearchDealsFilter(): React.Node {
  const icons = [RobotIcon, CartIcon, GlassIcon, HouseIcon];
  const { identity } = React.useContext(IdentityContext);

  return (
    <div>
      <div className="filter-panel">
        <Navbar className="inline" variant="light" bg="white">
          <Row xs={12} md={12} className="m-0 w-100 toggl with-label">
            <Col xs={12} md={2} className="pl-0 d-flex align-items-center filter-label">
              <label className="pl-0 font-rubik-small font-rubik-bold">
                <I18n id="type" d="Opportunity type" />
              </label>
            </Col>
            <Form.Field
              className="mr-0 mb-2 mr-sm-2 mb-sm-0 cooperation-picker"
              component={DealTypePicker}
              variant="primary"
              name="type"
            />
            {(identity.type === 'INTERNAL' || identity.type === 'INTERNAL_ADMIN') && (
              <BForm.Group className="mb-0 internal-container" controlId="internal">
                <I18n id="onlyInternalPlaceholder" d="Internal Opportunity">
                  {placeholder => (
                    // $FlowFixMe
                    <Form.Field
                      className="font-rubik-small font-rubik-bold"
                      label={placeholder.value}
                      component={CheckBox}
                      name="internal"
                    />
                  )}
                </I18n>
              </BForm.Group>
            )}
          </Row>
        </Navbar>
        <Navbar className="inline" variant="light" bg="white">
          <Form.Field
            component={CooperationPicker}
            icons={icons}
            id="cooperationPicker"
            name="cooperation"
            label
            multiple
          />
        </Navbar>
        <Navbar className="inline category-keyword" variant="light" bg="white">
          <div className="w-100 toggl with-label align-items-center">
            <label className="pl-0 font-rubik-small font-rubik-bold filter-search">
              <I18n id="searchLabel" d="Search" />
            </label>
            <Form.Field
              style={{ paddingTop: 7, paddingBottom: 8, zIndex: 900 }}
              component={CategoryKeywordAutocomplete}
              id="filter-panel.categoryOrKeyword"
              name="categoryOrKeyword"
              block
            />
          </div>
        </Navbar>
      </div>
      <div className="pb-2" />
    </div>
  );
}
