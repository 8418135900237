// @flow

import type { QueryRendererProps } from 'react-relay';

import idx from 'idx';
import * as React from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import type { searchCategoryOrKeywordQueryResponse } from './__generated__/searchCategoryOrKeywordQuery.graphql';

import env from '../relayEnvironment';

export default function searchCategoryKeywordQuery(
  props: QueryRendererProps<searchCategoryOrKeywordQueryResponse>
): React.Node {
  const { variables, children } = props;

  return (
    <QueryRenderer
      variables={{ search: idx(variables, _ => _.search) || null }}
      query={searchCategoryOrKeywordQuery}
      environment={env}
      render={children}
    />
  );
}

const searchCategoryOrKeywordQuery = graphql`
  query searchCategoryOrKeywordQuery($search: String) {
    search {
      results: categoryOrKeyword(search: $search, first: 300) {
        label: name
        value: code
        type
      }
    }
  }
`;
