/**
 * @flow
 * @relayHash e06c7e85be9ee8f6b49b862107316a55
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CompanyInput = {|
  id?: ?string,
  owner?: ?string,
  name: string,
  country?: ?string,
  street?: ?string,
  city?: ?string,
  zip?: ?string,
  state?: ?string,
  vat?: ?string,
  logo?: ?string,
  web?: ?string,
  info?: ?string,
  categories: $ReadOnlyArray<string>,
  keywords: $ReadOnlyArray<any>,
  specialization?: ?string,
  certificates?: ?string,
  gps?: ?GPSInput,
|};
export type GPSInput = {|
  lng?: ?number,
  lat?: ?number,
|};
export type AccountCompanyPageMutationVariables = {|
  data: CompanyInput
|};
export type AccountCompanyPageMutationResponse = {|
  +myCompany: {|
    +id: string,
    +country: ?any,
    +state: ?string,
    +street: ?string,
    +city: ?string,
    +zip: ?string,
    +gps: ?{|
      +lng: number,
      +lat: number,
    |},
    +name: ?string,
    +vat: ?string,
    +logo: ?{|
      +id: string,
      +size: number,
      +name: string,
      +url: string,
    |},
    +web: ?string,
    +info: ?string,
    +specialization: ?string,
    +populated: boolean,
    +certificates: ?string,
    +categories: $ReadOnlyArray<{|
      +id: string,
      +name: string,
    |}>,
    +keywords: $ReadOnlyArray<any>,
    +created: ?any,
    +modified: ?any,
  |}
|};
export type AccountCompanyPageMutation = {|
  variables: AccountCompanyPageMutationVariables,
  response: AccountCompanyPageMutationResponse,
|};
*/


/*
mutation AccountCompanyPageMutation(
  $data: CompanyInput!
) {
  myCompany: updateMyCompany(data: $data) {
    id
    country
    state
    street
    city
    zip
    gps {
      lng
      lat
    }
    name
    vat
    logo {
      id
      size
      name
      url
    }
    web
    info
    specialization
    populated
    certificates
    categories {
      id
      name
    }
    keywords
    created
    modified
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "CompanyInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": "myCompany",
    "name": "updateMyCompany",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "Company",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "country",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "state",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "street",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "city",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "zip",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "gps",
        "storageKey": null,
        "args": null,
        "concreteType": "Gps",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lng",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lat",
            "args": null,
            "storageKey": null
          }
        ]
      },
      (v2/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "vat",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "logo",
        "storageKey": null,
        "args": null,
        "concreteType": "File",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "size",
            "args": null,
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "url",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "web",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "info",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "specialization",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "populated",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "certificates",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "categories",
        "storageKey": null,
        "args": null,
        "concreteType": "NACECategory",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "keywords",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "created",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "modified",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AccountCompanyPageMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AccountCompanyPageMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AccountCompanyPageMutation",
    "id": null,
    "text": "mutation AccountCompanyPageMutation(\n  $data: CompanyInput!\n) {\n  myCompany: updateMyCompany(data: $data) {\n    id\n    country\n    state\n    street\n    city\n    zip\n    gps {\n      lng\n      lat\n    }\n    name\n    vat\n    logo {\n      id\n      size\n      name\n      url\n    }\n    web\n    info\n    specialization\n    populated\n    certificates\n    categories {\n      id\n      name\n    }\n    keywords\n    created\n    modified\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ad643545c27b82e0480feb3a03ea6dfc';
module.exports = node;
