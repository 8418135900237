// @flow
import * as React from 'react';
import I18n from '../../../_helpers/I18n';
import BBCode from '../../../_components/BBCode';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Icon from '../../../_components/Icon';
import { getQueryParam } from '../../../_helpers/router';

// more introduction info
export default function Section2(): React.Node {
  const toggleClass = React.useCallback(key => {
    const elems = document.getElementsByClassName('card-header active');
    if (elems[0] && elems[0] !== key.target) {
      elems[0].classList.toggle('active');
    }
    key.target.classList.toggle('active');
  });

  return (
    <>
      <Row className="mb-5 home-s2" id="why-b3b">
        <Tabs
          id="uncontrolled-tab-example"
          defaultActiveKey={getQueryParam('video') ? 'how-it-works' : 'examples'}
        >
          <Tab
            eventKey="examples"
            title={<I18n id="concreteExamples" d="Specific real-life examples" />}
            className="bookmark mt-1"
          >
            <Col xs={12} md={12} className="p-sm-0" id="examples">
              <Card className="p-5 p-md-5">
                <h2>
                  {' '}
                  <I18n id="concreteExamples" d="Specific real-life examples" />
                </h2>
                <p className="text-secondary">
                  <I18n
                    id="description"
                    d="[b]Spolupracovna.cz[/b] is a unique tool for utilizing the[b] principles of the shared and circular economy[/b] within the Czech market. See how it can help you."
                  >
                    {({ value }) => <BBCode>{value}</BBCode>}
                  </I18n>
                </p>
                <Row xs={12} md={12} className="accordion-row">
                  <Accordion>
                    <Col xs={12} md={6}>
                      <Card className="mt-2 rounded-0">
                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={toggleClass}>
                          <I18n
                            id="card1Header"
                            d="ASSETS AND PRODUCTION CAPACITIES: Rent them out and let them make money"
                          />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <div className="card-body-row">
                              <Icon name="check" className="ml-3 mr-3 sole-icon text-success" />
                              <I18n
                                id="card1Body1"
                                d="Offer unused property such as machinery, equipment, warehousing, building, offices, fleet or production lines for rent or lease"
                              />
                            </div>
                            <div className="card-body-row">
                              <Icon name="check" className="ml-3 mr-3 sole-icon text-success" />
                              <I18n
                                id="card1Body2"
                                d="Instead of buying expensive equipment or machines, rent them temporarily"
                              />
                            </div>
                            <div className="card-body-row">
                              <Icon name="check" className="ml-3 mr-3 sole-icon text-success" />
                              <I18n
                                id="card1Body3"
                                d="Bring in new orders and reach new customers"
                              />
                            </div>
                            <div className="card-body-row">
                              <Icon name="check" className="ml-3 mr-3 sole-icon text-success" />
                              <I18n
                                id="card1Body4"
                                d="Offer your older functional machines and equipment for sale"
                              />
                            </div>
                            <div className="card-body-row">
                              <Icon name="check" className="ml-3 mr-3 sole-icon text-success" />
                              <I18n
                                id="card1Body5"
                                d="Provide advertising space, or arrange mutual advertising and promotions with another company"
                              />
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card className="rounded-0">
                        <Accordion.Toggle as={Card.Header} eventKey="1" onClick={toggleClass}>
                          <I18n
                            id="card2Header"
                            d="NEW SUPPLIERS: Choose from a wider range of suppliers and buy cheaper"
                          />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            <div className="card-body-row">
                              <Icon name="check" className="ml-3 mr-3 sole-icon text-success" />
                              <I18n
                                id="card2Body1"
                                d="Field a much larger number of bids in one place, to allow you to choose the most advantageous for your business"
                              />
                            </div>
                            <div className="card-body-row">
                              <Icon name="check" className="ml-3 mr-3 sole-icon text-success" />
                              <I18n
                                id="card2Body2"
                                d="Look for new suppliers who are also manufacturers, cut out intermediaries and do not pay them unnecessary margins"
                              />
                            </div>
                            <div className="card-body-row">
                              <Icon name="check" className="ml-3 mr-3 sole-icon text-success" />
                              <I18n
                                id="card2Body3"
                                d="Discover smaller suppliers offering lower prices directly on the platform (you can also easily share your requests on the platform via any online channel: web, e-mail, social networks, paid advertising)"
                              />
                            </div>
                            <div className="card-body-row">
                              <Icon name="check" className="ml-3 mr-3 sole-icon text-success" />
                              <I18n
                                id="card2Body4"
                                d="Search for suppliers of replacement products based exactly on your specifications"
                              />
                            </div>
                            <div className="card-body-row">
                              <Icon name="check" className="ml-3 mr-3 sole-icon text-success" />
                              <I18n
                                id="card2Body5"
                                d="Select new suppliers transparently, rule out corruption and facilitate internal audits in procurement"
                              />
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card className="rounded-0">
                        <Accordion.Toggle as={Card.Header} eventKey="2" onClick={toggleClass}>
                          <I18n
                            id="card3Header"
                            d="SHARED PROCUREMENT: Reduce prices to a minimum"
                          />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                          <Card.Body>
                            <div className="card-body-row">
                              <Icon name="check" className="ml-3 mr-3 sole-icon text-success" />
                              <I18n
                                id="card3Body1"
                                d="Join forces with another company to reduce high prices of input raw materials, energy, transport and logistics (UPS, DHL, etc.) thanks to bulk discounts"
                              />
                            </div>
                            <div className="card-body-row">
                              <Icon name="check" className="ml-3 mr-3 sole-icon text-success" />
                              <I18n
                                id="card3Body2"
                                d="Buy for multiple branches (central purchasing) to secure the best possible prices"
                              />
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Col>
                    <Col xs={12} md={6}>
                      <Card className="rounded-0">
                        <Accordion.Toggle as={Card.Header} eventKey="3" onClick={toggleClass}>
                          <I18n
                            id="card4Header"
                            d="HUMAN RESOURCES: Don't leave spare capacity sitting idle."
                          />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="3">
                          <Card.Body>
                            <div className="card-body-row">
                              <Icon name="check" className="ml-3 mr-3 sole-icon text-success" />
                              <I18n
                                id="card4Body1"
                                d="Offer and/or request spare human resources within your company (arrange internal transfers of your own people between branches, according to current occupancy and business needs)"
                              />
                            </div>
                            <div className="card-body-row">
                              <Icon name="check" className="ml-3 mr-3 sole-icon text-success" />
                              <I18n
                                id="card4Body2"
                                d="Contract spare staff out to another company during periods of excess capacity"
                              />
                            </div>
                            <div className="card-body-row">
                              <Icon name="check" className="ml-3 mr-3 sole-icon text-success" />
                              <I18n
                                id="card4Body3"
                                d="Recruit new people directly on the platform without overpaying for advertising on overpriced (and often inefficient) job portals"
                              />
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card className="rounded-0">
                        <Accordion.Toggle as={Card.Header} eventKey="4" onClick={toggleClass}>
                          <I18n
                            id="card5Header"
                            d="WASTE DISPOSAL MANAGEMENT: Monetize everything for which you have no use"
                          />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="4">
                          <Card.Body>
                            <div className="card-body-row">
                              <Icon name="check" className="ml-3 mr-3 sole-icon text-success" />
                              <I18n
                                id="card5Body1"
                                d="Offer plastic, scrap metal, bio-waste, paper, or even wooden pallets for sale – these materials can serve another company as a cheaper input raw material for further processing"
                              />
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card className="rounded-0">
                        <Accordion.Toggle as={Card.Header} eventKey="5" onClick={toggleClass}>
                          <I18n id="card6Header" d="SALES: Support sales through the platform" />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="5">
                          <Card.Body>
                            <div className="card-body-row">
                              <Icon name="check" className="ml-3 mr-3 sole-icon text-success" />
                              <I18n id="card6Body1" d="Promote the best products on the platform" />
                            </div>
                            <div className="card-body-row">
                              <Icon name="check" className="ml-3 mr-3 sole-icon text-success" />
                              <I18n
                                id="card6Body2"
                                d="Offer older equipment to employees for sale"
                              />
                            </div>
                            <div className="card-body-row">
                              <Icon name="check" className="ml-3 mr-3 sole-icon text-success" />
                              <I18n id="card6Body3" d="Strengthen your brand on the platform" />
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Col>
                  </Accordion>
                </Row>
                <Row xs={12} md={12} className="mt-5 flex-wrap-reverse card-bottom">
                  <Col xs={12} md={12} className="card-bottom-text text-secondary">
                    <I18n
                      id="concreteDescriptionBottom"
                      d="Turning the coming crisis into an opportunity for corporate growth and cost reduction may be easier than it first seems. All you need to do to consolidate your company's position in the market is make full use of the right tool."
                    >
                      {({ value }) => <BBCode>{value}</BBCode>}
                    </I18n>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Tab>
          <Tab
            eventKey="how-it-works"
            title={<I18n id="howItWorks" d="How it works" />}
            className="bookmark mt-1"
          >
            <Col xs={12} md={12} className="p-sm-0" id="how-it-works">
              <Card className="p-5 p-md-5">
                <h2>
                  {' '}
                  <I18n id="learnMore" d="Learn more" />
                </h2>
                <span className="mb-4 text-secondary">
                  <I18n
                    id="howItWorksDescription"
                    d='[b]Using the platform[/b] is [b]as easy and intuitive as[/b], for example, searching for goods on an e-commerce website. You can start using [a href="https://spolupracovna.cz/"]SPOLUPRACOVNA.cz[/a][b] immediately[/b] – without the need for any training.'
                  >
                    {({ value }) => <BBCode>{value}</BBCode>}
                  </I18n>
                </span>
                <Row xs={12} md={12}>
                  <Col xs={12} md={6} className="mb-4">
                    <ul className="list-group text-secondary">
                      <li className="list-group-item">
                        <I18n id="listItem1" d="The platform will be fully adapted to your needs.">
                          {({ value }) => <BBCode>{value}</BBCode>}
                        </I18n>
                      </li>
                      <li className="list-group-item">
                        <I18n
                          id="listItem2"
                          d="Automatic matching of offers and requests as well as e-mail notifications."
                        >
                          {({ value }) => <BBCode>{value}</BBCode>}
                        </I18n>
                      </li>
                      <li className="list-group-item">
                        <I18n
                          id="listItem3"
                          d="The solution is optimized for mobile, tablet and PC."
                        >
                          {({ value }) => <BBCode>{value}</BBCode>}
                        </I18n>
                      </li>
                      <li className="list-group-item">
                        <I18n
                          id="listItem4"
                          d="We guarantee the launch of the platform within one month of signing the contract."
                        >
                          {({ value }) => <BBCode>{value}</BBCode>}
                        </I18n>
                      </li>
                    </ul>
                  </Col>
                </Row>
                <h2>
                  {' '}
                  <I18n id="usefulServices" d="Other useful services" />
                </h2>
                <Row xs={12} md={12}>
                  <Col xs={12} md={6} className="mb-4">
                    <h5 className="text-primary font-rubik font-rubik-small font-rubik-bold">
                      <I18n id="supportService" d="Service support" />
                    </h5>
                    <div className="text-secondary">
                      <I18n
                        id="supportServiceDesc"
                        d="We will be happy to provide technical support for the operation of the platform by prior arrangement."
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6} className="mb-4">
                    <h5 className="text-primary font-rubik font-rubik-small font-rubik-bold">
                      <I18n id="consultingService" d="Consultation services" />
                    </h5>
                    <div className="text-secondary">
                      <I18n
                        id="consultingServiceDesc"
                        d="We will perform an in-depth analysis of the internal and external needs of the company as a whole, at branch level, and at any other company premises as required."
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6} className="mb-4">
                    <h5 className="text-primary font-rubik font-rubik-small font-rubik-bold">
                      <I18n id="lawService" d="Legal service" />
                    </h5>
                    <div className="text-secondary">
                      <I18n
                        id="lawServiceDesc"
                        d="We will also be happy to advise you legally. We will send draft contracts, advise with regard to insurance of contractual relations, and also warn you of possible risks in each case, if any."
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6} className="mb-4">
                    <h5 className="text-primary font-rubik font-rubik-small font-rubik-bold">
                      <I18n id="webScraping" d="Web scraping" />
                    </h5>
                    <div className="text-secondary">
                      <I18n
                        id="webScrapingDesc"
                        d="Web scraping is a technology that retrieves unstructured information from web pages and stores it in a structured form. It will help you find new suppliers and customers faster, buy machines and equipment at a bargain price and analyze your competition."
                      />
                    </div>
                  </Col>
                </Row>
                <Row md={12} className="mt-5 mt-sm-0 flex-wrap-reverse">
                  <Col xs={12} md={4} className="sm-text-center">
                    <Button variant="primary" className="button-large" href={'./contact'}>
                      <I18n id="iWantDemo" d="I want a demo" />
                    </Button>
                  </Col>
                  <Col xs={12} md={8} className="card-bottom-text text-secondary">
                    <I18n
                      id="otherQuestion"
                      d="Do you have further questions about using the platform? See the Frequently Asked Questions section or email us at info@zynctos.com."
                    >
                      {({ value }) => <BBCode>{value}</BBCode>}
                    </I18n>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Tab>
        </Tabs>
      </Row>
    </>
  );
}
