// @flow

import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ContractListItem from './ContractListItem';
import CreateContractModal from './CreateContractModal';

// -------------------------------------------------------------------------------------------------

// TODO: use GQL fragment
export default function ContractList(props: {
  noItemsMsg: React.Node,
  items: Array<*>
}): React.Node {
  if (!props.items || props.items.length === 0) {
    return (
      <Row>
        <Col className="d-flex flex-column p-3 text-primary">
          <Col className="d-flex flex-column p-3 text-primary">{props.noItemsMsg}</Col>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="manage-deal-contract-list">
      <CreateContractModal>
        {props.items.map(contract => (
          <ContractListItem key={contract.id} contract={contract} />
        ))}
      </CreateContractModal>
    </Row>
  );
}
