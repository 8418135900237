// @flow

import I18n from '../../../_helpers/I18n';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BForm from 'react-bootstrap/Form';
import ReactDOMServer from 'react-dom/server';

import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import RobotIcon from '../../../_assets/images/robot.svg';
import CartIcon from '../../../_assets/images/cart-simple-1.svg';
import GlassIcon from '../../../_assets/images/research.svg';
import HouseIcon from '../../../_assets/images/house.svg';
import CheckBox from '../../../_components/Form/_components/CheckBox';
import CooperationPicker from '../../_components/CooperationPicker';
import type { DealCooperation, DealType } from '../__generated__/NewDealPageMutation.graphql';
import type { FileInterface } from '../../../_components/Form/_components/FileInput/FileInput';

import Enum from '../../../_components/Enum';
import KeywordsAutocomplete from '../../_components/KeywordsAutocomplete';
import CategoryAutocomplete from '../../_components/CategoryAutocomplete';
import Form, {
  TextareaInput,
  ToggleInput,
  DateInput,
  FileInput,
  TextInput
} from '../../../_components/Form';

// -------------------------------------------------------------------------------------------------

const dealFormValidation = {
  summary: { required: true, maxLength: 70 },
  description: { required: true, maxLength: 500 },
  partnerRequirements: { maxLength: 200 },
  cooperation: { required: true },
  expires: { required: true },
  attachments: { maxCount: 5 },
  category: { required: true },
  type: { required: true }
};

// -------------------------------------------------------------------------------------------------

export type DealFormData = {
  cooperation: null | DealCooperation,
  attachments: $ReadOnlyArray<FileInterface>,
  keywords: $ReadOnlyArray<string>,
  category: null | NACECategory,
  partnerRequirements: ?string,
  isInternal: ?Boolean,
  onlyInternal: ?Boolean,
  type: null | DealType,
  expires: null | Date,
  description: ?string,
  summary: ?string,
  video: ?string
};

export type DealFormProps = {|
  onSubmit: (data: null | DealFormData) => void,
  defaultValue?: { [string]: * },
  children?: React.Node,
  disabled?: boolean,
  paying?: boolean,
  populated?: boolean,
  userType?: string
|};

const defaultFormValue: DealFormData = {
  partnerRequirements: null,
  isInternal: false,
  onlyInternal: false,
  cooperation: null,
  description: null,
  attachments: [],
  category: null,
  summary: null,
  expires: null,
  keywords: [],
  video: null,
  type: null
};

// -------------------------------------------------------------------------------------------------

export default function DealForm(props: DealFormProps): React.Node {
  const { disabled, paying, onSubmit, children, defaultValue, populated, userType } = props;
  const dayCreated = defaultValue && defaultValue.created ? new Date(defaultValue.created) : null;

  const icons = [RobotIcon, CartIcon, GlassIcon, HouseIcon];
  if (dayCreated) {
    dayCreated.setHours(
      dayCreated.getHours() + 168,
      dayCreated.getMinutes(),
      dayCreated.getSeconds(),
      dayCreated.getMilliseconds()
    );
  }
  //const today = new Date();
  const isPaying = /*dayCreated && today ? dayCreated.getTime() > today.getTime() : paying*/ true;
  /*const max = !paying
    ? dayCreated
      ? new Date(new Date(dayCreated).setMonth(dayCreated.getMonth() + 6))
      : new Date(new Date().setMonth(new Date().getMonth() + 6))
    : null;*/
  const exDate = /*max && !paying ? new Date().getTime() > new Date(max).getTime() :*/ false;

  const val = {
    ...defaultValue,
    expires: defaultValue && defaultValue.expires ? new Date(defaultValue.expires) : null,
    created: defaultValue && defaultValue.created ? new Date(defaultValue.created) : null
  };

  return (
    <Form
      defaultValue={{ ...defaultFormValue, ...val }}
      validationRules={dealFormValidation}
      onSubmit={promptSubmit(onSubmit)}
      disabled={!populated || (disabled && !isPaying)}
      paying={isPaying}
    >
      <BForm.Group controlId="dealForm.cooperation">
        <BForm.Label className="font-rubik-small">
          <I18n id="cooperationLabel" d="Select Partnership type" />
          <span className="form-label-required"> *</span>
        </BForm.Label>
        <Form.Field
          component={CooperationPicker}
          icons={icons}
          id="cooperationPicker"
          name="cooperation"
        />
      </BForm.Group>

      <Row>
        <Col md={6}>
          <BForm.Group controlId="dealForm.type">
            <BForm.Label className="font-rubik-small">
              <I18n id="typeLabel" d="Select Opportunity type" />
              <span className="form-label-required"> *</span>
            </BForm.Label>
            <Enum.DealType>
              {({ d }) => (
                // $FlowFixMe
                <Form.Field
                  className="deal-picker"
                  options={Object.keys(d).map(key => ({ value: key, label: d[key] }))}
                  component={ToggleInput}
                  name="type"
                  size="sm"
                />
              )}
            </Enum.DealType>
          </BForm.Group>
        </Col>
        {(userType === 'INTERNAL' || userType === 'INTERNAL_ADMIN') && (
          <Col md={6} className="d-flex align-items-center text-right">
            <BForm.Group className="mb-0" controlId="dealForm.onlyInternal">
              <I18n id="onlyInternalPlaceholder" d="Internal Opportunity">
                {placeholder => (
                  // $FlowFixMe
                  <Form.Field
                    className="font-rubik-small font-rubik-bold"
                    label={placeholder.value}
                    component={CheckBox}
                    name="onlyInternal"
                    type="checkbox"
                    disabled={defaultValue}
                  />
                )}
              </I18n>
              <div className="mt-2 text-small font-weight-light ">
                <I18n
                  id="onlyInternalDescription"
                  d="Opportunity will not be visible to external partners"
                />
              </div>
            </BForm.Group>
          </Col>
        )}
      </Row>

      <BForm.Group controlId="dealForm.summary">
        <BForm.Label className="font-rubik-small font-rubik-bold">
          <I18n id="summryLabel" d="Title" />
          <span className="form-label-required"> *</span>
        </BForm.Label>
        <I18n id="summaryPlaceholder" d="Write a short Opportunity title">
          {placeholder => (
            // $FlowFixMe
            <Form.Field
              placeholder={placeholder.value}
              component={TextareaInput}
              name="summary"
              rows={1}
            />
          )}
        </I18n>
        <div className="mt-2 font-italic font-weight-light">
          <small>
            <I18n
              id="summaryFeedback"
              d="Please do not use any company names or Reply information in this field, {max} Character limit."
              max={dealFormValidation.summary.maxLength}
            />
          </small>
        </div>
      </BForm.Group>

      <Row>
        <Col lg={6}>
          <BForm.Group className="category-keyword" controlId="dealForm.category">
            <BForm.Label className="font-rubik-small font-rubik-bold">
              <I18n id="categoryLabel" d="Select Category" />
              <span className="form-label-required"> *</span>
            </BForm.Label>
            <I18n id="categoryPlaceholder" d="Search">
              {placeholder => (
                // $FlowFixMe
                <Form.Field
                  style={{ zIndex: 999 }}
                  component={CategoryAutocomplete}
                  placeholder={placeholder.value}
                  disabled={!populated || (disabled && !isPaying)}
                  name="category"
                />
              )}
            </I18n>
          </BForm.Group>
        </Col>
        <Col lg={6}>
          <BForm.Group controlId="dealForm.expires">
            <BForm.Label className="font-rubik-small font-rubik-bold">
              <I18n id="expiresLabel" d="Expires" />
              <span className="form-label-required"> *</span>
            </BForm.Label>
            {/* $FlowFixMe */}
            <Form.Field
              component={DateInput}
              created={val.created}
              name="expires"
              autoComplete="off"
              paying={paying}
              populated={populated}
              disabled={!populated || (disabled && !isPaying && exDate)}
            />
          </BForm.Group>
        </Col>
      </Row>

      <BForm.Group controlId="dealForm.description">
        <BForm.Label className="font-rubik-small font-rubik-bold">
          <I18n id="descriptionLabel" d="Opportunity description" />
          <span className="form-label-required"> *</span>
        </BForm.Label>
        <I18n id="descriptionPlaceholder" d="Describe in full what the Opportunity consist of">
          {placeholder => (
            // $FlowFixMe
            <Form.Field
              placeholder={placeholder.value}
              component={TextareaInput}
              id="dealForm.description"
              name="description"
            />
          )}
        </I18n>
      </BForm.Group>

      <BForm.Group controlId="dealForm.keywords">
        <BForm.Label className="font-rubik-small font-rubik-bold">
          <I18n id="keywordsLabel" d="Opportunity keywords" />
        </BForm.Label>
        <I18n id="keywordsPlaceholder" d="Characteristic keywords">
          {placeholder => (
            // $FlowFixMe
            <Form.Field
              style={{ zIndex: 900 }}
              component={KeywordsAutocomplete}
              placeholder={placeholder.value}
              disabled={!populated || (disabled && !isPaying)}
              id="dealForm.keywords"
              name="keywords"
            />
          )}
        </I18n>
      </BForm.Group>

      <Row>
        <Col lg={6}>
          <BForm.Group controlId="dealForm.partnerRequirements">
            <BForm.Label className="font-rubik-small font-rubik-bold">
              <I18n id="requirementsLabel" d="Partner requirements" />
            </BForm.Label>
            <I18n
              id="requirementsPlaceholder"
              d="List any certificates or similar that you will need from a potential partner"
            >
              {placeholder => (
                // $FlowFixMe
                <Form.Field
                  id="dealForm.partnerRequirements"
                  placeholder={placeholder.value}
                  component={TextareaInput}
                  name="partnerRequirements"
                />
              )}
            </I18n>
          </BForm.Group>
        </Col>
        <Col lg={6}>
          <BForm.Group controlId="dealForm.attachments">
            <BForm.Label className="font-rubik-small font-rubik-bold">
              <I18n id="attachmentsLabel" d="Attachments" />
            </BForm.Label>
            {/* $FlowFixMes */}
            <Form.Field
              accept="deal_attachment"
              component={FileInput}
              name="attachments"
              multiple={5}
            />
          </BForm.Group>
        </Col>
      </Row>

      <Row>
        <Col md={12} lg={6}>
          <BForm.Group controlId="dealForm.video">
            <BForm.Label className="font-rubik-small font-rubik-bold">
              <I18n id="videoLabel" d="Link to video" />
            </BForm.Label>
            <I18n d="Paste link here" id="videoPlaceholder">
              {placeholder => (
                // $FlowFixMe
                <Form.Field placeholder={placeholder.value} component={TextInput} name="video" />
              )}
            </I18n>
          </BForm.Group>

          {/* TODO: add input description parameter */}
          <div className="mb-4 text-small font-weight-light ">
            <I18n
              id="videoFeedback"
              d="Here you can provide a link to a video that helps illustrating what your Opportunity is about"
            />
          </div>
        </Col>
      </Row>

      {children}
    </Form>
  );
}

// -------------------------------------------------------------------------------------------------

function promptSubmit(onSubmit: DealFormData => void): DealFormData => void {
  return function(data: DealFormData): void {
    const files = data.attachments;
    if (files && files.some(file => !file.id && !file.error)) {
      const msg = ReactDOMServer.renderToString(
        <I18n
          id="skipUnfinishedFile"
          d="Your file upload is in progress, do you want to save form without attachments which are not uploaded already?"
        />
      );
      // eslint-disable-next-line
      confirm(msg) && onSubmit(data);
    } else {
      onSubmit(data);
    }
  };
}
