// @flow

import * as React from 'react';
import Container from 'react-bootstrap/Container';

import Footer from '../Footer';

export type AuthLayoutProps = {| children: React.Node |};

export default function AuthLayout(props: AuthLayoutProps): React.Node {
  return (
    <>
      <Container className="layout-auth">
        <div className="layout-auth__container">
          <div className="layout-auth__box">{props.children}</div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
