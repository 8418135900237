// @flow

import cn from 'classnames';
import * as React from 'react';
import I18n from '../../_helpers/I18n';
import BBCode from '../../_components/BBCode';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

import Link from '../Link';
import Brand from '../Brand';

// -------------------------------------------------------------------------------------------------

export default function Footer(props: *): React.Node {
  const footerLinks: Array<{ path: string, label: React.Node, blank?: boolean }> = [
    {
      label: <I18n id="terms" d="Terms & conditions" />,
      path: `/terms&conditions`
    },
    {
      label: <I18n id="privacy" d="Privacy policy" />,
      path: `/privacy-policy`
    },
    {
      label: <I18n id="contact" d="Contact" />,
      path: '/contact'
    }
  ];
  const { sticky } = props;

  const toggleClass = React.useCallback(key => {
    const elems = document.getElementsByClassName('card-header active');
    if (elems[0] && elems[0] !== key.target) {
      elems[0].classList.toggle('active');
    }
    key.target.classList.toggle('active');
  });

  return (
    <footer className={cn('page-footer no-print')}>
      <div className="container" id="faq">
        {sticky && (
          <>
            <div className="mt-2 mb-3 d-flex justify-content-center">
              <h2 className="page-heading">
                <I18n id="faq" d="Časté dotazy (FAQ)" />
              </h2>
            </div>
            <Row xs={12} md={12}>
              <Accordion className="flex-wrap">
                <Col sm={12} md={6}>
                  <Card className="rounded-0">
                    <Accordion.Toggle as={Card.Header} eventKey="0" onClick={toggleClass}>
                      <I18n id="faq1title" d="Jaký je rozdíl mezi ERP a vaší platformou?" />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="pt-0">
                        <span className="mb-3 answer">
                          <I18n id="answer" d="Odpověď" />
                        </span>
                        <span className="text-justify">
                          <I18n
                            id="faq1answer"
                            d="Platforma má [b]doplňovat váš stávající ERP systém a rozšiřovat jeho možnosti,[/b] cílem není ERP nahradit. Naše platforma je nástroj pro:
                            [ul][li][b]rychlý přehled[/b] o aktuálních potřebách firmy,[/li]
                            [li]informace o [b]možnostech využití zdrojů a majetku[/b] firmy, [/li]
                            [li]pomoc při [b]hledání nových dodavatelů, zákazníků a strategických partnerů[/b] a[/li]
                            [li]zrychlení a zjednodušení [b]komunikace[/b] mezi uživateli.[/li][/ul]"
                          >
                            {({ value }) => <BBCode>{value}</BBCode>}
                          </I18n>
                        </span>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card className="rounded-0">
                    <Accordion.Toggle as={Card.Header} eventKey="1" onClick={toggleClass}>
                      <I18n
                        id="faq2title"
                        d="Potřebovali bychom integrovat náš ERP systém s vaší platformou, je to možné?"
                      />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body className="pt-0">
                        <span className="mb-3 answer">
                          <I18n id="answer" d="Odpověď" />
                        </span>
                        <span className="text-justify">
                          <I18n
                            id="faq2answer"
                            d="Ano. [b]Platformu lze integrovat[/b] za použití rozhraní s vaším ERP systémem, konkrétně např. s adresní knihou nebo s nákupními objednávkami."
                          >
                            {({ value }) => <BBCode>{value}</BBCode>}
                          </I18n>
                        </span>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card className="rounded-0">
                    <Accordion.Toggle as={Card.Header} eventKey="2" onClick={toggleClass}>
                      <I18n
                        id="faq3title"
                        d="Máme již manažerskou nadstavbu (MIS), jak můžeme vaši platformu využít?"
                      />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body className="pt-0">
                        <span className="mb-3 answer">
                          <I18n id="answer" d="Odpověď" />
                        </span>
                        <span className="text-justify">
                          <I18n
                            id="faq3answer"
                            d="Manažerské informační systémy konsolidují informace z ERP, např. ekonomické výsledky, pohledávky a závazky. Lze je použít i pro plánování nebo sledování klíčových ukazatelů za vybraná období. Naše platforma má sloužit k [b]přehledu aktuálních potřeb firmy[/b] v oblasti lidských zdrojů, výroby, nákupu, majetku, případně prodeje. Lze s ní řešit [b]na jednom místě několik aktuálních problémů[/b] – od vývoje produktu přes nákup, výrobu, prodej až po řešení odpadu."
                          >
                            {({ value }) => <BBCode>{value}</BBCode>}
                          </I18n>
                        </span>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Col>
                <Col sm={12} md={6}>
                  <Card className="rounded-0">
                    <Accordion.Toggle as={Card.Header} eventKey="3" onClick={toggleClass}>
                      <I18n id="faq4title" d="Proč není na vaší stránce ceník?" />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body className="pt-0">
                        <span className="mb-3 answer">
                          <I18n id="answer" d="Odpověď" />
                        </span>
                        <span className="text-justify">
                          <I18n
                            id="faq4answer"
                            d="Důvodem jsou naprosto rozdílné a specifické požadavky jednotlivých klientů. [b]Konečná cena se stanoví podle toho, zda zákazník požaduje další modifikace platformy,[/b] jako např. specifické požadavky pro daný typ odvětví, rozhraní do ERP systému atp. nebo žádné změny platformy pro fungování své firmy nepotřebuje. Každopádně platí, že hlavním cílem platformy Spolupracovna.cz je [b]ušetřit vám náklady.[/b] Ani její cena vám tedy neudělá do rozpočtu „díru“. Kromě toho jsme flexibilní v oblasti fakturace ve formě jak CAPEX tak OPEX.  Žádné obavy, [b]vaše náklady na pořízení platformy se vám brzy vrátí. Poradíme vám, jak na to.[/b]"
                          >
                            {({ value }) => <BBCode>{value}</BBCode>}
                          </I18n>
                        </span>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card className="rounded-0">
                    <Accordion.Toggle as={Card.Header} eventKey="4" onClick={toggleClass}>
                      <I18n
                        id="faq5title"
                        d="Jak se naši noví dodavatelé dozví o naší nové platformě?"
                      />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="4">
                      <Card.Body className="pt-0">
                        <span className="mb-3 answer">
                          <I18n id="answer" d="Odpověď" />
                        </span>
                        <span className="text-justify">
                          <I18n
                            id="faq5answer"
                            d="Informace o existenci vaší nové platformy můžete snadno nasdílet přes vaše obvyklé komunikační kanály, jako například [b]firemní web, sociální sítě, intranet nebo tiskové výstupy jako letáky nebo třeba výroční zprávy.[/b] Nejde pouze o informaci pro vlastní zaměstnance či nové dodavatele, ale i pro potenciální nové obchodní partnery (třeba pro společný nákup nebo pro nabídku vlastního majetku k pronájmu)."
                          >
                            {({ value }) => <BBCode>{value}</BBCode>}
                          </I18n>
                        </span>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card className="rounded-0">
                    <Accordion.Toggle as={Card.Header} eventKey="5" onClick={toggleClass}>
                      <I18n id="faq6title" d="Kdo bude vlastnit data v této platformě na míru?" />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="5">
                      <Card.Body className="pt-0">
                        <span className="mb-3 answer">
                          <I18n id="answer" d="Odpověď" />
                        </span>
                        <span className="text-justify">
                          <I18n
                            id="faq6answer"
                            d="Platforma bude zcela vaše. [b]Vy budete vlastníkem a budete mít přístup k datům.[/b] Jen vy pak rozhodujete, komu na platformu povolíte přístup. Platforma bude obsahovat [b]logo vaší firmy[/b] a poběží na [b]doméně, kterou si sami zvolíte[/b] (platformu můžeme na vaše přání nainstalovat i na váš server). Používáme nejmodernější způsoby zabezpečení dat a jejich uchovávání je v souladu s pravidly ochrany osobních údajů [b]dle GDPR.[/b]"
                          >
                            {({ value }) => <BBCode>{value}</BBCode>}
                          </I18n>
                        </span>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Col>
              </Accordion>
            </Row>
            <div className="mt-2 text-muted text-right footer-link-small">
              <I18n id="moreFAQs" d='View more [a href="/faq"]FAQs[/a]'>
                {({ value }) => <BBCode>{value}</BBCode>}
              </I18n>
            </div>
          </>
        )}
        <Row className="mt-5 mt-sm-3 d-flex align-items-center justify-content-center">
          <Row xs={12} sm={12} md={10} className="text-center m-0">
            {footerLinks.map((link, key) => {
              return (
                <Link key={key} to={link.path} blank={link.blank} className="footer-link">
                  {link.label}
                </Link>
              );
            })}
            {/*{process.env.REACT_APP_STAGE !== 'production' && (
              <small className="ml-3">{`v.${window.version || ''}`}</small>
            )}*/}
          </Row>
        </Row>
        <Row className="text-center mt-3 p-2 mt-sm-5 copyright align-items-center justify-content-center">
          <Col sm={12} md={6} lg={4} className="mb-3 mb-md-0 text-center text-sm-center">
            <Link to="/">
              <Brand size={48} light />
            </Link>
          </Col>
          <Col sm={12} md={3} lg={2}>
            <I18n id="copyright" d="Copyright &copy; 2020" />
          </Col>
        </Row>
      </div>
    </footer>
  );
}
