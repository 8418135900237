// @flow

import * as React from 'react';

import parse from './parser';

import {
  HeadlineTag,
  SpanTag,
  LinkTag,
  TextTag,
  SupTag,
  SubTag,
  BrTag,
  UlTag,
  LiTag,
  OlTag,
  BTag,
  ITag,
  UTag,
  STag,
  PTag
} from './tags';

// -------------------------------------------------------------------------------------------------

class Renderer {
  tags = { text: TextTag };

  registerTag(tagName: string, component: React.ComponentType<*>): void {
    this.tags[tagName] = component;
  }

  toReact = (string: string): React.Node => {
    const objects = parse(string);

    return objects.map((obj, key) => {
      const Component = this.tags[obj.type] || this.tags.text;

      return (
        <Component attrs={obj.attrs || {}} toReact={this.toReact} tagName={obj.type} key={key}>
          {obj.child}
        </Component>
      );
    });
  };
}

// -------------------------------------------------------------------------------------------------

const renderer = new Renderer();

renderer.registerTag('b', BTag);
renderer.registerTag('i', ITag);
renderer.registerTag('u', UTag);
renderer.registerTag('s', STag);
renderer.registerTag('p', PTag);
renderer.registerTag('br', BrTag);
renderer.registerTag('ol', OlTag);
renderer.registerTag('ul', UlTag);
renderer.registerTag('li', LiTag);
renderer.registerTag('a', LinkTag);
renderer.registerTag('sub', SubTag);
renderer.registerTag('sup', SupTag);
renderer.registerTag('link', LinkTag);
renderer.registerTag('span', SpanTag);
renderer.registerTag('h1', HeadlineTag);
renderer.registerTag('h2', HeadlineTag);
renderer.registerTag('h3', HeadlineTag);
renderer.registerTag('h4', HeadlineTag);
renderer.registerTag('h5', HeadlineTag);

// -------------------------------------------------------------------------------------------------

export default function BBCode(props: { children: string }): React.Node {
  return renderer.toReact(props.children);
}
