// @flow

import I18n from '../../_helpers/I18n';
import * as React from 'react';

function Salutation(props: *): React.Node {
  return <I18n {...props} id="dealType$" d={{ MR: 'Mr.', MS: 'Ms.' }} />;
}

Salutation.types = ['MR', 'MS'];

export default Salutation;
