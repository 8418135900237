/**
 * @flow
 * @relayHash ab2acd2795edcf5185568281e92b1a64
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DealCooperation = "RND_COOPERATION" | "SHARE_ASSETS" | "SHARE_PROCUREMENT" | "SPARE_CAPACITY" | "%future added value";
export type DealStatus = "CLOSED" | "OPEN" | "%future added value";
export type DealType = "OFFER" | "REQUEST" | "%future added value";
export type MessagingProvider = "SIGNAL" | "SKYPE" | "TELEGRAM" | "VIBER" | "WHATSAPP" | "%future added value";
export type dealDetailQueryVariables = {|
  dealId: string
|};
export type dealDetailQueryResponse = {|
  +deal: ?{|
    +id: string,
    +summary: string,
    +type: DealType,
    +cooperation: DealCooperation,
    +category: {|
      +id: string,
      +name: string,
    |},
    +status: DealStatus,
    +description: string,
    +onlyInternal: boolean,
    +keywords: $ReadOnlyArray<any>,
    +expires: ?any,
    +created: any,
    +partnerRequirements: ?string,
    +video: ?string,
    +attachments: $ReadOnlyArray<{|
      +id: string,
      +url: string,
      +name: string,
      +size: number,
    |}>,
    +company: {|
      +id: string
    |},
    +user: {|
      +id: string,
      +firstName: string,
      +lastName: string,
      +phone: ?string,
      +branch: ?string,
      +workposition: ?string,
      +email: string,
      +contactEmail: ?string,
      +linkedin: ?string,
      +messaging: $ReadOnlyArray<{|
        +type: MessagingProvider,
        +value: string,
      |}>,
    |},
    +isContracted: ?boolean,
  |}
|};
export type dealDetailQuery = {|
  variables: dealDetailQueryVariables,
  response: dealDetailQueryResponse,
|};
*/


/*
query dealDetailQuery(
  $dealId: ID!
) {
  deal(id: $dealId) {
    id
    summary
    type
    cooperation
    category {
      id
      name
    }
    status
    description
    onlyInternal
    keywords
    expires
    created
    partnerRequirements
    video
    attachments {
      id
      url
      name
      size
    }
    company {
      id
    }
    user {
      id
      firstName
      lastName
      phone
      branch
      workposition
      email
      contactEmail
      linkedin
      messaging {
        type
        value
        id
      }
    }
    isContracted
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "dealId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "dealId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "summary",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cooperation",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "NACECategory",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v6/*: any*/)
  ]
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "onlyInternal",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "keywords",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "expires",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "partnerRequirements",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "video",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "attachments",
  "storageKey": null,
  "args": null,
  "concreteType": "File",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    },
    (v6/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "size",
      "args": null,
      "storageKey": null
    }
  ]
},
v17 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "company",
  "storageKey": null,
  "args": null,
  "concreteType": "Company",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ]
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "phone",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "branch",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "workposition",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v24 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contactEmail",
  "args": null,
  "storageKey": null
},
v25 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "linkedin",
  "args": null,
  "storageKey": null
},
v26 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "value",
  "args": null,
  "storageKey": null
},
v27 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isContracted",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "dealDetailQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deal",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Deal",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "messaging",
                "storageKey": null,
                "args": null,
                "concreteType": "MessageIdentity",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v26/*: any*/)
                ]
              }
            ]
          },
          (v27/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "dealDetailQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deal",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Deal",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "messaging",
                "storageKey": null,
                "args": null,
                "concreteType": "MessageIdentity",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v26/*: any*/),
                  (v2/*: any*/)
                ]
              }
            ]
          },
          (v27/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "dealDetailQuery",
    "id": null,
    "text": "query dealDetailQuery(\n  $dealId: ID!\n) {\n  deal(id: $dealId) {\n    id\n    summary\n    type\n    cooperation\n    category {\n      id\n      name\n    }\n    status\n    description\n    onlyInternal\n    keywords\n    expires\n    created\n    partnerRequirements\n    video\n    attachments {\n      id\n      url\n      name\n      size\n    }\n    company {\n      id\n    }\n    user {\n      id\n      firstName\n      lastName\n      phone\n      branch\n      workposition\n      email\n      contactEmail\n      linkedin\n      messaging {\n        type\n        value\n        id\n      }\n    }\n    isContracted\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4df579f73caecad5e63e14bbe4af1428';
module.exports = node;
