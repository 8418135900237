// @flow

import type { QueryRendererProps } from 'react-relay';

import * as React from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import type { articlesQueryResponse } from './__generated__/articlesQuery.graphql';

import env from '../relayEnvironment';

export type articlesProps = QueryRendererProps<articlesQueryResponse>;

export default function articles(props: articlesProps): React.Node {
  return (
    <QueryRenderer
      render={props.children}
      variables={props.variables}
      environment={env}
      query={graphql`
        query articlesQuery($locale: String, $limit: Int) {
          articles(locale: $locale, limit: $limit) {
            id
            slug
            title
            image {
              url
            }
            commentsCount
            likesCount
            created
          }
        }
      `}
    />
  );
}
