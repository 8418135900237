// @flow

import * as React from 'react';

import actionsMap from './actions';

type AuthState = {|
  loading: boolean,
  error: *,
  data: *
|};

export type AuthProps = {|
  children: React.Node | ((null | ((*) => Promise<*>), AuthState) => React.Node),
  action: $Keys<typeof actionsMap>,
  onFinish?: AuthState => void,
  autorun?: boolean,
  variables?: *
|};

/**
 * Auth query component
 * using render props
 * define which action you want to have available
 * - trigger manually by action reference in render-props
 * - read state from render props or promise response
 *
 * example:
 *
 * <Auth action="login">{(action, { loading, data, error }) => <LoginForm onSubmit={action} />}
 */
export default class Auth extends React.PureComponent<AuthProps, AuthState> {
  constructor(props: AuthProps) {
    super(props);
    this.state = {
      loading: !!props.autorun,
      error: undefined,
      data: undefined
    };
  }
  mounted: boolean = false;

  render(): React.Node {
    const { children, action } = this.props;

    if (typeof children === 'function') {
      return children(this.state.loading ? null : this.action(action), {
        ...this.state
      });
    }
    return children;
  }

  action = (action: string) => (vars: *): Promise<*> => {
    const fetch = actionsMap[action];
    return new Promise((resolve, reject) => {
      this.setState({ loading: true, error: undefined }, _ => {
        fetch(vars)
          .then(response => {
            const state = {
              loading: false,
              error: !response.ok ? response : undefined,
              data: response.ok && response.data ? response.data : undefined
            };
            this.mounted ? this.setState(state, _ => resolve(response)) : resolve(response);
          })
          .catch(error => {
            this.mounted
              ? this.setState({ loading: false, error, data: undefined }, _ => reject(error))
              : reject(error);
          });
      });
    }).then(response => {
      this.props.onFinish && this.props.onFinish({ ...this.state });
      return response;
    });
  };

  componentDidMount(): void {
    this.mounted = true;
    const { action, autorun, variables } = this.props;
    const event = this.action(action);
    if (autorun && event) {
      event(variables);
    }
  }

  componentWillUnmount(): void {
    this.mounted = false;
  }
}
