// @flow

import * as React from 'react';
import I18n from '../../_helpers/I18n';
import Icon from '../../_components/Icon';
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Enum from '../../_components/Enum';

// -------------------------------------------------------------------------------------------------

// TODO: prop types
export default function DealStatusDropdown(props: *): React.Node {
  const { value, onChange, id, name } = props;
  return (
    <NavDropdown
      id={id}
      className="pl-0 pb-0 no-caret deal-status-dropdown"
      title={
        <Button variant="outline-primary">
          {value ? <Enum.DealStatus v={value} /> : <AnyLabel />}
          <Icon name={value ? 'chevron-up' : 'chevron-down'} className="ml-3 sole-icon" />
        </Button>
      }
    >
      <NavDropdown.Item
        active={!value}
        className="pl-4 d-flex align-items-center"
        onClick={handlePick(onChange, name, null)}
      >
        <AnyLabel />
      </NavDropdown.Item>
      <Enum.DealStatus>
        {types =>
          Object.keys(types.d).map(type => (
            <NavDropdown.Item
              onClick={handlePick(onChange, name, type)}
              className="pl-4 d-flex align-items-center"
              active={type === value}
              href={'#' + type}
              key={type}
            >
              {types.d[type]}
            </NavDropdown.Item>
          ))
        }
      </Enum.DealStatus>
    </NavDropdown>
  );
}

// -------------------------------------------------------------------------------------------------

function handlePick(
  onChange: (*) => void,
  name: string,
  value: null | string
): (e: SyntheticEvent<*>) => void {
  return function(e: SyntheticEvent<*>) {
    e.preventDefault();
    onChange && onChange({ name, value });
  };
}

function AnyLabel(): React.Node {
  return <I18n id="any" d="Any" />;
}
