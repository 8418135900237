// @flow

import * as React from 'react';
import I18n, { Context as I18nContext } from '../../../_helpers/I18n';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logger from '@app/Logger';
import LoadingContent from '../../_components/LoadingContent';
import Link from '../../../_components/Link';
import Icon from '../../../_components/Icon';
import ChatIcon from '../../../_assets/images/chat-46.svg';
import ChatIconGrey from '../../../_assets/images/chat-46-1.svg';

function Section4(): React.Node {
  const { locale } = React.useContext(I18nContext);
  const [loading, setLoading] = React.useState(false);
  const [blogArticles, setBlogArticles] = React.useState(null);
  const [blogArticleComments, setBlogArticleComments] = React.useState([]);
  const [blogArticleImages, setBlogArticleImages] = React.useState([]);

  const langTags = {
    cz: 111,
    en: 113,
    sk: 115,
    de: 117
  };

  React.useEffect(() => {
    setLoading(true);
    fetch(
      `https://${process.env.REACT_APP_BLOG_URL}/wp-json/wp/v2/posts?orderby=date&order=desc&tags=${
        langTags[locale]
      }`
    )
      .then(res => res.json())
      .then(
        results => {
          fetchComments(results);
          fetchImages(results);
          setBlogArticles(results);
        },
        error => {
          setLoading(false);
          Logger.error(error);
        }
      );
  }, [locale]);

  const fetchComments = async results => {
    const displayed = results.slice(0, 3);
    const comments = [];
    // eslint-disable-next-line
    for (const result of displayed) {
      await fetch(
        `https://${process.env.REACT_APP_BLOG_URL}/wp-json/wp/v2/comments?post=${result.id}`
      )
        .then(res => res.json())
        .then(
          res => {
            comments.push(res.length);
          },
          error => {
            Logger.error(error);
          }
        );
    }
    setLoading(false);
    setBlogArticleComments(comments);
  };

  const fetchImages = async results => {
    const displayed = results.slice(0, 3);
    const imgArr = [];
    // eslint-disable-next-line
    for (const result of displayed) {
      await fetch(result._links['wp:featuredmedia'][0].href)
        .then(res => res.json())
        .then(
          image => {
            imgArr.push(image.source_url);
          },
          error => {
            Logger.error(error);
          }
        );
    }
    setLoading(false);
    setBlogArticleImages(imgArr);
  };

  const formatDate = date => {
    return new Date(date).toLocaleDateString('de-DE');
  };

  return loading ? (
    <LoadingContent />
  ) : (
    blogArticles && blogArticles.length > 0 && !loading && (
      <div className="home-s4">
        <a id="blog" href="#blog">
          {' '}
        </a>
        <div className="d-flex flex-column title">
          <h2 className="page-heading">
            <I18n id="title" d="Our blog" />
          </h2>
        </div>
        <Row className="d-flex">
          <Col xs={12} md={6} className="position-relative blog-left">
            <Link
              className="text-decoration-none"
              to={(blogArticles && blogArticles[0].link) || '/article'}
            >
              <div className="blog-container">
                <img className="blog-image" src={blogArticleImages[0]} alt="Left article logo" />
              </div>
              <div className="blog-image-text">
                <div className="date">
                  <I18n id="date" d="Date" /> :{' '}
                  {(blogArticles && formatDate(blogArticles[0].date, locale)) || ''}
                </div>
                <div className="text pt-2 pt-sm-4 pb-3">
                  {(blogArticles && blogArticles[0].title.rendered) || ''}
                </div>
                <div className="counts">
                  <span className="pr-3">
                    <img className="blog-icon" src={ChatIcon} alt="Chat icon" />{' '}
                    {blogArticleComments[0]}
                  </span>
                </div>
              </div>
            </Link>
          </Col>
          <Col xs={12} md={6} className="d-flex flex-column justify-content-center">
            {(blogArticles.slice(1, 3) || []).map((article, index) => (
              <Row className="mb-4" key={article.slug}>
                <Col xs={4} sm={3} md={4} lg={3}>
                  <Link className="text-decoration-none" to={article.link || '/article'}>
                    <img
                      className="blog-image-small"
                      src={blogArticleImages[index + 1]}
                      alt="Right article logo"
                    />
                  </Link>
                </Col>
                <Col xs={8} sm={9} md={8} lg={9}>
                  <Link className="text-decoration-none" to={article.link || '/article'}>
                    <div className="blog-small-image-text">
                      <div className="date">
                        <I18n id="date" d="Datum" /> : {formatDate(article.date, locale)}
                      </div>
                      <div className="text pt-2 pb-2">{article.title.rendered}</div>
                      <div className="counts">
                        <span className="pr-3">
                          <img className="blog-icon" src={ChatIconGrey} alt="Chat icon" />{' '}
                          {blogArticleComments[index + 1]}
                        </span>
                      </div>
                    </div>
                  </Link>
                </Col>
              </Row>
            ))}
            <div className="font-rubik-small font-rubik-bold others-link">
              <Link
                to={'https://' + process.env.REACT_APP_BLOG_URL + '/' + locale + '/'}
                className="text-primary"
              >
                <I18n id="other" d="View more blog posts" />{' '}
              </Link>
              <Icon name="arrow-right" className="ml-3 mr-3 sole-icon" />
            </div>
          </Col>
        </Row>
      </div>
    )
  );
}

export default React.memo(Section4);
