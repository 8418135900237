// @flow

import { getEndpoint } from './';

export default function logout(): Promise<*> {
  return fetch(getEndpoint() + '/auth/logout', {
    method: 'GET',
    headers: {
      'x-requested-with': 'XMLHttpRequest',
      'content-type': 'application/json'
    },
    cache: 'no-cache',
    credentials: 'include'
  })
    .then(res => res.json())
    .catch(error => ({
      ok: false,
      error
    }));
}
