// @flow

import { graphql } from 'react-relay';

import type { CreateContractModalTarget } from './CreateContractModal';

import commitMutationPromise from '../../../../_helpers/Api/commitMutationPromise';

export default function confirmAction(
  target: ?CreateContractModalTarget,
  status: DealStatus
): Promise<*> {
  const contractId = target && target.id;
  const deal = target && target.deal;
  const dealId = deal && deal.id;

  if (deal && contractId && dealId) {
    const actions = [];

    if (status === 'CLOSED') {
      actions.push(
        commitMutationPromise({
          mutation: closeDealMutation,
          variables: { dealId }
        })
      );
    }

    // set contract status
    actions.push(
      commitMutationPromise({
        mutation: setContractStatusMutation,
        variables: { contractId, status: 'OPEN' }
      })
    );

    return Promise.all(actions);
  }

  // TODO: throw err maybe?
  return Promise.reject('Invalid confirm action data');
}

const closeDealMutation = graphql`
  mutation confirmActionCloseDealMutation($dealId: ID!) {
    closeDeal(id: $dealId) {
      id
      status
    }
  }
`;

const setContractStatusMutation = graphql`
  mutation confirmActionSetContractStatusMutation($contractId: ID!, $status: ContractStatus!) {
    updateContract(id: $contractId, status: $status) {
      id
      status
    }
  }
`;
