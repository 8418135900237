// @flow

import idx from 'idx';
import I18n from '../../_helpers/I18n';
import * as React from 'react';
import { graphql } from 'react-relay';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import type { DealsFilterType } from '../_components/SearchResults';

import Link from '../../_components/Link';
import Icon from '../../_components/Icon';
import Layout from '../../_components/Layout';
import BBCode from '../../_components/BBCode';
import Searchresults from '../_components/SearchResults';
import { Context } from '../../_helpers/Identity';
import Form, { ToggleInput, FormContext } from '../../_components/Form';
import { parseFilter, getFilterUrlAction } from '../_helpers/dealUrlFilter';
import MyDealsCollapsibleFilter from './_components/MyDealsCollapsibleFilter';

// -------------------------------------------------------------------------------------------------

const updateFilterUrl = getFilterUrlAction('/account/my-deals');

export default class MyDealsPage extends React.PureComponent<*> {
  static contextType = Context;
  initialFilter = parseFilter('');

  constructor(props: *): void {
    super(props);
    this.initialFilter = parseFilter('?' + window.location.search || '');
  }

  render(): React.Node {
    /*const free = !this.context.identity.company.paying;
    const created = this.context.identity.company.created;
    const expirationDate = free
      ? new Date(new Date(created).setMonth(new Date(created).getMonth() + 6))
      : null;
    const today = new Date().getTime();*/
    return (
      <Layout.Common>
        {/*expirationDate && today > expirationDate.getTime() && (
          <Alert variant="danger" className="d-flex align-items-center rounded-pill">
            <span>
              <I18n id="accountExpired" d="Validity of your account has expired" />
            </span>

            <Link to="/subscribe" className="ml-auto">
              <I18n id="subscription" d="Subscribe now" />
            </Link>
          </Alert>
        )*/}
        <Form defaultValue={this.initialFilter} didChange={updateFilterUrl}>
          <Row className="deal-filter-header mb-4 mt-4">
            <h1 className="pl-20 font-rubik-title">
              <I18n id="title" d="My Opportunities" />
            </h1>
          </Row>
          <Row className="deal-filter-header mb-4 mt-4">
            <Col className="d-md-flex flex-wrap align-items-start justify-content-between">
              <Form.Field component={ToggleFilter} name="open" className="mb-2" />
              <Link
                className="new-deal-btn"
                component={Button}
                variant="outline-primary"
                to="/deal/new"
              >
                <Icon name="plus" />
                <span className="d-flex">
                  <I18n id="createButton" d="Create Opportunity" />
                </span>
              </Link>
            </Col>
          </Row>

          <MyDealsCollapsibleFilter />

          <Searchresults
            filterDecorator={decorateFilter}
            contexRef={FormContext}
            noResults={NoResults}
            query={myDealsQuery}
            editMode
            mine
          />
        </Form>
      </Layout.Common>
    );
  }
}

// -------------------------------------------------------------------------------------------------

function ToggleFilter(props: *): React.Node {
  return (
    <ToggleInput
      {...props}
      variant={'primary'}
      options={[
        {
          label: (
            <>
              <I18n id="toggleFilterButton" d="Filter" />
              <Icon name={props.value ? 'chevron-up' : 'chevron-down'} className="ml-4 sole-icon" />
            </>
          ),
          value: true
        }
      ]}
      value={!!props.value}
      disabled={false}
      size="sm"
    />
  );
}

// -------------------------------------------------------------------------------------------------

function decorateFilter(data: *): DealsFilterType {
  // TODO: unify this decorator with deal search
  const categoryOrKeyword = (data && data.categoryOrKeyword) || {};
  const filter = {
    ...data,
    category: categoryOrKeyword.type === 'CATEGORY' ? categoryOrKeyword.value : null,
    keywords: categoryOrKeyword.type === 'KEYWORD' ? [categoryOrKeyword.label] : [],
    location: null
  };
  //console.log(data);
  const coords = idx(data, _ => _.location.place.coords);
  const radius = idx(data, _ => _.location.radius);
  if (coords && radius) {
    filter.location = {
      lng: coords.lng,
      lat: coords.lat,
      radius
    };
  }

  return filter;
}

// -------------------------------------------------------------------------------------------------

function NoResults() {
  return (
    <I18n id="noResults" d='No opportunities were found. [a href="/deal/new"]Create a new one[/a]'>
      {({ value }) => <BBCode>{value}</BBCode>}
    </I18n>
  );
}

// -------------------------------------------------------------------------------------------------

const myDealsQuery = graphql`
  query MyDealsPageQuery(
    $cooperation: [DealCooperation!]
    $keywords: [Keyword!]
    $status: DealStatus
    $type: DealType
    $category: ID
    $first: Int!
    $offset: Int
    $internal: Boolean
  ) {
    search {
      results: dealsConnection(
        cooperation: $cooperation
        category: $category
        keywords: $keywords
        offset: $offset
        status: $status
        first: $first
        type: $type
        internal: $internal
        mine: true
      )
        @connection(
          key: "MyDeals_results"
          filters: [
            "cooperation"
            "location"
            "categories"
            "keywords"
            "status"
            "type"
            "internal"
          ]
        ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            summary
            type
            cooperation
            status
            category {
              id
              name
            }
            isInternal
            onlyInternal
            keywords
            created
            description
            unseenContracts: contracts(seen: false) {
              total
            }
          }
        }
        total
      }
    }
  }
`;
