// @flow

import * as React from 'react';

import type { AuthLayoutProps } from './AuthLayout';
import type { CommpnLayoutProps } from './CommonLayout';
import type { ContactLayoutProps } from './ContactLayout';

import Auth from './AuthLayout';
import Common from './CommonLayout';
import Contact from './ContactLayout';

export default class Layout extends React.PureComponent<CommpnLayoutProps> {
  static Contact: React$ComponentType<ContactLayoutProps> = Contact;
  static Common: React$ComponentType<CommpnLayoutProps> = Common;
  static Auth: React$ComponentType<AuthLayoutProps> = Auth;

  render(): React.Node {
    return <Common {...this.props} />;
  }
}
