// @flow

import AccountPage, {
  ProfilePage,
  PasswordResetPage,
  MyDealsPage,
  MyContractsPage,
  ManageContractPage,
  AccountCompanyPage
} from '../Account';
import AuthPage, {
  ResendVerification,
  ForgottenPage,
  VerifiedPage,
  SignUpPage,
  LogoutPage,
  LoginPage,
  ResetPage
} from '../Auth';
import HomePage from '../HomePage';
import ContactPage from '../ContactPage';
import FaqPage from '../FaqPage';
import ContentPage from '../ContentPage';
//import SubscribePage from '../SubscribePage';
import SearchDealsPage from '../SearchDealsPage';
import LazyRoute from '../_components/LazyRoute';
import SuggestedDealsPage from '../SuggestedDealsPage';
import { Error401, Error403, Error404, Error500 } from '../Error';
import { DealManagePage, DealDetailPage, EditDealPage, NewDealPage } from '../Deal';
import UserCompanyPage from '../UserCompanyPage';

// -------------------------------------------------------------------------------------------------

const routes = [
  {
    path: '/error',
    component: Error404,
    routes: [
      { path: '/401', component: Error401 },
      { path: '/403', component: Error403 },
      { path: '/404', component: Error404 },
      { path: '/500', component: Error500 }
    ]
  },
  {
    path: '/auth',
    component: AuthPage,
    routes: [
      { path: '/resend-verification', component: ResendVerification },
      { path: '/forgotten', component: ForgottenPage },
      { path: '/reset/:token', component: ResetPage },
      { path: '/verified', component: VerifiedPage },
      { path: '/sign-up', component: SignUpPage },
      { path: '/logout', component: LogoutPage },
      { path: '/login', component: LoginPage }
    ]
  },
  {
    path: '/account',
    permission: 'registered',
    component: AccountPage,
    routes: [
      {
        path: '/my-contracts',
        component: MyContractsPage,
        routes: [{ path: '/update/:contractId', component: ManageContractPage }]
      },
      { path: '/company', component: AccountCompanyPage },
      { path: '/profile', component: ProfilePage },
      { path: '/reset', component: PasswordResetPage },
      {
        path: '/my-deals',
        component: MyDealsPage,
        routes: [
          { path: '/delete/:dealId', component: EditDealPage },
          { path: '/', exact: true, component: MyDealsPage },
          { path: '/', component: Error404 }
        ]
      }
    ]
  },
  /*{
    permission: 'registered',
    component: SubscribePage,
    path: '/subscribe'
  },*/
  {
    path: '/company/:companyId/user/:userId',
    permission: 'registered',
    component: UserCompanyPage
  },
  {
    path: '/content',
    component: ContentPage
  },
  {
    path: '/success-story',
    component: LazyRoute,
    exact: true
  },
  {
    path: '/terms&conditions',
    component: LazyRoute,
    exact: true
  },
  {
    path: '/privacy-policy',
    component: LazyRoute,
    exact: true
  },
  {
    path: '/faq',
    component: FaqPage
  },
  { path: '/suggested-deals', component: SuggestedDealsPage },
  {
    path: '/deal',
    permission: 'registered',
    component: SearchDealsPage,
    routes: [
      { path: '/suggested', component: SuggestedDealsPage },
      {
        path: '/detail/:dealId',
        component: DealDetailPage,
        routes: [
          {
            path: '/contact/:companyId/user/:userId',
            component: UserCompanyPage
          }
        ]
      },
      { path: '/delete/:dealId', permission: 'paying', component: DealManagePage },
      { path: '/manage/:dealId', component: DealManagePage },
      { path: '/edit/:dealId', component: EditDealPage },
      { path: '/new', component: NewDealPage }
    ]
  },
  { path: '/contact', component: ContactPage },
  { path: '/', exact: true, component: HomePage },
  { path: '/', component: Error404 }
];

export default routes;
