// @flow

import I18n from '../../../../_helpers/I18n';
import Location from '../../../../_assets/images/ic_place_24px.svg';
import * as React from 'react';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import LocationModal from './LocationModal';
import { TextInput, FormContext } from '../..';
import { placeToString } from '../../../GoogleAutocompleteInput';

export type LocationInputProps = {
  decoratePlace?: (*) => Place | null,
  decorateData?: (Place | null) => *,
  placeholder?: React.Node,
  name: string
};

export default function LocationInput(props: LocationInputProps): React.Node {
  const { placeholder, decoratePlace, name, decorateData, ...passing } = props;
  const { data, batchChange, errors, disabled } = React.useContext(FormContext);
  const [open, setOpen] = React.useState(false);

  const isInvalid: boolean = Boolean(errors && errors[name]);

  const place: null | Place = (decoratePlace && decoratePlace(data)) || null;
  const stringVal = placeToString(place);

  const confirm = React.useCallback(
    (val: null | Place) => {
      setOpen(false);
      let newVal = val;
      if (decorateData) {
        newVal = decorateData(val);
      }
      if (name) {
        newVal = { ...newVal, [name]: placeToString(val) };
      }
      batchChange(newVal);
    },
    [batchChange, decorateData]
  );

  const toggleOpen = React.useCallback(
    _ => {
      setOpen(open => !open);
    },
    [setOpen]
  );

  return (
    <>
      <InputGroup className={isInvalid ? 'is-invalid' : ''}>
        <TextInput
          {...passing}
          placeholder={placeholder}
          isInvalid={isInvalid}
          disabled={disabled}
          onClick={toggleOpen}
          onChange={devNull}
          value={stringVal}
          type="text"
          name={name}
          readOnly
        />
        <InputGroup.Append>
          <I18n id="hint" d="Pick a location from map">
            {({ value }) => (
              <Button
                className="d-flex btn-icon"
                onClick={toggleOpen}
                variant="light"
                title={value}
                disabled={disabled}
              >
                <img src={Location} alt="HAHAHA123" />
              </Button>
            )}
          </I18n>
        </InputGroup.Append>
      </InputGroup>
      <LocationModal
        defaultValue={place || null}
        onRequestClose={toggleOpen}
        onRequestConfirm={confirm}
        title={placeholder}
        open={open}
      />
    </>
  );
}

const devNull = () => undefined;
