// @flow

import I18n from '../../../_helpers/I18n';
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ClockIcon from '../../../_assets/images/ic_access_time_24px.svg';
import PencilIcon from '../../../_assets/images/pencil-coloured.svg';
import { CooperationIcon, DaysAgo } from '../../_components/DealList/DealListItem';

import Link from '../../../_components/Link';
import Enum from '../../../_components/Enum';
import DateTime from '../../../_components/DateTime';

// -------------------------------------------------------------------------------------------------

const STATUS_COLOR_MAP = {
  PROPOSED: 'secondary',
  COMPLETED: 'secondary',
  CLOSED: 'success',
  FAILED: 'danger',
  OPEN: 'success'
};

// -------------------------------------------------------------------------------------------------

export default function MyContract(props: { contract: *, other?: any }): React.Node {
  const contract = props.contract;
  const other = props.other || false;
  const deal = contract && contract.deal;
  const user = contract && contract.consumer;
  const company = contract && contract.consumer.company;

  if (!deal || !contract || !company) {
    return null;
  }

  return (
    <div className="my-contract-item">
      {/* Summary - status */}
      <ContractItemHeader deal={deal} contract={contract} />

      {/* Details */}
      <ContractItemTable deal={deal} contract={contract} company={company} user={user} />

      {/* Action buttons */}
      <Row className="my-contract-item__footer" noGutters>
        <Col sm={12} md={12} lg={9}>
          <Link
            component={Button}
            to={`/account/my-contracts/update/${contract.id}`}
            variant="outline-primary"
          >
            <img src={PencilIcon} className="mr-3" width="17px" height="17px" alt="" />
            <I18n id="editContractButton" d="Edit Reply" />
          </Link>
          {other ? (
            <Link to={`/deal/detail/${deal.id}`} component={Button} variant="primary">
              <I18n id="dealDetailButton" d="Show opportunity" />
            </Link>
          ) : (
            <Link to={`/deal/edit/${deal.id}`} component={Button} variant="primary">
              <I18n id="editDealButton" d="Edit deal" />
            </Link>
          )}
        </Col>
        <Col
          sm={12}
          md={12}
          lg={3}
          className="font-weight-bold d-flex align-items-center justify-content-end"
        >
          <img src={ClockIcon} className="mr-3" width="20px" height="20px" alt="" />
          <DaysAgo value={deal.created} />
        </Col>
      </Row>
    </div>
  );
}

// -------------------------------------------------------------------------------------------------

// display contract item header
function ContractItemHeader(props: { deal: *, contract: * }): React.Node {
  const { deal, contract } = props;

  const summary = <h3 className="title mr-1">{deal.summary}</h3>;

  return (
    <div className="my-contract-item__header">
      <div className="d-flex mb-4">
        <CooperationIcon
          value={deal.cooperation}
          className="float-left mr-2 text-primary"
          grayScale
        />

        <div className="d-none d-md-block" style={{ flex: 1 }}>
          {summary}
        </div>

        <div>
          <span
            className={
              'text-' + STATUS_COLOR_MAP[contract.status] + ' font-rubik-bold' || 'secondary'
            }
          >
            <Enum.ContractStatus v={contract.status} />
          </span>
        </div>
      </div>

      <div className="d-block d-md-none mb-3">{summary}</div>
    </div>
  );
}

// -------------------------------------------------------------------------------------------------

// display contract details
function ContractItemTable(props: { deal: *, contract: *, company: *, user: * }): React.Node {
  const { contract, deal, company, user } = props;

  return (
    <Row className="my-contract-item__table mb-2 pb-2">
      <Col className="mt-3 mt-md-0" xs={12} sm={6} md={2}>
        <h5 className="mb-2 px-1">
          <I18n id="started" d="Started" />
        </h5>
        <span className="text-primary px-1">
          {contract.created ? <DateTime.Date value={contract.created} /> : '-'}
        </span>
      </Col>
      <Col className="mt-3 mt-md-0" xs={12} sm={6} md={3}>
        <h5 className="mb-2 px-1">
          <I18n id="contact" d="Contact" />
        </h5>
        <span className="float-left px-1">{`${user.firstName || ''} ${user.lastName || ''}`}</span>
      </Col>
      <Col className="mt-3 mt-md-0" xs={12} sm={6} md={3}>
        <h5 className="mb-2 px-1">
          <I18n id="company" d="Company" />
        </h5>
        <Link
          to={`/company/${company.id}/user/${user.id}`}
          className="text-success font-rubik-bold px-1 float-left"
        >
          {company.name}
        </Link>
      </Col>
      <Col className="mt-3 mt-md-0 d-block my-contract-item__categories" xs={12} sm={6} md={4}>
        <h5 className="mb-2 px-1">
          <I18n id="category" d="Category" />
        </h5>
        {deal.category && (
          <span className="px-1 mb-1 text-secondary font-rubik-bold float-left">
            {deal.category.name}
          </span>
        )}
      </Col>
    </Row>
  );
}
