// @flow

import * as React from 'react';
import Container from 'react-bootstrap/Container';

import I18n, { Context as I18nContext } from '../../_helpers/I18n';

import Icon from '../Icon';
import Link from '../Link';
import Footer from '../Footer';
import BBCode from '../BBCode';

export type ContactLayoutProps = {|
  children: React.Node
|};

// -------------------------------------------------------------------------------------------------

export default function ContactLayout(props: ContactLayoutProps): React.Node {
  return (
    <>
      <Container className="layout-contact">
        <div className="layout-contact__wrapper">
          <div className="layout-contact__info">
            <h2 className="mb-0">
              <I18n id="companyName" d="ZYNCTOS Consulting s.r.o." />
            </h2>

            <div className="mt-4">
              <Icon name="envelope" />
              <span className="ml-4">
                <I18n id="companyEmail" d="info@zynctos.com" />
              </span>
            </div>

            <div className="mt-4 mt-sm-2 d-flex align-items-start">
              <Icon name="location" />
              <span className="ml-4 d-inline-block">
                <I18n
                  id="companyAddress"
                  d="Tehovská 2148/9,[br /]100 00 Praha 10 – Strašnice,[br /]Česká republika"
                >
                  {({ value }) => <BBCode>{value}</BBCode>}
                </I18n>
              </span>
            </div>

            <div className="mt-4 mt-sm-2">
              <div className="layout-contact__map">
                <I18nContext.Consumer>
                  {({ locale }) => (
                    <I18n
                      id="gMapsUrl"
                      d="https://maps.google.com/maps?q=50.0658054,14.5053464&t=&z=15&ie=UTF8&iwloc=&output=embed&language={locale}"
                      locale={locale}
                    >
                      {({ value, locale }) => (
                        <iframe
                          id="gmap_canvas"
                          frameBorder="0"
                          scrolling="no"
                          title="map"
                          src={value}
                        />
                      )}
                    </I18n>
                  )}
                </I18nContext.Consumer>
              </div>
            </div>

            <div className="mt-4 text-center layout-contact__icons">
              <I18n id="linkedInLink" d="https://linkedin.com/b3bcomsro">
                {({ value }) => (
                  <Link to={value} blank>
                    <Icon name="linkedin" />
                  </Link>
                )}
              </I18n>
              <I18n id="facebookLink" d="https://facebook.com/b3bcomsro">
                {({ value }) => (
                  <Link to={value} blank className="ml-3">
                    <Icon name="facebook" />
                  </Link>
                )}
              </I18n>
            </div>
          </div>
          <div className="layout-contact__content">{props.children}</div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
