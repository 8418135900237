// @flow

import me from './me';
import myCompany from './myCompany';
import dealDetail from './dealDetail';
import manageDeal from './manageDeal';
import myContracts from './myContracts';
import companyDetail from './companyDetail';
import manageContract from './manageContract';
import searchCategory from './searchCategory';
import searchKeywords from './searchKeywords';
import searchCategoryOrKeyword from './searchCategoryOrKeyword';
import articles from './articles';
import article from './article';

const queryMap: { [string]: React$ComponentType<*> } = {
  searchCategoryOrKeyword,
  searchKeywords,
  manageContract,
  searchCategory,
  companyDetail,
  myContracts,
  manageDeal,
  dealDetail,
  myCompany,
  me,
  articles,
  article
};

export default queryMap;
