// @flow

import * as React from 'react';

import type { TypeaheadOptions } from './Typeahead';

import Enum from '../../Enum';
import Typeahead from './Typeahead';
import type { OnChangeEvent } from '../';

// -------------------------------------------------------------------------------------------------

export type CountryInputProps = {
  onChange: (OnChangeEvent<string>) => void, // eslint-disable-line
  value: null | string, // eslint-disable-line
  name: string, // eslint-disable-line
  id: string // eslint-disable-line
};

export default function CountryInput(props: CountryInputProps): React.Node {
  return <Enum.Country component={EnumToFieldProxy} inputProps={props} />;
}

// -------------------------------------------------------------------------------------------------

function EnumToFieldProxy(props: {
  inputProps: CountryInputProps,
  d: { [string]: string },
  value: null | string
}): React.Node {
  const { inputProps, d } = props;

  const { onChange, value, ...rest } = inputProps;

  const country = (value || '').toUpperCase();
  const options = d ? Object.keys(d).map(id => ({ value: id, label: d[id] })) : [];
  const val: TypeaheadOptions =
    country && d && d.hasOwnProperty(country) ? [{ value: country, label: d[country] }] : [];

  return <Typeahead {...rest} onChange={changeHandler(onChange)} options={options} value={val} />;
}

// -------------------------------------------------------------------------------------------------

function changeHandler(
  onChange: (OnChangeEvent<string>) => void
): (OnChangeEvent<TypeaheadOptions>) => void {
  return function(event: OnChangeEvent<TypeaheadOptions>) {
    const { name, value } = event;
    const val = value && Array.isArray(value) ? value[0] && value[0].value : value && value.value;
    onChange && onChange({ name, value: val || null });
  };
}
