// @flow

import * as React from 'react';

import './loadingContent.scss';

export default function LoadingContent(props: { min?: number }): React.Node {
  return (
    <div className="lds-continer" style={props.min ? { minHeight: props.min } : undefined}>
      <div className="lds-ripple">
        <div />
        <div />
      </div>
    </div>
  );
}
