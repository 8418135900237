// @flow

import I18n from '../_helpers/I18n';
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Link from './Link';

export type PaginationProps = {
  onRequestPage: (page: number) => void,
  pages: number,
  page: number
};

function Pagination(props: PaginationProps): React.Node {
  const { onRequestPage, pages, page } = props;
  const [mobile, setMobile] =
    window.innerWidth > 1199 ? React.useState(false) : React.useState(true);
  const [mobileSmall, setMobileSmall] =
    window.innerWidth > 575 ? React.useState(false) : React.useState(true);

  const action = React.useCallback(
    (newPage: number) => () => {
      onRequestPage && onRequestPage(newPage);
    },
    [onRequestPage]
  );

  const mobileSize = () => {
    if (window.innerWidth < 1200) {
      setMobile(true);
    } else {
      setMobile(false);
    }
    if (window.innerWidth < 576) {
      setMobileSmall(true);
    } else {
      setMobileSmall(false);
    }
  };

  window.addEventListener('resize', mobileSize);

  if (!pages || pages < 1) {
    return null;
  }

  return (
    <Row className="pagination">
      <Col xs={12} sm={1} lg={2} xl={3} className="px-0">
        {page > 1 && (
          <Link onClick={action(page - 1)} className="direction">
            {!mobile ? <I18n id="prevPage" d="&laquo; Prev" /> : '<<'}
          </Link>
        )}
      </Col>
      <Col xs={12} sm={10} lg={8} xl={6} className="text-center number-container">
        {page > 3 && !mobileSmall && (
          <>
            <Link onClick={action(1)} className="page-number">
              {1}
            </Link>
            {page > 4 && <> . . . </>}
          </>
        )}
        {page > 2 && !mobileSmall && (
          <Link onClick={action(page - 2)} className="page-number">
            {page - 2}
          </Link>
        )}
        {page > 1 && (
          <Link onClick={action(page - 1)} className="page-number">
            {page - 1}
          </Link>
        )}
        <span className="active-number">{page}</span>
        {page < pages && (
          <>
            <Link onClick={action(page + 1)} className="page-number">
              {page + 1}
            </Link>
          </>
        )}
        {pages > page + 1 && !mobileSmall && (
          <>
            <Link onClick={action(page + 2)} className="ml-1 mr-1 page-number">
              {page + 2}
            </Link>
          </>
        )}
        {pages > page + 2 && !mobileSmall && (
          <>
            {page + 4 <= pages && <> . . . </>}
            <Link onClick={action(pages)} className="ml-1 mr-1 page-number">
              {pages}
            </Link>
          </>
        )}
      </Col>
      <Col xs={12} sm={1} lg={2} xl={3} className="px-0 text-right">
        {pages > page && (
          <Link onClick={action(page + 1)} className="direction next">
            {!mobile ? <I18n id="nextPage" d="Next &raquo;" /> : '>>'}
          </Link>
        )}
      </Col>
    </Row>
  );
}

export default Pagination;
