// @flow

import idx from 'idx';
import I18n from '../_helpers/I18n';
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { graphql } from 'react-relay';
import queryString from 'query-string';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import FilterIcon from '../_assets/images/preferences.svg';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import type { DealsFilterType } from './_components/SearchResults';

import Icon from '../_components/Icon';
import Layout from '../_components/Layout';
import Link from '../_components/Link';
import Searchresults from './_components/SearchResults';
import Form, { FormContext } from '../_components/Form';
import Toggle from '../_components/Toggle';
import Query from '../_helpers/Api/Query';
import LoadingContent from './_components/LoadingContent';
import SearchDealsFilter from './_components/SearchDealsFilter';
import LocationFilterInput from './_components/LocationFilterInput';
import MyLocationFakeInput from './_components/MyLocationFakeInput';
import { parseFilter, getFilterUrlAction } from './_helpers/dealUrlFilter';

// -------------------------------------------------------------------------------------------------

const MAX_RADIUS = 2001;

const defaultFilter: DealsFilterType = {
  cooperation: [],
  category: null,
  type: null,
  internal: null,
  keywords: [],
  status: null,
  location: {
    place: null,
    radius: 300,
    open: true,
    ignore: false
  }
};

// -------------------------------------------------------------------------------------------------

const updateFilterUrl = getFilterUrlAction('/deal');
const LocationFakeInputComponent = React.memo(MyLocationFakeInput);

export default class SearchDealsPage extends React.PureComponent<*> {
  initialFilter = parseFilter('');

  constructor(props: *) {
    super(props);
    this.initialFilter = parseFilter('?' + (window.location.search || ''));
    this.state = { disabled: true };
    const parsedQuery = window.location.search ? queryString.parse(window.location.search) : null;
    if (parsedQuery && parsedQuery.location && parsedQuery.open === undefined) {
      this.initialFilter.location.open = false;
    }
    if (parsedQuery && (parsedQuery.location === undefined || parsedQuery.location === '0')) {
      this.initialFilter.location.ignore = true;
    }
  }

  componentDidMount() {
    this.setState({ disabled: false, warning: false });
  }

  render(): React.Node {
    return (
      <Query name="me">
        {({ loading, error, data }) => {
          if (loading) {
            return <LoadingContent />;
          }

          const name = idx(data, _ => _.me.company.name);
          const type = idx(data, _ => _.me.type);

          const personalDetail =
            !!idx(data, _ => _.me.firstName) &&
            !!idx(data, _ => _.me.lastName) &&
            !!idx(data, _ => _.me.salutation) &&
            !!idx(data, _ => _.me.lang);

          const totalDeals = idx(data, _ => _.me.company.deals.total);
          if (!this.state.warning && personalDetail && name && totalDeals === 0) {
            this.setState({ warning: true });
          }
          if (!personalDetail || !name) {
            this.setState({ disabled: true });
            this.initialFilter.location.ignore = true;
          }

          /*const free = !idx(data, _ => _.me.company.paying);
          const created = idx(data, _ => _.me.company.created);
          const expirationDate = free
            ? new Date(new Date(created).setMonth(new Date(created).getMonth() + 6))
            : null;
          const today = new Date().getTime();*/

          return (
            <Layout.Common>
              {(!personalDetail && (
                <Alert variant="danger" className="d-flex align-items-center rounded-pill">
                  <span>
                    <I18n id="populateProfileAlert" d="Please fill in the profile details first." />
                  </span>

                  <Link to="/account/profile" className="ml-auto">
                    <I18n id="populateProfileAlertButton" d="Update profile details" />
                  </Link>
                </Alert>
              )) ||
                (!name && (
                  <Alert variant="danger" className="d-flex align-items-center rounded-pill">
                    <span>
                      <I18n
                        id="populateCompanyAlert"
                        d="Please fill in the company details first."
                      />
                    </span>

                    <Link to="/account/company?next-step=/deal/new" className="ml-auto">
                      <I18n id="populateCompanyAlertButton" d="Update company details" />
                    </Link>
                  </Alert>
                )) ||
                (type !== 'INTERNAL' && type !== 'INTERNAL_ADMIN' && this.state.warning && (
                  <Alert variant="warning" className="d-flex align-items-center rounded-pill">
                    <span>
                      <I18n
                        id="createOwnDealWarning"
                        d="Please add your own business opportunity (offer or request)."
                      />
                    </span>

                    <Link to="/deal/new" className="ml-auto warning-link">
                      <I18n id="createDealBtn" d="Create opportunity" />
                    </Link>
                  </Alert>
                ))}

              <Form
                defaultValue={this.initialFilter}
                disabled={!personalDetail || (type !== 'INTERNAL' && !name)}
                didChange={updateFilterUrl}
              >
                <Form.Field
                  component={LocationFakeInputComponent}
                  name="location.place"
                  disabled={this.state.disabled}
                />
                <Row className="deal-filter-header mb-4 mt-4">
                  <Col className="d-md-flex flex-column">
                    <h1 className="font-rubik-title mb-4">
                      <I18n id="title" d="Open Opportunities" />
                    </h1>
                    <Row className="m-0">
                      <img src={FilterIcon} width="24px" height="24px" alt="" />
                      <span className="ml-3 font-rubik-small font-rubik-bold">
                        <I18n id="filter" d="Filter by ..." />
                      </span>
                    </Row>
                  </Col>
                </Row>

                <SearchDealsFilter />

                <Row className="deal-filter-header mb-4 mt-4">
                  <Col className="d-md-flex align-items-center justify-content-between">
                    <div className="d-flex col-6 location-toggle p-0">
                      <Form.Field name="location" component={Toggle} />
                    </div>
                    <div className="d-flex flex-column">
                      <Form.Field name="location" component={OpenMapButton} />
                    </div>
                  </Col>
                </Row>

                <Form.Field
                  component={LocationFilterInput}
                  max={MAX_RADIUS}
                  step={50}
                  name="location"
                />
                <div className="pb-3 search-results-title font-rubik-small font-rubik-bold">
                  <I18n id="searchResults" d="Search results" />
                </div>
                {personalDetail && (name || type === 'INTERNAL' || type === 'INTERNAL_ADMIN') && (
                  <Searchresults
                    filterDecorator={decorateFilter}
                    contexRef={FormContext} // Connected to form context
                    noResults={NoResults}
                    query={searchQuery}
                    mine={false}
                  />
                )}
              </Form>
            </Layout.Common>
          );
        }}
      </Query>
    );
  }
}

// -------------------------------------------------------------------------------------------------

function NoResults() {
  return (
    <I18n
      id="noResultsPlaceholder"
      d="No opportunities were found. Please change your search criteria and try again"
    />
  );
}

// -------------------------------------------------------------------------------------------------

// Decorate Form schema to query params
function decorateFilter(data: *): DealsFilterType {
  const categoryOrKeyword = (data && data.categoryOrKeyword) || {};
  const location = data.location || {};

  const filter = {
    ...data,
    category: categoryOrKeyword.type === 'CATEGORY' ? parseInt(categoryOrKeyword.value, 10) : null,
    keywords: categoryOrKeyword.type === 'KEYWORD' ? [categoryOrKeyword.label] : [],
    location: idx(data, _ => _.location.ignore) === true ? {} : location
  };

  return filter;
}

export { defaultFilter };

// -------------------------------------------------------------------------------------------------

function OpenMapButton(props: *): React.Node {
  const { onChange, value, name } = props;
  const parsedQuery = window.location.search ? queryString.parse(window.location.search) : null;

  const toggleOpen = React.useCallback(() => {
    onChange({ name, value: { ...(value || {}), open: !(value && value.open) } });
  }, [name, value, onChange]);

  const open = parsedQuery ? value && value.open && parsedQuery.open : value && value.open;
  const ignoreLocation = !!(value && value.ignore);

  const c = ignoreLocation ? 'gray' : 'primary';

  return (
    !ignoreLocation && (
      <ButtonGroup>
        <Button
          variant={open ? c : 'outline-' + c}
          className="d-flex filter-button"
          onClick={toggleOpen}
          size="sm"
        >
          <Icon name="location" className="mr-1 sole-icon" />
          {open ? (
            <I18n id="closeMabButton" d="Close map" />
          ) : (
            <I18n id="mapFilterButton" d="Open map" />
          )}
          <Icon name={open ? 'close' : 'chevron-down'} className="ml-3 sole-icon" />
        </Button>
      </ButtonGroup>
    )
  );
}

// -------------------------------------------------------------------------------------------------

const searchQuery = graphql`
  query SearchDealsPageQuery(
    $cooperation: [DealCooperation!]
    $location: LocationInput
    $keywords: [Keyword!]
    $status: DealStatus
    $type: DealType
    $category: ID
    $first: Int!
    $offset: Int
    $internal: Boolean
  ) {
    search {
      results: dealsConnection(
        cooperation: $cooperation
        location: $location
        category: $category
        keywords: $keywords
        offset: $offset
        status: $status
        first: $first
        type: $type
        internal: $internal
        mine: false
      )
        @connection(
          key: "SearchDeals_results"
          filters: [
            "cooperation"
            "location"
            "categories"
            "keywords"
            "status"
            "type"
            "internal"
          ]
        ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            summary
            type
            cooperation
            status
            category {
              id
              name
            }
            isInternal
            onlyInternal
            keywords
            created
            description
          }
        }
        total
      }
    }
  }
`;
