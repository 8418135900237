/**
 * @flow
 * @relayHash 1e36912a3d87e55bd84306ef28e3b454
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DealStatus = "CLOSED" | "OPEN" | "%future added value";
export type confirmActionCloseDealMutationVariables = {|
  dealId: string
|};
export type confirmActionCloseDealMutationResponse = {|
  +closeDeal: {|
    +id: string,
    +status: DealStatus,
  |}
|};
export type confirmActionCloseDealMutation = {|
  variables: confirmActionCloseDealMutationVariables,
  response: confirmActionCloseDealMutationResponse,
|};
*/

/*
mutation confirmActionCloseDealMutation(
  $dealId: ID!
) {
  closeDeal(id: $dealId) {
    id
    status
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'dealId',
        type: 'ID!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'closeDeal',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'dealId'
          }
        ],
        concreteType: 'Deal',
        plural: false,
        selections: [
          {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'status',
            args: null,
            storageKey: null
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'confirmActionCloseDealMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'confirmActionCloseDealMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    params: {
      operationKind: 'mutation',
      name: 'confirmActionCloseDealMutation',
      id: null,
      text:
        'mutation confirmActionCloseDealMutation(\n  $dealId: ID!\n) {\n  closeDeal(id: $dealId) {\n    id\n    status\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'b36a446aa7e18e81b48c0c4bd8532df5';
module.exports = node;
