// @flow

import * as React from 'react';

import { routerHistory } from '../_helpers/router';

export type LinkProps = {
  component?: string | React$ComponentType<{ onClick: (*) => void }>,
  onClick?: (SyntheticEvent<*>) => void,
  className?: string,
  replace?: boolean,
  blank?: boolean,
  back?: boolean,
  to?: string
};

export default function Link(props: LinkProps): React.Node {
  const { component, to, blank, replace, back, onClick, className, ...more } = props;
  const Component = component || 'a';

  let passingProps = more;
  if (Component === 'a') {
    passingProps = { ...passingProps, href: to };
  }

  if (!back && !to && !onClick) {
    throw new Error('Cannot create link without target');
  }

  let click = handleClick(to, replace, back, onClick);

  const [url, hash] = (to || '').split('#');

  if (typeof hash === 'string') {
    const prev = click;
    click = e => {
      e.preventDefault();
      e.persist();
      routerHistory.push(url + '#' + hash);
      setTimeout(_ => {
        const elem = document.getElementById(hash);
        const top = (elem && elem.getBoundingClientRect().top) || 0;
        const menu = document.getElementById('site-menu');
        const offset = (menu && menu.getBoundingClientRect().height) || 0;
        window.scrollTo(0, Math.max(0, top ? window.pageYOffset + top - offset : 0));
        prev && prev(e);
      }, 100);
    };
  } else if (to && to.startsWith('http')) {
    click = undefined;
  }

  const moreProps = { ...passingProps };

  if (blank) {
    if (to && to.startsWith('htt')) {
      // $FlowFixMe
      moreProps.target = '_blank';
    }
    // $FlowFixMe
    moreProps.rel = 'noopener noreferrer';
    click = undefined;
  }

  return (
    <Component
      {...moreProps}
      className={'link' + (className ? ` ${className}` : '')}
      href={to || '#'}
      onClick={click}
    />
  );
}

const handleClick = (
  to: ?string,
  replace: ?boolean,
  back: ?boolean,
  onCLick: ?(SyntheticEvent<*>) => void
) => (e: SyntheticEvent<*>) => {
  e.preventDefault();
  if (!to && back === true) {
    routerHistory.cangoBack() ? routerHistory.goBack() : routerHistory.push('/');
  } else if (to) {
    replace === true ? routerHistory.replace(to) : routerHistory.push(to);
  }
  onCLick && onCLick(e);
};
