// @flow

import * as React from 'react';
import Redirect from 'react-router-dom/Redirect';

import I18n from '../../_helpers/I18n';
import toastr from '../../_helpers/toastr';
import { AuthApi } from '../../_helpers/Api';
import Layout from '../../_components/Layout';
import { getQueryParam } from '../../_helpers/router';
import LoadingContent from '../_components/LoadingContent';

export default function ResendVerificationPage(): React.Node {
  const email = getQueryParam('email');

  const [loading, setLoading] = React.useState(!!email);

  const handleResend = React.useCallback(response => {
    toastr({
      type: response && response.error ? 'error' : 'success',
      message: response.error ? (
        <I18n id="emailNotResent" d="Ooops! Something went wrong, try again" />
      ) : (
        <I18n id="emailHasBeenResent" d="Done! Check your email for verification email" />
      )
    });

    setLoading(false);
  });

  return (
    <Layout.Auth>
      {loading ? (
        <AuthApi action="resendVerification" variables={{ email }} autorun onFinish={handleResend}>
          <LoadingContent />
        </AuthApi>
      ) : (
        <Redirect to="/auth/login" />
      )}
    </Layout.Auth>
  );
}
