// @flow

import idx from 'idx';
import * as React from 'react';

import type { OnChangeEvent } from '../../_components/Form';
import type { TypeaheadOptions } from '../../_components/Form/_components/Typeahead';

import { Query } from '../../_helpers/Api';
import { Typeahead } from '../../_components/Form';

// -------------------------------------------------------------------------------------------------

export type CategoriesAutocompleteProps = {
  onChange: (OnChangeEvent<Array<NACECategory>>) => void,
  value: null | Array<NACECategory>,
  name: string,
  id?: string
};

// -------------------------------------------------------------------------------------------------

export default function CategoriesAutocomplete(props: CategoriesAutocompleteProps): React.Node {
  const [search, setSearch] = React.useState('');
  const { value, onChange, id, ...rest } = props;

  return (
    <Query name="searchCategory" variables={{ search }}>
      {({ loading, data }) => (
        <Typeahead
          {...rest}
          value={value ? value.map(i => ({ value: i.id, label: i.name })) : null}
          id={id || 'search-category-' + Date.now()}
          onChange={onChangeHandler(onChange)}
          options={decorateOptions(data)}
          className="control-search"
          onSearch={setSearch}
          loading={loading}
          multiple
        />
      )}
    </Query>
  );
}

// -------------------------------------------------------------------------------------------------

function onChangeHandler(
  onChange: (OnChangeEvent<Array<NACECategory>>) => void
): (OnChangeEvent<TypeaheadOptions>) => void {
  return function handleOnChange(event: OnChangeEvent<TypeaheadOptions>): void {
    const { value, name } = event;
    onChange({
      name,
      value: value
        ? value.map(i => ({ id: i.value, name: typeof i.label === 'string' ? i.label : '' }))
        : null
    });
  };
}

function decorateOptions(data: *): TypeaheadOptions {
  return idx(data, _ => _.search.categories) || [];
}
