/**
 * @flow
 * @relayHash 90b10e085095cc3c49759232ce1b7148
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DealStatus = "CLOSED" | "OPEN" | "%future added value";
export type CloseDealModalMutationVariables = {|
  dealId: string
|};
export type CloseDealModalMutationResponse = {|
  +closeDeal: {|
    +id: string,
    +status: DealStatus,
  |}
|};
export type CloseDealModalMutation = {|
  variables: CloseDealModalMutationVariables,
  response: CloseDealModalMutationResponse,
|};
*/

/*
mutation CloseDealModalMutation(
  $dealId: ID!
) {
  closeDeal(id: $dealId) {
    id
    status
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'dealId',
        type: 'ID!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'closeDeal',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'dealId'
          }
        ],
        concreteType: 'Deal',
        plural: false,
        selections: [
          {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'status',
            args: null,
            storageKey: null
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'CloseDealModalMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'CloseDealModalMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    params: {
      operationKind: 'mutation',
      name: 'CloseDealModalMutation',
      id: null,
      text:
        'mutation CloseDealModalMutation(\n  $dealId: ID!\n) {\n  closeDeal(id: $dealId) {\n    id\n    status\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '370928836b871bb40063dc6b98c042da';
module.exports = node;
